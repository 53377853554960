import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Pages extends Component{

    render(){

        return(
            <div>
{/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
    

     <header>
        <div className="back-links">
            <a href="/Profile">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Pages</h2>
                </div>
            </a>
        </div>
    </header>
    


    <section className="px-15 top-space ratio_45 pt-0 category-listing cate-padding">
        <a href="/Inner_category" className="category-wrap">
            <div className="content-part w-100">
                <h2>Elements</h2>
                <h4>Listing of All the Elements</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/Accord">Accordion</a></li>
                    <li><a href="alert.html">Alert</a></li>
                    <li><a href="buttons.html">Buttons</a></li>
                    <li><a href="/Form">form style</a></li>
                </ul>
            </div>
        </div>
        <a href="/Inner_category" className="category-wrap mt-3">
            <div className="content-part w-100">
                <h2>Onboarding & Authentication</h2>
                <h4>Registration & Splash Screens</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/Onboard">onboarding</a></li>
                    <li><a href="/Login">Login</a></li>
                    <li><a href="/Register">Signup</a></li>
                    <li><a href="/Forgot">forgot password</a></li>
                    <li><a href="/Reset_password">Reset Password</a></li>
                    <li><a href="/OTP">OTP</a></li>
                </ul>
            </div>
        </div>
        <a href="/Inner_category" className="category-wrap mt-3">
            <div className="content-part w-100">
                <h2>Home & products</h2>
                <h4>Find Products, Banners, Sale</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/Cat">Categories</a></li>
                    <li><a href="/Inner_category">Inner Categories</a></li>
                    <li><a href="/Search">Search</a></li>
                    <li><a href="/Shop">Shop</a></li>
                    <li><a href="/Product">Product</a></li>
                </ul>
            </div>
        </div>
        <a href="/Inner_category" className="category-wrap mt-3">
            <div className="content-part w-100">
                <h2>cart, order & payment</h2>
                <h4>Add your Products & Placed Order</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/Kart">Cart</a></li>
                    <li><a href="/Ekart">Empty Cart</a></li>
                    <li><a href="/Coupon">Apply Coupons</a></li>
                    <li><a href="Wishlist">Wishlist</a></li>
                    <li><a href="/Delivery">delivery</a></li>
                    <li><a href="/New_address">add new address</a></li>
                    <li><a href="/Payment">payment</a></li>
                    <li><a href="/Order-placed">order success</a></li>
                </ul>
            </div>
        </div>
        <a href="/Inner_category" className="category-wrap mt-3">
            <div className="content-part w-100">
                <h2>Profile Settings</h2>
                <h4>Check Order History, tracking pages..</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/Profile">Profile</a></li>
                    <li><a href="/Payment">payment</a></li>
                    <li><a href="/Profile_setting">Profile setting</a></li>
                    <li><a href="/History">order history</a></li>
                    <li><a href="/Track">order tracking</a></li>
                    <li><a href="/No_order">No orders</a></li>
                </ul>
            </div>
        </div>
        <a href="/Inner_category" className="category-wrap mt-3">
            <div className="content-part w-100">
                <h2>Other pages</h2>
                <h4>Listing of Other Inner Pages</h4>
            </div>
        </a>
        <div className="category-menu pt-3 px-15">
            <div className="element-menu">
                <ul>
                    <li><a href="/TC">terms condition</a></li>
                    <li><a href="/Help">help</a></li>
                    <li><a href="/About">about us</a></li>
                    <li><a href="/Notification">notification</a></li>
                </ul>
            </div>
        </div>
    </section>
    <section className="panel-space"></section>
          
            </div>
        );
    }

}export default Pages;