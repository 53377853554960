import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
       input: {},
      errors: {}
    };
    this.commonChange= this.commonChange.bind(this);
  }
commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
      this.setState({
      input
    }); 
  }
  validate(){

      let input = this.state.input;
      let errors = {};
      let isValid = true;

      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Please enter your name.";
      }
      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }
      if (typeof input["email"] !== "undefined") {
            var regx = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!regx.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
       if (!input["phone"]) {
        isValid = false;
        errors["phone"] = "Please enter your mobile number.";
      }
       if (typeof input["phone"] !== "undefined") {
              var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(input["phone"])) {
          isValid = false;
          errors["phone"] = "Please enter only number.";
        }else if(input["phone"].length !== 10){
          isValid = false;
          errors["phone"] = "Please enter valid phone number.";
        }
      }
      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Please enter your password.";
      }
        if(typeof input["password"] !== "undefined"){
          var regex =  new RegExp( "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$")
          if(input["password"].length<8 || input["password"].length> 20){
          isValid=false
         errors["password"]="Passwords lenght should be 8 to 20"
      }
      else if(!regex.test(input["password"])){
       isValid=false;
       errors["password"]="Passwoord should contains: Uppercase, lowercase ,number,special character"
      }
        }
      
      
      this.setState({
        errors: errors
      }); 
      return isValid;
  }
  reset() {
  
   let input = {};
        input["name"] = "";
        input["email"] = "";
        input["phone"] = "";
        input["password"] = "";
          this.setState((currentState) => ({
             input:input
           }),
          )}
  handleSubmit(e){

    if(this.validate()){
        
       var param={
          mobile_no:this.state.input.phone,
          Method:"Register",
          Old_no:"",
          email:this.state.input.email,
          password:this.state.input.password

       }
    var pdata={
        fullname:this.state.input.name,
        email:this.state.input.email,
        mobile_no:this.state.input.phone,
        password:this.state.input.password
    };
    
    var formBody = [];
    for (var property in pdata) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(pdata[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); 
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formBody,
    };
     fetch("https://api.emechmart.com/api/UserAccount/Register",requestOptions)
     .then((resp)=>resp.json())
     .then((result)=>{
         console.log(result);
        
        
         let success=result.Success
        if(success===true){
    this.props.history.push({ pathname: "/OTP",
          state:param
         
    });

    }else{
      toast.dismiss();
       toast.error(result.error,{autoClose:2000});
       this.reset()
    }
     })
}

  }
  
  render() {
    return (
      <div>
       

        {/* <!-- top design start --> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />
        {/* <!-- top design end --> */}

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* <!-- login section start --> */}
        <section className="form-section px-15 section-b-space">
          <h1>
            Hey, <br />
            Sign Up
          </h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                placeholder="Name"
                name="name" 
                value={this.state.input.name}
                onChange={this.commonChange}
                required
                
              />
              <label htmlFor="one">Name</label>
              <div className="text-danger">{this.state.errors.name}</div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="two"
                placeholder="Email"
                name="email"
                value={this.state.input.email}
                onChange={this.commonChange}
                required
              />
              <label htmlFor="two">Email</label>
              <div className="text-danger">{this.state.errors.email}</div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="three"
                placeholder="Mobile No."
                name="phone"
                value={this.state.input.phone}

                onChange={this.commonChange}
                required
              />
              <label htmlFor="two">Mobile Number</label>
              <div className="text-danger">{this.state.errors.phone}</div>

            </div>
            <div className="form-floating mb-4">
              <input
                type="password"
                id="txtPassword"
                className="form-control"
                placeholder="password"
                name="password"
                value={this.state.input.password}
                onChange={this.commonChange}
                required
              />
              <label>Password</label>
              {/* <div id="btnToggle" className="password-hs">
                <i id="eyeIcon" className="iconly-Hide icli hide"></i>
              </div> */}
              <div className="text-danger">{this.state.errors.password}</div>
            </div>
            <button className="btn btn-solid w-100" onClick={(e)=>this.handleSubmit()}>
              Sign UP
            </button>
            {/* <ToastContainer Colored theme='colored' closeButton={false} /> */}
          </div>
          {/* <div className="or-divider">
            <span>Or sign in with</span>
        </div> */}
          {/* <div className="social-auth">
            <ul>
                <li>
                    <a href="#"><img src="assets/images/social/google.png" className="img-fluid" alt=""/></a>
                </li>
                <li>
                    <a href="#"><img src="assets/images/social/fb.png" className="img-fluid" alt=""/></a>
                </li>
                <li className="apple-icon">
                    <a href="#"><img src="assets/images/social/apple.png" className="img-fluid" alt=""/></a>
                </li>
            </ul>
        </div> */}
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">
              Already have an Account?{" "}
              <a className="title-color text-decoration-underline" href="/Login">
                Sign In
              </a>
            </h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
      </div>
    );
  }
}
export default Register;
