import React from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";

export default class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
    };
  }

  componentDidMount() {
    const { state } = this.props.location;

    var param = "productnrand=" + encodeURIComponent(state);
    const header = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: param,
    };
    fetch("https://api.emechmart.com/api/Product/AllRatings", header)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        this.setState({
          reviews: res.Data,
        });
      });
  }

  render() {
    const { reviews } = this.state;
    const { state } = this.props.location;

    return (
      <div>
        <header>
          <div className="back-links">
            <Link to={{ pathname: "/Product", state: state }}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>All Reviews</h2>
              </div>
            </Link>
          </div>
        </header>
        <div className="product-detail-box px-15 top-space pt-2">
          {/* <h4 className="page-title">Customer Reviews (24)</h4> */}
          <div className="review-section">
            <ul>
              {reviews.map((data) => (
                <li>
                  <div className="media">
                    <img
                      src={
                        data.userimage !== ""
                          ? data.userimage
                          : "assets/images/user/1.png"
                      }
                      className="img-fluid"
                      alt=""
                    />

                    <div className="media-body">
                      <h4>
                        {data.name}|{data.date}
                      </h4>
                      <ul className="ratings">
                        <ReactStars
                          count={5}
                          edit={false}
                          value={parseFloat(data.rating)}
                          size={18}
                          char={<i className="iconly-Star icbo"></i>}
                          color="#b7babe"
                          activeColor="#007744"
                        />
                      </ul>
                      <h2 style={{ lineHeight: "8px" }}>{data.reviewtitle}</h2>
                    </div>
                  </div>
                  <h4 className="content-color">{data.reviewdecription}</h4>
                  <div className="review-bottom">
                    {/* <h6>Size bought: <span className="content-color">S</span></h6> */}
                    {/* <div className="liking-sec">
                                <span className="content-color"><img src="assets/svg/thumbs-up.svg" className="img-fluid"
                                        alt=""/>20</span>
                                <span className="content-color"><img src="assets/svg/thumbs-down.svg" className="img-fluid"
                                        alt=""/>2</span>
                            </div> */}
                  </div>
                </li>
              ))}
              {/* <li>
                   
                        <div className="media">
                            <img src="assets/images/user/3.png" className="img-fluid" alt=""/>
                            <div className="media-body">
                                <h4>Mark Jacob | 20 Aug, 2021</h4>
                                <ul className="ratings">
                                    <li><i className="iconly-Star icbo"></i></li>
                                    <li><i className="iconly-Star icbo"></i></li>
                                    <li><i className="iconly-Star icbo"></i></li>
                                    <li><i className="iconly-Star icbo"></i></li>
                                    <li><i className="iconly-Star icbo empty"></i></li>
                                </ul>
                            </div>
                        </div>
                        <h4 className="content-color">It's a really cute skirt! I didn't expect to feel so good in a
                            polyester material. The print is slightly less bright than what is shown in the product
                            description.</h4>
                        <div className="review-bottom">
                              <h6>Size bought: <span className="content-color">S</span></h6>
                            <div className="liking-sec">
                                <span className="content-color"><img src="assets/svg/thumbs-up.svg" className="img-fluid"
                                        alt=""/>20</span>
                                <span className="content-color"><img src="assets/svg/thumbs-down.svg" className="img-fluid"
                                        alt=""/>2</span>
                            </div>
                        </div>
                    </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
