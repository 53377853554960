import React from "react"


export default class Offline extends React.Component{
    render(){
        return(
            <div className="text-center">
            <h3 className="text-center no-connect-internet-title my-4">No internet connection</h3>
            <div className="clearfix"></div>
            <div className="no-connect-internet">
                <img src="assets/images/wireless.png" alt="No internet" />
            </div>
            <div className="clearfix"></div>
            <p className="no-connect-internet-text">Please re-connect to the internet to continue...</p>
            <div className="clearfix"></div>
            <a href="/" className="no-connect-internet-link">Try Again</a>
        </div>
        )
    }
}