/* eslint-disable no-loop-func */
import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Order_placed extends Component{
    constructor(props){
      super(props);
      this.state={
        O_Detail:[],
        id:"",
        B_add:{},
        D_add:{},
        Method:"",
        btotal:0,
        bsaving:0,
        shipping:0 ,
        tax:0       
      }
    }
componentDidMount(){
  var order= JSON.parse(localStorage.getItem("order"))
this.setState({
  O_Detail: order.OrderedProducts,
  id:order.orderid,
  B_add:order.billingaddress,
  D_add:order.deliveryAddress,
  Method:order.payment_method
})
 var data=order.OrderedProducts
    console.log(data[0].shippingRates.final_rate);
  for(var i=0;i<order.OrderedProducts.length;i++){
   
    this.setState ({
      btotal:this.state.btotal+(parseInt(data[i].vmrp)*parseInt(data[i].productquantity)),
      tax:this.state.tax+(parseInt(data[i].totaltax)*parseInt(data[i].productquantity)),
      bsaving:this.state.bsaving+(parseInt(data[i].discountprice)*parseInt(data[i].productquantity)),
      shipping:this.state.shipping+parseFloat(data[i].shippingRates.final_rate)
    })
  }  
}

onclick(e){
 localStorage.removeItem("order")
}
    render(){
 
const{ O_Detail,id,D_add,B_add,Method,btotal,tax,bsaving,shipping}=this.state;
const total=btotal+tax+shipping-bsaving;
        return(
            <div>
{/* <div className="loader">
    <span></span>
    <span></span>
  </div>
   */}

 
  <header>
    <div className="back-links">
      <a href="/">
        <i className="iconly-Arrow-Left icli"></i>
        <div className="content">
          <h2>Order Placed</h2>
        </div>
      </a>
    </div>
  </header>



  {/* <!-- order success section start --> */}
  <section className="order-success-section px-15 top-space">
    <div>
      <div className="check-circle">
        <img src="assets/images/check-circle.gif" className="img-fluid" alt=""/>
      </div>
      <h1>Order successfully!</h1>
      <h2>Payment is successfully processed and your Order is on the way.</h2>
    </div>
  </section>
  {/* <!-- order success section end --> */}


  {/* <!-- order details section start --> */}
  <section className="px-15">
    <h2 className="page-title">Order Details</h2>
    <div className="details">
      <ul>
         
        <li className="mb-3 d-block">
          <h4 className="fw-bold mb-1">Your order # is:{id}</h4>
          <h4 className="content-color">An email receipt including the details about your order has been sent to your email
            ID.</h4>
        </li>
        <li className="mb-3 d-block">
          <h4 className="fw-bold mb-1">Billing Address:</h4>
          <h4 className="content-color">{B_add.line1},</h4>
          <h4 className="content-color">{B_add.line2},</h4>
          <h4 className="content-color">{B_add.town}, {B_add.state}</h4>
          <h4 className="content-color">{B_add.country}</h4>
        </li>
        <li className="mb-3 d-block">
          <h4 className="fw-bold mb-1">Delivery Address:</h4>
          <h4 className="content-color">{D_add.line1},</h4>
          <h4 className="content-color">{D_add.line2},</h4>
          <h4 className="content-color">{D_add.town}, {D_add.state}</h4>
          <h4 className="content-color">{D_add.country}</h4>
        </li>
        <li className="d-block">
          <h4 className="fw-bold mb-1">Payment Method</h4>
          <h4 className="content-color">{Method}</h4>
        </li>
      </ul>
    </div>
  </section>
  <div className="divider"></div>
  {/* <!-- order details section end --> */}



  {/* <!-- expected delivery section start --> */}
  <section className="px-15 pt-0">
    <h2 className="page-title">Order Summary</h2>
    <div className="product-section">
      <div className="row gy-3">
      {O_Detail.map((data)=>(
        <div className="col-12" key={data.productrandid}>
          <div className="product-inline">
            <a href={null}>
              <img src={data.productimage} className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href={null}>
                  <h4>{data.productname} </h4>
                 <h4 className="content-color">{data.variation}</h4>
                </a>
                <h5 className="content-color"> Qty: {data.productquantity}</h5>
                <div className="price">
                  <h4>{"\u20B9"}{data.finalprice}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        ))}
        {/* <div className="col-12">
          <div className="product-inline">
            <a href="/Product">
              <img src="assets/images/products/2.jpg" className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href="/Product">
                  <h4>Men Blue Denim Jacket</h4>
                </a>
                <h5 className="content-color">Size: S, Qty: 1</h5>
                <div className="price">
                  <h4>$32.00</h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      
    </div>
  </section>
  {/* <!-- expected delivery section end --> */}


  {/* <!-- order details start --> */}
  <section className="px-15">
    <div className="order-details">
      <ul>
        <li>
          <h4>Bag total <span>{"\u20B9"}{btotal}</span></h4>
        </li>
        <li>
          <h4>Bag savings <span className="text-green">-{"\u20B9"}{bsaving}</span></h4>
        </li>
        <li>
          <h4>Tax <span>{"\u20B9"}{tax}</span></h4>
        </li>
        <li>
          <h4>Delivery <span>{"\u20B9"}{shipping.toFixed(0)}</span></h4>
        </li>
      </ul>
      <div className="total-amount">
        <h4>Total Amount <span>{"\u20B9"}{total.toFixed(0)}</span></h4>
      </div>
    </div>
  </section>
  {/* <!-- order details end --> */}



  
  <section className="panel-space"></section>
  


  
  <div className="delivery-cart cart-bottom">
    <div>
      <div className="left-content">
        <a href="/History" className="title-color" onClick={(e)=>this.onclick()}>Track Order</a>
      </div>
      <a href="/" className="btn btn-solid" onClick={(e)=>this.onclick()}>Continue shopping</a>
    </div>
  </div>

                
            </div>
        );
    }

}export default Order_placed ;