import React from "react"


class Cancel_order extends React.Component{

render(){
    return(
        <div>
<header>
    <div className="back-links">
      <a href="/">
        <i className="iconly-Arrow-Left icli"></i>
        <div className="content">
          <h2>Order Cancelled</h2>
        </div>
      </a>
    </div>
  </header>



  {/* <!-- order success section start --> */}
  <section className="order-success-section px-15 top-space">
    <div>
      <div className="check-circle1">
        <img src="assets/images/cancel.png" className="img-fluid" alt=""/>
      </div>
      <h1 style={{color:"Red"}}>Your order payment is failed!</h1>
    </div>
  </section>


        </div>
    )
}

}export default Cancel_order