import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

export default class Rate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      errors: {},
      rate: 0,
    };
    this.commonChange = this.commonChange.bind(this);
    this.handlecheckbox = this.handlecheckbox.bind(this);
  }
  commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }
  handlecheckbox(newRating) {
    console.log(newRating);
    this.setState({
      rate: newRating,
    });
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your name.";
    }
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
    if (typeof input["email"] !== "undefined") {
      var regx = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!regx.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!input["title"]) {
      isValid = false;
      errors["title"] = "Review title is required";
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }
  handlesubmit() {
    const { state } = this.props.location;
    if (this.validate()) {
      let input = this.state.input;

      var bdata = {
        productnrand: state.enc_id,
        email: input.email,
        name: input.name,
        reviewtitle: input.title,
        reviewdescription: input.Desc,
        rating: this.state.rate,
      };
      console.log(bdata);
      var formBody = [];
      for (var property in bdata) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(bdata[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch(
        "https://api.emechmart.com/api/Product/AddRatingsForProduct",
        requestOptions
      )
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          toast.dismiss();
          toast.success(res.Message, { autoClose: 4000 });
          this.props.history.push({ pathname: "/Track", state: state });
        });
    }
  }

  render() {
    const { state } = this.props.location;

    const Submit = () => {
      if (this.state.rate >= 1) {
        return (
          <button
            type="button"
            class="w-100 btn btn-solid"
            onClick={(e) => this.handlesubmit()}
          >
            Submit Review
          </button>
        );
      }
    };

    return (
      <div>
        <header>
          <div className="back-links">
            <Link to={{ pathname: "/Track", state: state }}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Write a review </h2>
              </div>
            </Link>
          </div>
        </header>

        <section class="pt-5">
          <div class="address-form-section px-15">
            <div class="form-floating mb-4 mystarsize">
              <ul className="ratings reactstar">
                <ReactStars
                  count={5}
                  edit={true}
                  size={34}
                  onChange={this.handlecheckbox}
                  char={<i className="iconly-Star icbo"></i>}
                  color="#b7babe"
                  activeColor="#007744"
                />
              </ul>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                name="name"
                placeholder="Full Name"
                value={this.state.input.name}
                onChange={this.commonChange}
              />
              <label htmlFor="one">Full Name</label>
              <div className="text-danger">{this.state.errors.name}</div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="four"
                name="email"
                placeholder="email"
                //defaultValue={state.binfo.houseno}
                onChange={this.commonChange}
                value={this.state.input.email}
              />
              <label htmlFor="four">email</label>
              <div className="text-danger">{this.state.errors.email}</div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="seven"
                placeholder="town/city"
                name="title"
                onChange={this.commonChange}
                value={this.state.input.town}
              />
              <label htmlFor="seven">Title</label>
              <div className="text-danger">{this.state.errors.town}</div>
            </div>
            <div className="form-floating mb-4">
              <textarea
                type="text"
                className="form-control"
                id="eight"
                placeholder="Description"
                name="Desc"
                defaultValue=""
                onChange={this.commonChange}
                style={{ padding: "11px", height: "90px" }}
                value={this.state.input.Desc}
              />
              <label htmlFor="seven">Description</label>
              <div className="text-danger">{this.state.errors.Desc}</div>
            </div>
          </div>
        </section>
        <section></section>

        <div className="row m-0">
          <div>
            {/* <div className="left-content col-5">
              <a href="#" className="title-color">
                RESET
              </a>
            </div> */}
            {Submit()}
          </div>
        </div>
      </div>
    );
  }
}
