import React from "react";
import { Link } from "react-router-dom";

export default class Reaturn_Policy extends React.Component{
    render(){
        const { state } = this.props.location;

        return(
            <div>
                <header>
          <div className="back-links">
          <Link to={{ pathname: "/Product", state: state }}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Returns Policy</h2>
              </div>
            </Link>
          </div>
        </header>

        <section className="px-15 top-space pt-0">
          <h2 className="mb-3">Returns Policy</h2>
          <h4 className="mb-1">
            KINDLY GO THROUGH FOLLOWING TERMS AND CONDITIONS CAREFULLY:
          </h4>
          <p className="content-color">
          This policy enables easy product return requests for customers through our website, subject to product validations at the time of pick-up and fraud prevention mechanisms. Returns Policy is provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to User(s). For any kind of manufacturing defect, the User(s) can claim Refund, Replacement or Exchange within 7 days from the date of delivery, if the product is delivered in defective/damaged condition or different from the ordered item. It is clarified that a customer may only be able to seek a one-time replacement under this Policy, subject to the other terms provided herein.</p>
 

  <p className="content-color">- Following claims will be covered under the policy provided here and through corresponding validation processes :</p>
  <ul >
  <li className="content-color">
  1) product undelivered.</li>
  <li className="content-color">
  2) product/accessories missing.</li>
  <li className="content-color">
  3) wrong product/accessories delivered.</li>
  <li className="content-color">
  4) A buyer should only return a product if it has a manufacturer defect.</li>
<li className="content-color">
  5) As soon as a buyer comes to know that the product is defective, He/She would make a detailed video showing the defect and clear images of the products.</li>
  </ul>

  <ul className="mt-2">
  <li ><p className="content-color">
 - In case the product was not delivered and User(s) received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation   for the   seller to investigate.</p></li>
 <li ><p className="content-color">
 - In order to help User(s) resolve issues with User(s) product, we may troubleshoot User(s) product either through online tools, over the phone, and/or through an in-person   technical visit.</p></li>
 <li ><p className="content-color">
 - The correct product type i.e., the name/ image/ brand/ serial number/ article number/ bar code should match and MRP tag should be undetached and clearly visible.</p></li>
 <li ><p className="content-color">
 - The product should be unused, unwashed, unsoiled, without any stains and with nontampered quality check seals/return tags/warranty seals (wherever applicable).</p></li>
 <li ><p className="content-color">
 - All in-the-box accessories (like starter kits, instruction manuals, etc.), freebies and combos (if any) should be present.</p></li>
 <li ><p className="content-color">
 - The field executive will refuse to accept the return if any of the above conditions is not met.</p></li>
 <li ><p className="content-color">
 - If a defect is determined within the Returns Window, a replacement of the same model will be provided at no additional cost. If no defect is confirmed or the issue is not diagnosed within 7 days of delivery, User(s) will be directed to a brand service centre to resolve any subsequent issues.</p></li>
 <li ><p className="content-color">
 - Full replacement will be provided only in cases where the service personnel opines that replacing the faulty/defective part will not resolve the issue.</p></li>
 <li ><p className="content-color">
 - For any products for which a refund is to be given, the refund will be processed once the returned product has been received by the seller.</p></li>
 </ul>
 
 <p className="content-color mt-2">
 Please keep the product intact, with original accessories (if any), user manual and warranty cards in the original packaging at the time of returning the product. In certain cases where the seller is unable to process a replacement for any reason whatsoever, a refund will be given.
          </p>
          </section>
            </div>
        )
    }
}