
export default class Queue {
    constructor(size) {
        this.queue = [];
        this.size = size;
    }

    insertIntoQueue(Element) {
        if(this.queue.includes(Element)){
           
            return;
        }
      else if (this.queue.length < 10) {
           

            this.queue.push(Element);
        }
        else {
            this.queue.shift();
          

            this.queue.push(Element);
        }
    }

    toString() {
        return this.queue.join(",");
    }

    getQueue() {
        return this.queue;
    }

    consoleQueue() {
        console.log(this.queue);
    }

    setQueue(arraystring) {
        this.queue = arraystring.split(',');
    }
}