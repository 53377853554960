import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Saved_cards extends Component{

    render(){
 let url="";
        return(
            <div>
 {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
   */}


    <header>
        <div className="back-links">
            <a href="/Profile">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Payments</h2>
                </div>
            </a>
        </div>
    </header>
    


    {/* <!-- save cards section start --> */}
    <section className="top-space save-card-section pt-0">
        <h2 className="page-title px-15">Saved Cards</h2>
        <div className="payment-slider slick-default overflow-hidden">
            <div>
                <div className="card-box blue-card">
                    <div className="card-top">
                        <h4>Credit Card</h4>
                        <h4 className="ms-auto">Bank Name</h4>
                    </div>
                    <img src="assets/svg/payment/chip.svg" className="img-fluid chip-img" alt=""/>
                    <div className="card-number">
                        <span>1234</span>
                        <span>5678</span>
                        <span>****</span>
                        <span>9123</span>
                    </div>
                    <div className="card-bottom">
                        <h4>Paige Turner</h4>
                        <div className="valid-threw">
                            <span>VALID <br/>THRU</span>
                            <h6>XX/XX</h6>
                        </div>
                    </div>
                </div>
                <div className="card-buttons">
                    <a href={url}>Remove</a>
                    <a href={url}>Edit</a>
                </div>
            </div>
            <div>
                <div className="card-box yellow-card">
                    <div className="card-top">
                        <h4>Credit Card</h4>
                        <h4 className="ms-auto">Bank Name</h4>
                    </div>
                    <img src="assets/svg/payment/chip.svg" className="img-fluid chip-img" alt=""/>
                    <div className="card-number">
                        <span>1234</span>
                        <span>5678</span>
                        <span>****</span>
                        <span>9123</span>
                    </div>
                    <div className="card-bottom">
                        <h4>Paige Turner</h4>
                        <div className="valid-threw">
                            <span>VALID <br/>THRU</span>
                            <h6>XX/XX</h6>
                        </div>
                    </div>
                </div>
                <div className="card-buttons">
                    <a href={url}>Remove</a>
                    <a href={url}>Edit</a>
                </div>
            </div>
            <div>
                <div className="card-box pink-card">
                    <div className="card-top">
                        <h4>Credit Card</h4>
                        <h4 className="ms-auto">Bank Name</h4>
                    </div>
                    <img src="assets/svg/payment/chip.svg" className="img-fluid chip-img" alt=""/>
                    <div className="card-number">
                        <span>1234</span>
                        <span>5678</span>
                        <span>****</span>
                        <span>9123</span>
                    </div>
                    <div className="card-bottom">
                        <h4>Paige Turner</h4>
                        <div className="valid-threw">
                            <span>VALID <br/>THRU</span>
                            <h6>XX/XX</h6>
                        </div>
                    </div>
                </div>
                <div className="card-buttons">
                    <a href={url}>Remove</a>
                    <a href={url}>Edit</a>
                </div>
            </div>
        </div>
    </section>
    <div className="divider"></div>
    {/* <!-- save cards section end --> */}


    {/* <!-- wallet section start  --> */}
    <section className="px-15 pt-0 section-b-space">
        <div className="wallet-link-section">
            <h2 className="page-title">Wallets</h2>
            <ul>
                <li>
                    <img src="assets/images/wallet/paytm.png" className="img-fluid" alt=""/>
                    <div className="content">
                        <h4>Paytm Wallet</h4>
                        <h6>Balance: <span>$25.00</span></h6>
                    </div>
                    <a href={url}>Delink</a>
                </li>
                <li>
                    <img src="assets/images/wallet/cash.png" className="img-fluid" alt=""/>
                    <div className="content">
                        <h4>Cash App</h4>
                        <h6>Balance: <span>$25.00</span></h6>
                    </div>
                    <a href={url}>Delink</a>
                </li>
                <li>
                    <img src="assets/images/wallet/google.png" className="img-fluid" alt=""/>
                    <div className="content">
                        <h4>Google Wallet</h4>
                        <h6>Balance: <span>00</span></h6>
                    </div>
                    <a href={url}>Delink</a>
                </li>
            </ul>
        </div>
    </section>
    {/* <!-- wallet section emd  --> */}


                
            </div>
        );
    }

}export default Saved_cards;