import React from "react";
import { ToastContainer, toast } from "react-toastify";

export default class Refund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      errors: {},
      rows: [{}],
      type: "Saving",
    };
    this.commonChange = this.commonChange.bind(this);
    this.Change = this.Change.bind(this);
  }
  commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }
  Change(event) {
    this.setState({
      type: event.target.value,
    });
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["name"]) {
      isValid = false;
      errors["name"] = "This Field is required!";
    }
    if (!input["ac"]) {
      isValid = false;
      errors["ac"] = "This Field is required!";
    }
    if (typeof input["ac"] !== "undefined") {
      var regx = new RegExp(/^(?:[0-9]{9,18}|[0-9]{2}-[0-9]{3}-[0-9]{6})$/);
      if (!regx.test(input["ac"])) {
        isValid = false;
        errors["ac"] = "Enter a valid A/C number!";
      }
    }
    if (!input["Ifsc"]) {
      isValid = false;
      errors["Ifsc"] = "This Field is required!";
    }
    if (typeof input["Ifsc"] !== "undefined") {
      var pattern = new RegExp(/ ^[A-Z]{4}0[A-Z0-9]{6}$/);
      if (!pattern.test(input["Ifsc"])) {
        isValid = false;
        errors["Ifsc"] = "Enter a valid IFSC code!";
      }
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  handleSubmit() {
    const { state } = this.props.location;
     const { rows } = this.state;
    if (this.validate()) {
      var values = {
        orderid: state.id,
        enc_orderitemid: state.enc_id,
        reason: state.reason,
        reason_other: state.reason_other,
        bankaccounttype: this.state.type,
        bankaccountholdername: this.state.input.name,
        bankaccountnumber: this.state.input.ac,
        bankifsc: this.state.input.Ifsc,
      };
      console.log(values);

      var formBody = [];
      for (var property in values) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(values[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const headers = {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch("https://api.emechmart.com/api/Order/ReturnOrder", headers)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          if (res.Success === true) {
            if(rows.length===0){
              toast.dismiss();
              toast.success(res.Message)
              this.props.history.push("/History")
            }
            else{
             const formData = new FormData();
      for (var i = 0; i < rows.length; i++) {
        formData.append(rows[i].file["name"], rows[i].file);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] , pair[1]);
      //   console.log(typeof pair[1]);
      // }
      console.log(formData.entries());
            formData.append("Row_Id",res.Data);
            const headers = {
              method: "POST",
              mode: "cors",
              headers: {
                authorization: "Bearer " + localStorage.getItem("U_Id"),
              },
              body: formData,
            };
            fetch(
              "https://api.emechmart.com/api/Order/UploadFilesForReturnOrder",
              headers
            )
              .then((resp) => resp.json())
              .then((resp) => {
                console.log(resp);
                if(resp.Success===true){
                  toast.dismiss();
                  toast.success("Return requested",{autoClose:3000})
                  this.props.history.push("/History")
                }
                else{
                  toast.dismiss()
                  toast.error(resp.Message,{autoClose:3000})
                }
              });}
        }
       });

      // let input = {};
      //         input["name"] = "";
      //         input["ac"] = "";
      //         input["Ifsc"] = "";
      //           this.setState((currentState) => ({
      //              input:input
      //            }),
      //           )
    }
  }
  handleChange = (idx) => (e) => {
    const rows = [...this.state.rows];
    rows[idx] = {
      [e.target.name]: e.target.files[0],
    };
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      name: "",
      mobile: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1),
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ rows });
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <header>
          <div className="back-links">
            <a href="/History">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Add a Refund Details </h2>
              </div>
            </a>
          </div>
        </header>
 <ToastContainer Colored theme='colored' closeButton={false} autoClose={3000} />

        <section className="tab-content top-space px-15 pt-2">
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="one"
              placeholder="Name"
              name="name"
              value={this.state.input.name}
              onChange={this.commonChange}
              required
            />
            <label htmlFor="one">Bank-Account Holdername</label>
            <div className="text-danger">{this.state.errors.name}</div>
          </div>
          <div className="form-floating mb-4">
            <input
              type="email"
              className="form-control"
              id="two"
              placeholder="Email"
              name="ac"
              value={this.state.input.ac}
              onChange={this.commonChange}
              required
            />
            <label htmlFor="two">Bank-Account Number</label>
            <div className="text-danger">{this.state.errors.ac}</div>
          </div>
          <div className="form-floating mb-4">
            <select
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              name="type"
              defaultValue="Saving"
              onChange={this.Change}
              // value={this.state.input.country}
            >
              <option value="Current">Current Account</option>
              <option value="Saving">Saving Account</option>
            </select>
            <label htmlFor="floatingSelect">Account Type</label>
            {/* <div className="text-danger">
                      {this.state.errors.country}
                    </div> */}
          </div>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="three"
              placeholder="Mobile No."
              name="Ifsc"
              value={this.state.input.Ifsc}
              onChange={this.commonChange}
              required
            />
            <label htmlFor="two">Bank IFSC Code</label>
            <div className="text-danger">{this.state.errors.Ifsc}</div>
          </div>
          <div className="form-floating mb-4" id="upload">
            <h2 className="billing">Upload product image &nbsp;</h2>
            <div className="m-4" style={{ width: "99%" }}>
              <div className="row ">
                <div
                  className="col-md-12 column"
                  style={{ marginLeft: "-22px" }}
                >
                  <table
                    className="table table-bordered table-hover"
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        {/* <th className="text-center"> Name </th> 
                    <th />*/}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rows.map((item, idx) => (
                        <tr id="addr0" key={idx}>
                          {/* <td>{idx}</td> */}
                          <td>
                            {/* <input
                          type="text"
                          name="name"
                          value={this.state.rows[idx].name}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        /> */}
                            <input
                              type="hidden"
                              name="hiddenFile"
                              id="cropImage"
                            />
                            <input
                              type="file"
                              id="cropzee-input"
                              accept="image/*"
                              name="file"
                              value={this.state.rows[idx].name}
                              onChange={this.handleChange(idx)}
                              className="edit-profile-file"
                              style={{ fontSize: "15px", color: "green" }}
                            />
                            <button
                              className="btn btn-outline-danger btn-sm left"
                              onClick={this.handleRemoveSpecificRow(idx)}
                              
                            >
                              Remove
                            </button>
                          </td>
                          {/* <td>
                        
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    onClick={this.handleAddRow}
                    className="btn btn-outline-success btn-sm"
                  >
                    Add Row
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </section> */}
          <button
            className="btn btn-solid w-100"
            onClick={(e) => this.handleSubmit()}
          >
            Submit
          </button>
        </section>
      </div>
    );
  }
}
