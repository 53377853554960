import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Settings extends Component{

    render(){

        return(
            <div>
 {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
     */}


    <header>
        <div className="back-links">
            <a href="/">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>settings</h2>
                </div>
            </a>
        </div>
    </header>
   


    >
    <section className="px-15 top-space pt-0 ratio_40">
        <div className="help-img rounded-1">
            <img src="assets/images/setting.jpg" className="img-fluid bg-img bg-top" alt=""/>
        </div>
    </section>
    


    {/* <!-- setting section start --> */}
    <section className="px-15 pt-3">
        <h2 className="mb-2">Settings</h2>
        <div className="row">
            <div className="form-group toggle-sec col-12 mb-3">
                <label>Dark <span>Lorem ipsum dolor sit amet
                    </span></label>
                <div className="button toggle-btn">
                    <input id="darkButton" type="checkbox" className="checkbox"/>
                    <div className="knobs">
                        <span></span>
                    </div>
                    <div className="layer"></div>
                </div>
            </div>
            <div className="form-group toggle-sec col-12 mb-3">
                <label>RTL
                    <span>Lorem ipsum dolor sit amet</span>
                </label>
                <div className="button toggle-btn">
                    <input id="rtlButton" type="checkbox" className="checkbox"/>
                    <div className="knobs">
                        <span></span>
                    </div>
                    <div className="layer"></div>
                </div>
            </div>
            <div className="form-group toggle-sec col-12">
                <label>Notification
                    <span>Lorem ipsum dolor sit amet</span>
                </label>
                <div className="button toggle-btn">
                    <input type="checkbox" className="checkbox"/>
                    <div className="knobs">
                        <span></span>
                    </div>
                    <div className="layer"></div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- setting section end --> */}



    
    <section className="panel-space"></section>
   

    
    <div className="bottom-panel">
        <ul>
            <li>
                <a href="/">
                    <div className="icon">
                        <i className="iconly-Home icli"></i>
                        <i className="iconly-Home icbo"></i>
                    </div>
                    <span>home</span>
                </a>
            </li>
            <li>
                <a href="/Cat">
                    <div className="icon">
                        <i className="iconly-Category icli"></i>
                        <i className="iconly-Category icbo"></i>
                    </div>
                    <span>category</span>
                </a>
            </li>
            <li>
                <a href="/Kart">
                    <div className="icon">
                        <i className="iconly-Buy icli"></i>
                        <i className="iconly-Buy icbo"></i>
                    </div>
                    <span>cart</span>
                </a>
            </li>
            <li>
                <a href="/Wishlist">
                    <div className="icon">
                        <i className="iconly-Heart icli"></i>
                        <i className="iconly-Heart icbo"></i>
                    </div>
                    <span>wishlist</span>
                </a>
            </li>
            <li>
                <a href="/Profile">
                    <div className="icon">
                        <i className="iconly-Profile icli"></i>
                        <i className="iconly-Profile icbo"></i>
                    </div>
                    <span>profile</span>
                </a>
            </li>
        </ul>
    </div>
  
                
            </div>
        );
    }

}export default Settings;