/* eslint-disable jsx-a11y/anchor-is-valid */
import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import Loader from "react-js-loader";
//import copy from "copy-to-clipboard";
//import ReactTooltip from "react-tooltip";
import Iconmenu from "./iconmenu.js";
import Hearticon from "./Hearticon.js";
import { Userlogin, Discount, whishlist, Price } from "./Helper";
import "react-toastify/dist/ReactToastify.css";
import Copy from "./taptocopy";
import Queue from "./assets/js/Queue.js";

var APiPar = "";
const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
const cookies = new Cookies();
class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainproduct: [],
      relatedproduct: [],
      productimg: [],
      productattr: [],
      variation: [],
      pincode: "",
      charges: [],
      stock: "",
      quantity: 1,
      show: false,
      price: "",
      mrp: "",
      promocode: [],
      totalprice: "",
      title: "Tap to copy",
      size: "",
      loading: true,
      cod:""
    };
    this.cost = {
      Price: "",
    };
    this.commonChange = this.commonChange.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.ToggleClick = this.ToggleClick.bind(this);
  }
  increment() {
    this.setState((prevState) => {
      if (prevState.quantity < this.state.mainproduct.maximum) {
        return {
          quantity: prevState.quantity + 1,
        };
      } else {
        return null;
      }
    });
  }
  decrement() {
    this.setState((prevState) => {
      if (prevState.quantity > this.state.mainproduct.minimum) {
        return {
          quantity: prevState.quantity - 1,
        };
      } else {
        return null;
      }
    });
  }
  ToggleClick = () => {
    this.setState({
      show: true,
    });
  };

  componentDidMount() {
    const { state } = this.props.location;
    var length;
    if (Userlogin() === true) {
      length = cookies.get("local") || [];
      this.setState({
        size: length.length,
      });
    } else {
      const header = {
        method: "POST",
        mode: "cors",
        headers: { Authorization: "Bearer " + localStorage.getItem("U_Id") },
      };
      fetch("https://api.emechmart.com/api/Cart/ShowCartData", header)
        .then((resp) => resp.json())
        .then((res) => {
          //console.log(res);
          var length1 = res.Data.length;
          this.setState({
            size: length1,
          });
        });
    }

    var param = "id=" + encodeURIComponent(state);
    fetch("https://api.emechmart.com/api/Product/GetProductData?" + param)
      .then((resp) => resp.json())
      .then((resp) => {
       // console.log(resp);
        this.setState((currentState) => ({
          mainproduct: resp.Data.main_product,
          relatedproduct: resp.Data.related_products,
          productimg: resp.Data.product_images,
          productattr: resp.Data.product_attributes,
          price: resp.Data.main_product.yourprice,
          mrp: resp.Data.main_product.mrp,
          stock: resp.Data.main_product.stock,
          promocode: resp.Data.applicable_promocodes,
          quantity: parseInt(resp.Data.main_product.minimum),
          totalprice:
            resp.Data.main_product.yourprice * resp.Data.main_product.minimum,
          loading: false,
          cod:resp.Data.main_product.Csah_On_Delivery
        }));
        var queue = new Queue();
       // console.log(localStorage.getItem("views"));
        if (localStorage.getItem("views") === null) {
          queue.insertIntoQueue(state);
        } else {
          queue.setQueue(localStorage.getItem("views"));
          queue.insertIntoQueue(state);
        }
        localStorage.setItem("views", queue.toString());
      });
  }

  commonChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  onrelatedproducts(id) {
    this.props.history.push({ pathname: "/Product", state: id });
  }

  checkpin(e) {
    const { state } = this.props.location;
    if (this.state.pincode.length === 0 || this.state.pincode.length !== 6) {
      toast.dismiss();
      toast.error("Please provide valid city pincode!");
    } else {
      var details =
        "product_id=" +
        encodeURIComponent(state) +
        "&customer_pincode=" +
        encodeURIComponent(this.state.pincode);

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: details,
      };
      fetch(
        "https://api.emechmart.com/api/Product/CheckPincode",
        requestOptions
      )
        .then((resp) => resp.json())
        .then((result) => {
          console.log(result);
          this.ToggleClick();
          var object = JSON.parse(result.Data);
           console.log(object);
          this.setState({
            charges: object,
          });
        });
    }
  }

  addtocart(e) {
    // var SizeAttr = document.getElementsByName("Size");
    // var ColorAttr = document.getElementsByName("checkedradio");
    // var color = "";
    // var size = "";
    const cookies = new Cookies();
    const { state } = this.props.location;
    const { mainproduct, productattr } = this.state;

    // if(SizeAttr.length>0 && ColorAttr.length>0)
    // {
    //         size=$("#floatingSelect").val();
    //     for (let i of ColorAttr) {
    //         if (i.checked) {
    //             color="selected"
    //         }
    //     else{
    //          color="notselected"
    //         }
    //         }
    //        if(color=="selected")
    //        {
    //            if(size!="Select Size")
    //            {
    //                 toast.error("Success");
    //            }
    //            else
    //            {
    //                 toast.error("Please Select the Size");
    //            }
    //        }
    //        else
    //        {
    //                 toast.error("Please Select the Color");
    //        }
    // }
    // else if(SizeAttr.length>0 && ColorAttr.length==0)
    // {
    //     size=$("#floatingSelect").val();
    // }
    if (APiPar === "" && productattr.length !== 0) {
      this.GetVariationsvalue("Color").then((resp) => {
        //(this.state.price);
        if (this.state.quantity > this.state.stock) {
          toast.error("Oops! It's Out of stock", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        } else {
          var localcart = {
            product_id: state,
            variation: APiPar,
            product_name: mainproduct.product_name,
            product_image: mainproduct.productimage,
            var_price: this.state.price,
            AVL_Stock: this.state.stock,
            product_qty: this.state.quantity,
            Cart_Price: this.state.totalprice,
            maximum: mainproduct.maximum,
            minimum: mainproduct.minimum,
            discountprice: mainproduct.discountprice,
            mrpprice: mainproduct.mrp,
            tax: Price(mainproduct.gsttax, this.state.price) - this.state.price,
            dprice: mainproduct.mrp - this.state.price,
          };
          console.log(localcart);
          if (Userlogin() === true) {
            var olditems = cookies.get("local") || [];
            const found = olditems.findIndex((item) => {
              // console.log(item.product_id === state && item.variation === APiPar);
              return item.product_id === state && item.variation === APiPar;
            });
            //  console.log(found);
            if (found > -1) {
              olditems[found].product_qty = olditems[found].product_qty + 1;
              olditems[found].Cart_Price =
                olditems[found].Cart_Price + parseInt(this.state.price);
              // console.log(olditems[found].Cart_Price);
              cookies.set("local", JSON.stringify(olditems));
              toast.dismiss();
              toast.success("Item added to cart!", { autoClose: 3000 });
            } else {
              toast.dismiss();

              // console.log(localcart);
              olditems.push(localcart);
              toast.success("Item added to cart!", { autoClose: 3000 });
              cookies.set("local", JSON.stringify(olditems));
              // console.log(cookies.get("local"));
              this.setState({
                size: cookies.get("local").length,
              });
            }
          } else {
            var pdata = {
              product_id: state,
              product_price: this.state.totalprice,
              product_discount: mainproduct.discountprice,
              product_qty: this.state.quantity,
              variations: APiPar,
            };
            console.log(pdata);
            // localStorage.setItem('Detail',JSON.stringify(localcart))
            var formBody = [];
            for (var property in pdata) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(pdata[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + localStorage.getItem("U_Id"),
              },
              body: formBody,
            };
            fetch(
              "https://api.emechmart.com/api/Cart/AddToCart",
              requestOptions
            )
              .then((response) => response.json())
              .then((resp) => {
                let Success = resp.Success;
                if (Success === true) {
                  toast.dismiss();
                  toast.success("Item added to cart!", { autoClose: 3000 });
                  this.setState({
                    size: resp.Catt_Item,
                  });
                  // console.log(pdata);
                } else {
                  toast.dismiss();
                  toast.error("Something went wrong!", { autoClose: 3000 });
                }
              });
          }
        }
      });
    } else if (this.state.quantity > this.state.stock) {
      toast.error("Oops! It's Out of stock", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    } else {
      var localcart = {
        product_id: state,
        variation: APiPar,
        product_name: mainproduct.product_name,
        product_image: mainproduct.productimage,
        var_price: this.state.price,
        AVL_Stock: this.state.stock,
        product_qty: this.state.quantity,
        Cart_Price: this.state.totalprice,
        maximum: mainproduct.maximum,
        minimum: mainproduct.minimum,
        product_discount: mainproduct.discountprice,
        mrpprice: mainproduct.mrp,
        tax: Price(mainproduct.gsttax, this.state.price) - this.state.price,
        dprice: mainproduct.mrp - this.state.price,
      };

      if (Userlogin() === true) {
        var olditems = cookies.get("local") || [];
        const found = olditems.findIndex((item) => {
          //console.log(item.product_id === state && item.variation === APiPar);
          return item.product_id === state && item.variation === APiPar;
        });
        //  console.log(found);
        if (found > -1) {
          olditems[found].product_qty = olditems[found].product_qty + 1;
          olditems[found].Cart_Price =
            olditems[found].Cart_Price + parseInt(this.state.price);
          //  console.log(olditems[found].Cart_Price);
          cookies.set("local", JSON.stringify(olditems));
          toast.dismiss();
          toast.success("Item added to cart!", { autoClose: 3000 });
        } else {
          toast.dismiss();
          olditems.push(localcart);
          toast.success("Item added to cart!", { autoClose: 3000 });
          cookies.set("local", JSON.stringify(olditems));
          this.setState({
            size: cookies.get("local").length,
          });
          // $("#cartrecord").text(cookies.get("local").length);
        }
      } else {
        var pdata = {
          product_id: state,
          product_price: this.state.totalprice,
          product_discount: mainproduct.discountprice,
          product_qty: this.state.quantity,
          variations: APiPar,
        };

        // localStorage.setItem('Detail',JSON.stringify(localcart))
        var formBody = [];
        for (var property in pdata) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(pdata[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.getItem("U_Id"),
          },
          body: formBody,
        };
        fetch("https://api.emechmart.com/api/Cart/AddToCart", requestOptions)
          .then((response) => response.json())
          .then((resp) => {
            let Success = resp.Success;
            if (Success === true) {
              toast.dismiss();
              toast.success("Item added to cart!", { autoClose: 3000 });
              this.setState({
                size: resp.Catt_Item,
              });
            } else {
              toast.dismiss();
              toast.error("Something went wrong!", { autoClose: 3000 });
            }
          });
      }
    }
  }

  async GetVariationsvalue(name, value, id) {
    if (value === null) {
      toast.error("Please Select the color");
    } else {
      const { state } = this.props.location;

      var color;
      var abc = document.getElementsByName("checkedradio");

      if (abc.length !== 0) {
        for (let i of abc) {
          if (i.checked) {
            color = document.querySelector(
              'input[name="checkedradio"]:checked'
            ).value;

            // console.log(i.value);
          } else {
            color = abc[0].value;
          }
        }
      }
      var size = $("#floatingSelect").val();
      //  alert(size)

      if (size === undefined && abc.length !== 0) {
        color = document.querySelector(
          'input[name="checkedradio"]:checked'
        ).value;

        // eslint-disable-next-line no-useless-concat
        APiPar = name + "," + "=" + color + ",";
        // alert(APiPar)
      } else if (abc.length === 0 && size !== undefined) {
        // eslint-disable-next-line no-useless-concat
        APiPar = "Size" + ",=" + size + ",";
        // alert(APiPar);
      } else {
        //  color = document.querySelector('input[name="checkedradio"]:checked').value;

        // eslint-disable-next-line no-useless-concat
        APiPar = name + "," + "Size" + ",=" + color + "," + size + ",";
        // alert(APiPar);
      }
      console.log(APiPar);
      var param =
        "product_id=" +
        encodeURIComponent(state) +
        "&data=" +
        encodeURIComponent(APiPar);

      const headers = {
        method: "POST",
        mode: "cors",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: param,
      };
      const responseBody = (
        await fetch(
          "https://api.emechmart.com/api/Product/GetVariations",
          headers
        )
      ).json();
      var res = await responseBody;
      console.log(res);
      if (res !== "") {
        this.setState({
          variation: res.Data,
          price: res.Data.product_vprice,
          mrp: res.Data.product_mrp_price,
          stock: res.Data.product_stock,
          totalprice: res.Data.product_vprice * this.state.quantity,
        });
        console.log(this.state);
      }

      //   fetch("https://api.emechmart.com/api/Product/GetVariations", headers)
      //     .then((resp) => resp.json())
      //     .then((res) => {
      //       console.log(res);
      //       this.setState(
      //         (currentState) => ({
      //           variation: res.Data,
      //           price: res.Data.product_vprice,
      //           mrp: res.Data.product_mrp_price,
      //           stock: res.Data.product_stock,
      //         }),
      //         () => {
      //           //alert(this.state.price);
      //         }
      //       );
      //        this.cost={
      //            Price:res.Data.product_vprice
      //        }

      //  this.setState({
      //      variation:res.Data,
      //      price:res.Data.product_vprice,
      //      mrp:res.Data.product_mrp_price,
      //      stock:res.Data.product_stock

      //  });

      //});
    }
  }
  getplusprice(e) {
    const { variation, mainproduct } = this.state;
    if (variation.length === 0) {
      this.setState({
        price: mainproduct.yourprice,
        totalprice: mainproduct.yourprice * (this.state.quantity + 1),
      });
      // price=mainproduct.yourprice
      //  alert(mainproduct.yourprice * (this.state.quantity + 1));
    } else {
      if (this.state.quantity < this.state.stock) {
        this.setState({
          price: variation.product_vprice,
          totalprice: variation.product_vprice * (this.state.quantity + 1),
        });
        //price=variation.product_vprice
      } else {
        this.setState({
          quantity: this.state.stock,
        });
        toast.error("Out of Stock");
      }
    }
  }
  getminusprice(e) {
    const { variation, mainproduct } = this.state;
    if (variation.length === 0 && this.state.quantity > mainproduct.minimum) {
      this.setState({
        price: mainproduct.yourprice,
        totalprice: mainproduct.yourprice * (this.state.quantity - 1),
      });
      //   alert(mainproduct.yourprice * (this.state.quantity - 1));
    } else {
      if (this.state.quantity > mainproduct.minimum) {
        this.setState({
          totalprice: variation.product_vprice * (this.state.quantity - 1),
        });
      }
    }
  }

  render() {
    var path;
    if (Userlogin() === true) {
      path = "/Login";
    } else {
      path = "/Bulk";
    }
    const {
      mainproduct,
      relatedproduct,
      productimg,
      productattr,
      price,
      show,
      charges,
      promocode,
      mrp,
      loading,
      cod
    } = this.state;

    const { state } = this.props.location;
    var settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "30px",
      dots: true,
      arrows: false,
    };
    const productslider = {
      infinite: true,
      slidesToShow: relatedproduct.length,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "60px",
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 365,
          settings: {
            slidesToShow: 2,
            centerPadding: "20px",
          },
        },
      ],
    };

    //const cookies = new Cookies();
    // var olditems = cookies.get("local") || [];

    const available = () => {
      if (this.state.quantity > this.state.stock) {
        return (
          <label style={{ color: "Red", marginBottom: "3px" }}>
            OutofStock
          </label>
        );
      } else {
        return (
          <label style={{ color: "#007744", marginBottom: "3px" }}>
            InStock
          </label>
        );
      }
    };
    const Rating = () => {
      if (mainproduct.product_star !== "0") {
        return (
          <Link
            to={{ pathname: "/Review", state: state }}
            style={{ color: "#007744", fontWeight: "400" }}
          >
            See Reviews
          </Link>
        );
      }
    };
    const radio = (type, Data, name) => {
      if (type === "Radio") {
        return (
          <div>
            {Data.map((data) => (
              <label class="container1">
                {data}
                <input
                  type="radio"
                  name="checkedradio"
                  defaultChecked
                  value={data}
                  onClick={(e) => this.GetVariationsvalue(name, data, state)}
                />
                <span class="check" style={{ background: data }}></span>
              </label>
            ))}
          </div>
        );
      } else if (type === "Dropdown") {
        return (
          <select
            className="form-select"
            id="floatingSelect"
            name="Size"
            onChange={(e) =>
              this.GetVariationsvalue(
                "Color",
                $("#floatingSelect").val(),
                state
              )
            }
          >
            {/* <option defaultselected >Select Size</option> */}
            {Data.map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select>
        );
      }
    };
    const Chargesheet = () => {
      if (mainproduct.requiresshipping === "No") {
        return (
          <div className="service-section">
            <ul>
              <li>
                <img
                  src="assets/svg/delivery.svg"
                  className="img-fluid"
                  alt=""
                />
                Free Delivery
              </li>
              <li>
                <img src="assets/svg/refund.svg" className="img-fluid" alt="" />
                Estimated Time Of Delivery: {charges.etd}
              </li>
            </ul>
          </div>
        );
      } else if (charges === null) {
        return (
          <div className="service-section">
            <ul>
              <li className="img-fluid" style={{ color: "Red" }}>
                Pincode is not avaliable!
              </li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="service-section">
            <ul>
              <li>
                <img
                  src="assets/svg/delivery.svg"
                  className="img-fluid"
                  alt=""
                />
                Shipping Charge: {"\u20B9"}
                {charges.freight_charges}
              </li>
              <li style={{display:cod==="Yes"?"block":"none"}}>
                <img
                  src="assets/svg/payment.svg"
                  className="img-fluid"
                  alt=""
                />
                COD Charges: {"\u20B9"}
                {charges.cod_charges}
              </li>
              <li>
                <img src="assets/svg/refund.svg" className="img-fluid" alt="" />
                Estimated Time Of Delivery: {charges.etd}
              </li>
            </ul>
          </div>
        );
      }
    };
    const offerzone = () => {
      if (promocode.length === 0) {
        return <div className="product-detail-box px-15"></div>;
      } else {
        return (
          <div>
            <div className="divider"></div>
            <div className="product-detail-box px-15">
              <h4 className="page-title">Offers for You</h4>
              {promocode.map((data, index) => (
                <div key={index}>
                  <h5>
                    Use code {data.promocodename} to get flat{" "}
                    {data.discountamount}%
                  </h5>
                  <h6 className="content-color">
                    Use code {data.promocodename} to get flat{" "}
                    {data.discountamount}% upto ₹{data.maxvalue} .Only for
                    products with MRP above ₹{data.minimumvalue}, Only
                    applicable once.
                  </h6>
                  <div className="offer-code">
                    <div className="code">
                      <h6 style={{ marginBottom: "20px" }}>
                        {data.promocodename}
                      </h6>
                      <img
                        src="assets/svg/scissor.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <Copy dataFromParent={data.promocodename} />
                    {/* <h6
                      className="content-color"
                      style={{ "margin-bottom": "20px" }}
                      onClick={(e) => this.copytoclipboard(index)}
                    >
                    {title}
                    </h6> */}
                    {/* <span id={index}>{copy(index)}</span> */}
                    {/* <a
                  onClick={(e) => this.copytoclipboard(index)}
                  className="content-color"
                  data-for="custom-event"
                  data-tip="Copied"
                  data-event="click focus"
                  style={{ "margin-bottom": "20px" }}
                >
                  Tap to copy
                </a>
                <ReactTooltip id="custom-event" globalEventOff="click" afterShow={(e)=>this.copytoclipboard(index)}/> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
    };
    const rpolicy = () => {
      if (mainproduct.returnpolicy === "Yes") {
        return (
          <div className="product-detail-box px-15">
            <h4 className="page-title">Return & Exchange Policy</h4>
            <h4 className="content-color">
            This product is eligible for replacement within 10 days of delivery. Please refer to product replacement policy.
            </h4>
            <Link to={{pathname:"/Reaturn_Policy",state:state}} className="theme-color" >Return & Replacement Policy</Link>
          </div>
        );
      } else {
        return (
          <div className="product-detail-box px-15">
            <h4 className="page-title">No Returns Applicable</h4>
          </div>
        );
      }
    };
    const Similar = () => {
      if (relatedproduct.length < 3) {
        return (
          <section className="px-15 lg-t-space">
            <div className="row gy-3 gx-3">
              {relatedproduct.map((Data) => (
                <div className="col-6 text-center" key={Data.product_id}>
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <a
                        href="/Product"
                        onClick={(e) => this.onrelatedproducts(Data.product_id)}
                      >
                        <img
                          src={Data.product_img}
                          alt=""
                          className="img-fluid bg-img"
                          style={{ width: "70%", padding: "20px" }}
                        />
                      </a>
                      <div className="wishlist-btn">
                        <Hearticon dataFromParent={Data.product_id} />
                      </div>
                      {/* <label>new</label> */}
                    </div>
                    <a
                      href="/Product"
                      onClick={(e) => this.onrelatedproducts(Data.product_id)}
                    >
                      <div className="product-content">
                        <ul className="ratings reactstar">
                          <ReactStars
                            count={5}
                            edit={false}
                            value={parseFloat(Data.product_ratings)}
                            size={18}
                            char={<i className="iconly-Star icbo"></i>}
                            color="#b7babe"
                            activeColor="#007744"
                          />
                        </ul>
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: Data.product_name,
                          }}
                        ></h4>
                        <div className="price">
                          <h4>
                            {"\u20B9"}
                            {Data.your_price} <del></del>
                            {/* <span>{Discount(Data.mrp, Data.your_price)}%</span> */}
                          </h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </section>
        );
      } else {
        return (
          <section className="product-slider-section overflow-hidden px-15">
            <div className="title-section">
              <h2>Similar Products</h2>
            </div>
            <div className="product-slider slick-default pl-15">
              <Slider {...productslider}>
                {relatedproduct.map((Data) => (
                  <div key={Data.product_id}>
                    <div className="product-box ratio_square">
                      <div className="img-part">
                        <a
                          href="/Product"
                          onClick={(e) =>
                            this.onrelatedproducts(Data.product_id)
                          }
                        >
                          <img
                            src={Data.product_img}
                            alt=""
                            className="img-fluid bg-img"
                          />
                        </a>
                        <div className="wishlist-btn">
                          <Hearticon dataFromParent={Data.product_id} />
                        </div>
                      </div>
                      <a href="/Product"  onClick={(e) =>
                            this.onrelatedproducts(Data.product_id)
                          }>
                        <div className="product-content">
                          <ul className="ratings">
                            <ReactStars
                              count={5}
                              edit={false}
                              value={parseFloat(Data.product_ratings)}
                              size={18}
                              char={<i className="iconly-Star icbo"></i>}
                              color="#b7babe"
                              activeColor="#007744"
                            />
                          </ul>

                          <h4>{Data.product_name}</h4>

                          <div className="price">
                            <h4>
                              {"\u20B9"}
                              {Data.your_price}{" "}
                              <del>
                                {"\u20B9"}
                                {Data.mrp}
                              </del>
                              <span>
                                {Discount(Data.mrp, Data.your_price)}%
                              </span>
                            </h4>
                          </div>
                        </div>{" "}
                      </a>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        );
      }
    };

    return loading ? (
      <Loading />
    ) : (
      <div>
        {/* <ToastContainer
          hideProgressBar
          Colored
          theme="colored"
          closeButton={false}
        /> */}
        <header>
          <div className="back-links">
            <Link to={{ pathname: "/Shop", state: mainproduct.Product_Url }}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>{mainproduct.Product_category} </h2>
              </div>
            </Link>
          </div>
          <div className="header-option">
            <ul>
              {/* <li>
                    <a href=""><img src="assets/svg/share-2.svg" className="img-fluid" alt=""/></a>
                </li> */}
              <li>
                <a href="/Wishlist">
                  <i className="iconly-Heart icli"></i>
                </a>
              </li>
              <li>
                <Iconmenu dataFromParent={this.state.size} />
              </li>
            </ul>
          </div>
        </header>

        {/* <!-- slider start --> */}
        <section className="product-page-section top-space">
          <div className="home-slider slick-default theme-dots ratio_asos overflow-hidden">
            <Slider {...settings}>
              <div style={{ width: "100%" }}>
                <div className="home-img">
                  <img
                    src={mainproduct.productimage}
                    className="img-fluid bg-img"
                    alt=""
                  />
                </div>
              </div>

              {productimg.map((Data) => (
                <div>
                  <div className="home-img" key={Data.index}>
                    <img
                      src={Data.image_name}
                      className="img-fluid bg-img"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <div className="product-detail-box px-15 pt-2">
            <div className="main-detail">
              <h2 className="text-capitalize">{mainproduct.product_name}</h2>
              <h6
                className="content-color"
                dangerouslySetInnerHTML={{ __html: mainproduct.productbigdesc }}
              ></h6>
              <div className="rating-section">
                <ul className="ratings">
                  <ReactStars
                    count={5}
                    edit={false}
                    value={parseFloat(mainproduct.product_star)}
                    size={18}
                    char={<i className="iconly-Star icbo"></i>}
                    color="#b7babe"
                    activeColor="#007744"
                  />
                </ul>
                <h6 className="content-color">
                  ({mainproduct.total_rating} ratings)
                </h6>
              </div>
              <div className="price">
                <h4>{available()}</h4>
              </div>
              <div className="price">
                <h4>
                  {" "}
                  {"\u20B9"}
                  {price}{" "}
                  <del>
                    {"\u20B9"}
                    {mrp}
                  </del>{" "}
                  <span>({Discount(mrp, price)}%off)</span>
                </h4>
              </div>
              <h4 style={{ marginTop: "3px" }}>
                {" "}
                Price: {"\u20B9"}
                {Price(mainproduct.gsttax, price)}
                <span className="text-green">
                  {" "}
                  (Including {mainproduct.gsttax}% GSTtax )
                </span>{" "}
              </h4>
              {Rating()}
            </div>
          </div>
          <div className="divider"></div>
          <div className="product-detail-box px-15">
            {/* <div className="size-detail">
                <h4 className="size-title">Select Size: <a href="#">Size Chart</a></h4>
                <ul className="size-select">
                    <li><a href="javacript:void(0)">6</a></li>
                    <li><a href="javacript:void(0)">7</a></li>
                    <li><a href="javacript:void(0)">L</a></li>
                    <li className="disable"><del>XL</del></li>
                </ul>
            </div> */}
            {/* <div className="size-detail">
                <h4 className="size-title">Select Color:</h4>
                <ul className="filter-color">
                    <li>
                        <a href="javascript:void(0)"><div className="color-box light-purple" value="Blue"></div></a>
                    </li>
                    <li className="active">
                        <a href="javascript:void(0)"><div className="color-box light-grey" value="Red"></div></a>
                    </li>
                    {/* <li>
                        <a href="javascript:void(0)"><div className="color-box blue-purple"></div></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)"><div className="color-box light-orange"></div></a>
                    </li> */}
            {/* </ul>
            </div> */}
            {productattr.map((data, index) => (
              <div className="size-detail" key={index}>
                <h4 className="size-title">Select {data.attrname}:</h4>
                {radio(data.attrtype, data.allattrvals, data.attrname)}
                {/* <div dangerouslySetInnerHTML={{__html: variation(data.attrtype,data.allattrvals,data.attrname)}} ></div> */}
              </div>
            ))}

            <div className="size-detail">
              <h4 className="size-title">Quantity:</h4>
              <div className="qty-counter">
                <div className="input-group">
                  <button
                    type="button"
                    className="btn quantity-left-minus"
                    data-type="minus"
                    data-field=""
                  >
                    <img
                      src="assets/svg/minus-square.svg"
                      className="img-fluid"
                      alt=""
                      onClick={(e) => {
                        this.decrement();
                        this.getminusprice();
                      }}
                    />
                  </button>
                  <input
                    type="text"
                    name="quantity"
                    className="form-control form-theme qty-input input-number"
                    value={this.state.quantity}
                    onChange={this.commonChange}
                    readOnly={true}
                  />
                  <button
                    type="button"
                    className="btn quantity-right-plus"
                    data-type="plus"
                    data-field=""
                  >
                    <img
                      src="assets/svg/plus-square.svg"
                      className="img-fluid"
                      alt=""
                      onClick={(e) => {
                        this.increment();
                        this.getplusprice();
                      }}
                    />
                  </button>
                  <input
                    type="hidden"
                    id="minqty"
                    value={mainproduct.minimum}
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    id="maxqty"
                    value={mainproduct.maximum}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>

          {offerzone()}
          <div className="divider"></div>
          {rpolicy()}
          <div className="divider"></div>
          <div className="product-detail-box px-15">
            <h4 className="page-title mb-1">Product Details</h4>
            <h4
              className="content-color mb-3"
              dangerouslySetInnerHTML={{ __html: mainproduct.productbigdesc }}
            ></h4>
            <h4 className="page-title mb-1">
              Model Size & Fit({mainproduct.lengthclass})
            </h4>
            <h4 className="content-color mb-3">
              model height:{mainproduct.height}, width:{mainproduct.width},
              length:{mainproduct.length},weight:{mainproduct.weight}Kg
            </h4>
            <h4 className="page-title mb-1">Key-features</h4>
            <h4
              className="content-color mb-3"
              dangerouslySetInnerHTML={{ __html: mainproduct.keyfeatures }}
            ></h4>

            <Link
              to={{ pathname: path, state: state }}
              className="btn btn-outline text-capitalize w-100 mt-3"
            >
              Bulk Purchase
            </Link>
          </div>

          <div className="divider"></div>
          <div className="check-delivery-section product-detail-box px-15">
            <div className="title-section">
              <h4>Check Delivery</h4>
              <h6 className="content-color">
                Enter Pincode to check delivery date / pickup option
              </h6>
            </div>
            <div className="pincode-form">
              <input
                className="form-control form-theme"
                placeholder="Pin code"
                name="pincode"
                onChange={this.commonChange}
              />
              <a href={null} onClick={(e) => this.checkpin()}>
                Check
              </a>
            </div>
            {show === true ? Chargesheet() : null}
          </div>
          <div className="divider"></div>
        </section>
        {/* <!-- slider end --> */}

        {/* <!-- related section start --> */}
        {Similar()}

        {/* <!-- related section end --> */}

        <section className="panel-space"></section>

        <div className="fixed-panel">
          <div className="row">
            <div className="col-6">
              <a href={null} onClick={(e) => whishlist(state)}>
                <i className="iconly-Heart icli"></i>WISHLIST
              </a>
            </div>
            <div className="col-6">
              <a
                href={null}
                className="theme-color"
                onClick={(e) => this.addtocart()}
              >
                <i className="iconly-Buy icli"></i>ADD TO BAG
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Product;
