/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Hearticon from "./Hearticon.js";
import Iconmenu from "./iconmenu.js";
import { Discount, Userlogin, Navbar } from "./Helper";

const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);

class Main extends Component {
  data = { id: "" };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      Data: [],
      deals: [],
      logo: "",
      biggestdeal: [],
      midslider: [],
      topmenu: [],
      CategorywiseProducts: [],
      Views: [],
      open: false,
      loading: true,
      index: 0,
      name: "User",
      img: "assets/images/user/1.png",
      path: null,
      info: [],
      class: "left",
    };
  }

  // handleToggleClick() {
  //   const { isclick } = this.state;

  //   this.setState({
  //     isclick: !isclick,
  //   });
  // }
  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  componentDidMount() {
  
   
    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2300);

    fetch("https://api.emechmart.com/api/HomePage/GetLayoutData")
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          logo: result.Data.logo,
        });
      });

    fetch("https://api.emechmart.com/api/HomePage/GetHomePageData")
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);
        this.setState({
          Data: resp.Data.sliders,
          deals: resp.Data.deals,
          biggestdeal: resp.Data.biggest_deals,
          midslider: resp.Data.mid_sliders,
          topmenu: resp.Data.top_menu,
          CategorywiseProducts: resp.Data.CategorywiseProducts,
          appContents: resp.Data.appContents,
          // loading: false
        });
        // const dynamic=   React.createElement('div', { className: 'brown'
        //                       }, this.state.appContents)
        //   ReactDOM.render(dynamic, document.getElementById('root'));
      });

    if (Userlogin() === true) {
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("U_Id")
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://api.emechmart.com/api/UserAccount/GetUserProfile",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          this.setState({
            info: res.Data,
            name: res.Data.fullname,
            img: res.Data.uploadphoto,
          });
        });
    }

    if (localStorage.getItem("views") !== null) {
      var param =
        "product_ids=" + encodeURIComponent(localStorage.getItem("views"));
      console.log(param);
      const header = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: param,
      };
      fetch("https://api.emechmart.com/api/Product/GetSelectedProducts", header)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res.Data);
          this.setState({
            Views: res.Data,
          });
        });
    }
  }
  redirect(url) {
    console.log(url);
    var path = url.split("|");
    console.log(path);
    this.props.history.push({ pathname: "/" + path[0], state: path[1] });
  }

  logout(e) {
    localStorage.removeItem("U_Id");
    toast.success("Logout Successfully", { autoClose: 2000 });
    this.props.history.push("/Login");
  }
  // componentWillUnmount() {
  //   clearTimeout(this.isLoading);
  // }
  //  timer = () => setTimeout(()=>{
  //   this.setState({loading: false})
  // }, 2300);
  render() {
    let url = "";
    const {
      Data,
      deals,
      midslider,
      topmenu,
      CategorywiseProducts,
      loading,
      name,
      img,
      info,
      Views,
      appContents,
    } = this.state;
    //console.log(this.state.myref);
    var settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "30px",
      dots: true,
      arrows: true,
    };
    // var brandsetting = {
    //   slidesToShow: 3,
    //   infinite: true,
    //   centerMode: true,
    //   centerPadding: "30px",
    //   dots: false,
    //   arrows: false,
    // };
    const cat = {
      infinite: true,
      slidesToShow: topmenu.length,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "10px",
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 365,
          settings: {
            slidesToShow: 3,
            centerPadding: "20px",
          },
        },
      ],
    };

    const pic = () => {
      if (info.ImageName === "" || Userlogin() === true) {
        return (
          <a href={url} className="user-panel">
            <img
              src="assets/images/user/1.png"
              className="img-fluid user-img"
              alt=""
            />
            <span>Hello, {name}</span>
            <i className="iconly-Arrow-Right-2 icli"></i>
          </a>
        );
      } else {
        return (
          <Link
            to={{ pathname: "/Profile_setting", state: info }}
            className="user-panel"
          >
            <img src={img} className="img-fluid user-img" alt="" />
            <span>Hello, {name}</span>
            <i className="iconly-Arrow-Right-2 icli"></i>
          </Link>
        );
      }
    };

    const logout = () => {
      if (Userlogin() === true) {
        return <></>;
      } else {
        return (
          <a href={null} onClick={(e) => this.logout()}>
            <i className="iconly-Login icli"></i>
            <div className="content">
              <h4>Logout</h4>
            </div>
          </a>
        );
      }
    };

    const Deals = () => {
      if (deals.length !== 0) {
        return (
          <div>
            <div className="divider"></div>
            <section className="deals-section px-15 pt-0">
              <div className="title-part">
                <h2>Deals of the Day</h2>
                {/* <a href="/Shop">See All</a> */}
              </div>
              <div className="row gy-3 gx-3">
                {deals.map((Data) => (
                  <div className="col-6 text-center" key={Data.product_id}>
                    <div className="product-box ratio_square">
                      <div className="img-part">
                        <Link
                          to={{ pathname: "/Product", state: Data.product_id }}
                        >
                          <img
                            src={Data.product_img}
                            alt=""
                            className="img-fluid bg-img"
                            style={{ width: "70%", padding: "20px" }}
                          />
                        </Link>
                        <div className="wishlist-btn">
                          <Hearticon dataFromParent={Data.product_id} />
                        </div>
                        {/* <label>new</label> */}
                      </div>
                      <Link
                        to={{ pathname: "/Product", state: Data.product_id }}
                      >
                        <div className="product-content star-center">
                          <ul className="ratings reactstar ">
                            <ReactStars
                              count={5}
                              edit={false}
                              value={parseFloat(Data.product_ratings)}
                              size={18}
                              char={<i className="iconly-Star icbo"></i>}
                              color="#b7babe"
                              activeColor="#007744"
                            />
                          </ul>
                          <a href="/Product">
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: Data.product_name,
                              }}
                            ></h4>
                          </a>
                          <div className="price">
                            <h4>
                              {"\u20B9"}
                              {Data.your_price} <del>{Data.mrp}</del>
                              <span>
                                {Discount(Data.mrp, Data.your_price)}%
                              </span>
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        );
      }
    };

    const sidemenu = () => {
      if (Userlogin() === true) {
        return (
          <ul className="link-section">
            <li>
              <a href="/Login">
                <i className="iconly-Profile icli"></i>
                <div className="content">
                  <h4>Login</h4>
                  {/* <h6></h6> */}
                </div>
              </a>
            </li>
            <li>
              <a href="/Register">
                <i className="iconly-Document icli"></i>
                <div className="content">
                  <h4>Register</h4>
                  {/* <h6></h6> */}
                </div>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="iconly-Home icli"></i>
                <div className="content">
                  <h4>Home</h4>
                  <h6>Offers, Top Deals, Top Brands</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Cat">
                <i className="iconly-Category icli"></i>
                <div className="content">
                  <h4>Shop by Category</h4>
                  <h6>Valves, Oil Seals, Ball Bearings... </h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Ewishlist">
                <i className="iconly-Heart icli"></i>
                <div className="content">
                  <h4>Your Wishlist</h4>
                  <h6>Your Save Products</h6>
                </div>
              </a>
            </li>

            {/* <li>
          <a href="/Notification">
            <i className="iconly-Notification icli"></i>
            <div className="content">
              <h4>Notification</h4>
              <h6>Offers, Order tracking messages..</h6>
            </div>
          </a>
        </li> */}
          </ul>
        );
      } else {
        return (
          <ul className="link-section">
            {/* <li>
              <a href="/Pages">
                <i className="iconly-Paper icli"></i>
                <div className="content">
                  <h4>Pages</h4>
                   <h6></h6> 
                </div>
              </a>
            </li>*/}
            <li>
              <a href="/">
                <i className="iconly-Home icli"></i>
                <div className="content">
                  <h4>Home</h4>
                  <h6>Offers, Top Deals, Top Brands</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Cat">
                <i className="iconly-Category icli"></i>
                <div className="content">
                  <h4>Shop by Category</h4>
                  <h6>Valves, Oil Seals, Ball Bearings... </h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Wishlist">
                <i className="iconly-Heart icli"></i>
                <div className="content">
                  <h4>Your Wishlist</h4>
                  <h6>Your Save Products</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/History">
                <i className="iconly-Document icli"></i>
                <div className="content">
                  <h4>Orders</h4>
                  <h6>Ongoing Orders, Recent Orders..</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Purchase">
                <i className="iconly-Paper icli"></i>
                <div className="content">
                  <h4>Bulk Purchase</h4>
                </div>
              </a>
            </li>
            <li>
              <a href="/Profile">
                <i className="iconly-Profile icli"></i>
                <div className="content">
                  <h4>Your Account</h4>
                  <h6>Profile, Settings, Saved Cards...</h6>
                </div>
              </a>
            </li>
            {/* <li>
          <a href="/Notification">
            <i className="iconly-Notification icli"></i>
            <div className="content">
              <h4>Notification</h4>
              <h6>Offers, Order tracking messages..</h6>
            </div>
          </a>
        </li> */}
          </ul>
        );
      }
    };
    return loading ? (
      <Loading />
    ) : (
      <div id="parent">
        {/* <ToastContainer
          hideProgressBar
          Colored
          theme="colored"
          closeButton={false}
        /> */}
        <header>
          <div className="nav-bar">
            <img
              src="assets/svg/bar.svg"
              className="img-fluid"
              alt=""
              onClick={(e) => Navbar()}
            />
          </div>
          <a href="/" className="brand-logo">
            <img src={this.state.logo} className="img-fluid main-logo" alt="" />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <div className="header-option">
            <ul style={{ paddingTop: "9px" }}>
              <li>
                <a href="/Search">
                  <i className="iconly-Search icli"></i>
                </a>
              </li>
              {/* <li>
                <a href="/Notification">
                  <i className="iconly-Notification icli"></i>
                </a>
              </li> */}
              <li>
                <a href="/Wishlist">
                  <i className="iconly-Heart icli"></i>
                </a>
              </li>
              <li>
                <Iconmenu />
              </li>
            </ul>
          </div>
        </header>

        <a href={null} className="overlay-sidebar">
          ddd
        </a>
        <div className="header-sidebar">
          {pic()}
          <div className="sidebar-content">
            {sidemenu()}
            <div className="divider"></div>
            <ul className="link-section">
              <li>
                <a href="/About">
                  <i className="iconly-Info-Square icli"></i>
                  <div className="content">
                    <h4>About us</h4>
                    <h6>About Emechmart</h6>
                  </div>
                </a>
              </li>
              <li>
                <a href="Help">
                  <i className="iconly-Call icli"></i>
                  <div className="content">
                    <h4>Help/Customer Care</h4>
                    <h6>Customer Support, FAQs</h6>
                  </div>
                </a>
              </li>
              <li>{logout()}</li>
            </ul>
          </div>
        </div>

        {/* <!-- category start --> */}
        <section className="category-section top-space1">
          {/* <ul className="category-slide">
            <Slider {...cat}>
              {topmenu.map((Data) => (
                <li key={Data.index}>
                  <a href={Data.menu_link} className="category-box">
                    <img src={Data.menu_image} className="img-fluid" alt="" />
                    <h4>{Data.menu_text}</h4>
                  </a>
                </li>
              ))}
            </Slider>
             <li>      
        <a href="/Inner_category" className="category-box">
          <img src="assets/images/top-category/beauty.png" className="img-fluid" alt=""/>
          <h4>beauty</h4>
        </a>
      </li> 
          </ul>*/}
        </section>

        <div className="t-12 b-20"></div>
        {/* <!-- category end --> */}

        {/* <!-- home slider start --> */}

        <section
          className="pt-0 home-section ratio_55 "
          id="Section1"
          ref={this.myRef}
        >
          <div className="home-slider slick-default theme-dots">
            <Slider {...settings}>
              {Data.map((Data) => (
                <div key={Data.index}>
                  <div className="slider-box">
                    <img
                      src={Data.Homepageslider_image}
                      className="img-fluid bg-img"
                      alt=""
                    />
                    <div className="slider-content">
                      <div>
                        <h2>{Data.Maintitle}</h2>
                        <h1>{Data.Homepageslider_subtitle}</h1>
                        <button
                          onClick={(e) =>
                            this.redirect(Data.Homepageslider_b1_url)
                          }
                          className="btn btn-solid"
                        >
                          SHOP NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {/* 
       <div>
             <div className="slider-box">
          <img src="https://images.emechmart.com/Content/ModernSlider/RealImage/27365.jpg" className="img-fluid bg-img" alt=""/>
          <div className="slider-content">
            <div>
              <h2>Welcome To Multikart</h2>
              <h1>Flat 50% OFF</h1>
              <h6>Free Shipping Till Mid Night</h6>
              <a href="/Shop" className="btn btn-solid">SHOP NOW</a>
            </div>
          </div>
        </div>
      </div>
      <div>
             <div className="slider-box">
          <img src="https://images.emechmart.com/Content/ModernSlider/RealImage/48166.jpg" className="img-fluid bg-img" alt=""/>
          <div className="slider-content">
            <div>
              <h2>Welcome To Multikart</h2>
              <h1>Flat 50% OFF</h1>
              <h6>Free Shipping Till Mid Night</h6>
              <a href="/Shop" className="btn btn-solid">SHOP NOW</a>
            </div>
          </div>
        </div>
      </div> */}
          </div>
        </section>
        <div>
          <Slider {...cat}>
            {topmenu.map((Data) => (
              <div class="col-md-3 text-center box">
                <div class="bx-main-new text-center">
                  <div class="img-div-new">
                    <Link to={{ pathname: "/Shop", state: Data.menu_link }}>
                      <img src={Data.menu_image} class="main-img-new" alt="" />
                    </Link>
                  </div>
                  <Link to={{ pathname: "/Shop", state: Data.menu_link }}>
                    <div className="testWrap">
                      <h5>{Data.other_text}</h5>
                      <p>{Data.menu_text}</p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* <!-- home slider end --> */}

        {/* <!-- deals section start --> */}
        {Deals()}
        <div className="divider"></div>
        {/* <!-- deals section end --> */}

        {/* <!-- tab section start --> */}
        <section className="pt-0 tab-section">
          <div className="title-section px-15">
            <h2>Category wise Products</h2>
            {/* <h3></h3> */}
          </div>
          <div className="tab-section brand-section pl-15">
            <div style={{ marginBottom: "20px" }}>
              <ul className="nav nav-tabs custom_tab pl-15">
                {/* <Tabs value={index}  onChange={this.handleChange} >
                          {CategorywiseProducts.map((Data)=>(
                    <Tab label={Data.category_name} />
                ))}
             
                     </Tabs> */}

                {CategorywiseProducts.map((Data) => (
                  <li
                    className="nav-item theme-tab"
                    style={{ display: "contents" }}
                    key={Data.index}
                  >
                    <button
                      className={"nav-link " + Data.Button_Class}
                      data-bs-toggle="tab"
                      data-bs-target={
                        "#" +
                        Data.category_name
                          .replaceAll(" ", "-")
                          .replaceAll("&", "-")
                      }
                      type="button"
                    >
                      {Data.category_name}
                    </button>
                  </li>
                ))}

                {/* <li className="nav-item">
                 <button className="nav-link" data-bs-toggle="tab" data-bs-target="#top" type="button">Top Picks</button>
                </li>*/}
              </ul>
            </div>
            <div className="tab-content px-15">
              {CategorywiseProducts.map((Data, index) => (
                <div
                  className={"tab-pane fade " + Data.Product_Class}
                  id={Data.category_name
                    .replaceAll(" ", "-")
                    .replaceAll("&", "-")}
                >
                  <div className="row gy-3 gx-3">
                    {Data.products.map((Data) => (
                      <div
                        className="col-md-4 col-6 text-center"
                        key={Data.index}
                      >
                        <div className="product-box ratio_square">
                          <div className="img-part">
                            <Link
                              to={{
                                pathname: "/Product",
                                state: Data.product_id,
                              }}
                            >
                              <img
                                src={Data.product_img}
                                alt=""
                                className="img-fluid bg-img"
                                style={{ padding: "20px" }}
                              />
                            </Link>
                            <div className="wishlist-btn">
                              <Hearticon dataFromParent={Data.product_id} />
                              {/* <FavoriteBorderIcon style={{color:"lightgrey"}} onClick={(e) => this.handleToggleClick()} /> */}
                            </div>
                          </div>
                          <Link
                            to={{
                              pathname: "/Product",
                              state: Data.product_id,
                            }}
                          >
                            <div className="product-content">
                              <ul className="ratings reactstar">
                                <ReactStars
                                  count={5}
                                  edit={false}
                                  value={parseFloat(Data.product_ratings)}
                                  size={18}
                                  char={<i className="iconly-Star icbo"></i>}
                                  color="#b7babe"
                                  activeColor="#007744"
                                />
                              </ul>
                              <a href="/Product">
                                <h4>{Data.product_name}</h4>
                              </a>
                              <div className="price">
                                <h4>
                                  {"\u20B9"}
                                  {Data.your_price}{" "}
                                  <del>
                                    {"\u20B9"}
                                    {Data.mrp}
                                  </del>
                                  <span>
                                    {Discount(Data.mrp, Data.your_price)}%
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <!-- tab section end --> */}

        {/* <!-- timer banner start --> */}
        {/* <section className="banner-timer">
    <div className="banner-bg">
      <div className="banner-content">
        <div>
          <h6>Denim Wear</h6>
          <h2>Sales Starts In</h2>
          <div className="counters">
            <div className="counter d-none">
              <span id="days">NA</span>
              <p>Days</p>
            </div>
            <div className="counter">
              <span id="hours">NA</span>
              <p>Hours</p>
            </div>
            <div className="counter">
              <span id="minutes">NA</span>
              <p>Minutes</p>
            </div>
            <div className="counter">
              <span id="seconds">NA</span>
              <p>Seconds</p>
            </div>
          </div>
          <a href="/Shop">explore now</a>
        </div>
      </div>
      <div className="banner-img">
        <img src="assets/images/banner-image.png" className="img-fluid" alt=""/>
      </div>
    </div>
  </section>
  <!-- timer banner end --> */}

        {/* <!--middle banner start--> */}
        <section
          className=" home-section ratio_55"
          style={{ paddingtop: "30px" }}
        >
          <div className="home-slider slick-default theme-dots">
            <Slider {...settings}>
              {midslider.map((Data1) => (
                <div key={Data1.index}>
                  <div className="slider-box">
                    <img
                      src={Data1.Homepageslider_image}
                      className="img-fluid bg-img"
                      alt=""
                    />
                    <div
                      className={
                        "slider-content t-" +
                        Data1.Homepageslider_textposition.toLowerCase()
                      }
                    >
                      <div>
                        <h2>{Data1.Maintitle}</h2>
                        <h1>{Data1.Homepageslider_subtitle}</h1>
                        <button
                          onClick={(e) =>
                            this.redirect(Data1.Homepageslider_b1_url)
                          }
                          className="btn btn-solid"
                        >
                          SHOP NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
        {/* <!--middle banner end--> */}

        <div className="divider"></div>
        <section className="deals-section px-15 pt-0">
          {appContents.map((data) => (
            <div
              key={data.id}
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            ></div>
          ))}
        </section>

        <section className="deals-section px-15 pt-0">
          <div className="title-part">
            <h2>Recent Views</h2>
            {/* <a href="/Shop">See All</a> */}
          </div>
          <div className="product-section">
            <div className="row gy-3">
              {Views.map((Data) => (
                <div className="col-12" key={Data.product_id}>
                  <div className="product-inline">
                    <Link
                      to={{
                        pathname: "/Product",
                        state: Data.product_id,
                      }}
                    >
                      <img
                        src={Data.product_img}
                        className="img-fluid"
                        alt=""
                      />
                    </Link>
                    <div className="product-inline-content">
                      <div>
                        <Link
                          to={{
                            pathname: "/Product",
                            state: Data.product_id,
                          }}
                        >
                          <h4>{Data.product_name} </h4>
                        </Link>
                        {/* <h5>by Mango</h5> */}
                        <div className="price">
                          <h4>
                            {"\u20B9"}
                            {Data.your_price}{" "}
                            <del>
                              {"\u20B9"}
                              {Data.mrp}
                            </del>
                            <span>{Discount(Data.mrp, Data.your_price)}%</span>
                          </h4>
                        </div>
                        <ul className="ratings">
                          <ReactStars
                            count={5}
                            edit={false}
                            value={parseFloat(Data.product_ratings)}
                            size={18}
                            char={<i className="iconly-Star icbo"></i>}
                            color="#b7babe"
                            activeColor="#007744"
                          />
                        </ul>
                      </div>
                    </div>
                    {/* <div className="wishlist-btn">
                        <Hearticon dataFromParent={Data.product_id} />
                      </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <!-- brands section start --> */}
        {/* <section className="brand-section pl-15">
          <h2 className="title">Biggest Deals on Top Brands</h2>
          <div className="brand-slider slick-default">
            <Slider {...brandsetting}>
              {biggestdeal.map((Data) => (
                <div key={Data.index}>
                  <a className="brand-box" href={Data.banner_link}>
                    <img src={Data.banner_img} className="img-fluid" alt="" />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </section>
        <div className="divider"></div> */}
        {/* <!-- brands section end --> */}

        {/* <!-- kids corner section start --> */}
        {/* <section className="pt-0 product-slider-section overflow-hidden">
          <div className="title-section px-15">
            <h2>The Kids Corner</h2>
            <h3>Clothing for your Li’l One’s </h3>
          </div>
          <div className="product-slider slick-default pl-15">
             <Slider {...productslider}>
            <div>
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product">
                    <img
                      src="assets/images/products/10.jpg"
                      alt=""
                      className="img-fluid bg-img"
                    />
                  </a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo empty"></i>
                    </li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>
                      $32.00 <del>$35.00</del>
                      <span>20%</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product">
                    <img
                      src="assets/images/products/8.jpg"
                      alt=""
                      className="img-fluid bg-img"
                    />
                  </a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo"></i>
                    </li>
                    <li>
                      <i className="iconly-Star icbo empty"></i>
                    </li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>
                      $32.00 <del>$35.00</del>
                      <span>20%</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            </Slider>
          </div>
        </section> */}
        {/* <!-- kids corner section end --> */}

        {/* <!-- offer corner start --> */}
        {/* <section className="offer-corner-section px-15">
    <h2 className="title">Offer Corner</h2>
    <div className="row g-3">
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">Under $50.00</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">Flat $20 OFF</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">buy 1 get 1</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">upto 50% off</a>
        </div>
      </div>
    </div>
  </section> */}
        {/* <!-- offer corner end --> */}

        <section className="panel-space"></section>

        <div className="bottom-panel">
          <ul>
            <li className="active">
              <a href="index.html">
                <div className="icon">
                  <i className="iconly-Home icli"></i>
                  <i className="iconly-Home icbo"></i>
                </div>
                <span>home</span>
              </a>
            </li>
            <li>
              <a href="/Cat">
                <div className="icon">
                  <i className="iconly-Category icli"></i>
                  <i className="iconly-Category icbo"></i>
                </div>
                <span>category</span>
              </a>
            </li>
            <li>
              <a href="/Kart">
                <div className="icon">
                  <i className="iconly-Buy icli"></i>
                  <i className="iconly-Buy icbo"></i>
                </div>
                <span>cart</span>
              </a>
            </li>
            <li>
              <a href="/Wishlist">
                <div className="icon">
                  <i className="iconly-Heart icli"></i>
                  <i className="iconly-Heart icbo"></i>
                </div>
                <span>wishlist</span>
              </a>
            </li>
            <li>
              <a href="/Profile">
                <div className="icon">
                  <i className="iconly-Profile icli"></i>
                  <i className="iconly-Profile icbo"></i>
                </div>
                <span>profile</span>
              </a>
            </li>
          </ul>
        </div>
        <script src="assets/js/jquery-3.3.1.min.js"></script>
        <script src="assets/js/bootstrap.bundle.min.js"></script>
        <script src="assets/js/slick.js"></script>
        <script src="assets/js/script.js"></script>

        {/* <!-- pwa install app popup start --> */}
        {/* <div
          className="offcanvas offcanvas-bottom addtohome-popup"
          tabindex="-1"
          id="offcanvas"
        >
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="offcanvas-body small">
            <div className="app-info">
              <img
                src="assets/images/logo/logo48.png"
                className="img-fluid"
                alt=""
              />
              <div className="content">
                <h3>Emechmart-app</h3>
                <a href="https://m.emechmart.com">www.Emechmart.com</a>
              </div>
            </div>
            <a
              href="javascript:void(0)"
              className="btn btn-solid install-app"
              id="installApp1"
            >
              add to home screen
            </a>
          </div>
        </div> */}
      </div>
    );
  }
}
export default Main;
