import React, { Component } from "react";
import Loader from "react-js-loader";
import { saveAs } from 'file-saver';

//import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Return_track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      P_details: {},
      shippingRates: {},
      billingaddress: {},
      deliveryAddress: {},
      display: "block",
      loading: true,
      status: 0,
      show: false,
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    var values = {
      orderid: state.id,
      enc_orderitemid: state.enc_id,
    };
    var formBody = [];
    for (var property in values) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(values[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Order/TrackReturnOrder", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        this.setState((currentState) => ({
          Data: res.Data,
          deliveryAddress: res.Data.deliveryAddress,
          P_details: res.Data.singleProduct,
          shippingRates: res.Data.singleProduct.shippingRates,
          loading: false,
        }));

        if (this.state.P_details.return_status === "ReturnDenied") {
          this.setState({
            display: "none",
          });
        }
      });
  }
  downloadinvoice(){
    const{Data}=this.state
          saveAs(Data.invoice_link, "Taxinvoice.pdf");
   
  }

  renderSwitch() {
    switch (this.state.P_details.return_status) {
      case "ReturnAccepted":
        return 1;
      case "RefundInitiated":
        return 3;
      case "ReturnCompleted":
        return 2;
      case "Refunded":
        return 4;
      default:
        return 0;

      // code block
    }
  }

  render() {
    let url="";
    const {
      Data,
      P_details,
      shippingRates,
      deliveryAddress,
      display,
      loading,
    } = this.state;

    const amount =
      parseInt(P_details.grandtotal) + parseFloat(shippingRates.final_rate);

    const button = () => {
      if (Data.invoice_link!=="") {
        return (
          <button onClick={(e)=>this.downloadinvoice()} className="btn btn-outline content-color w-100">
            Download Credite Note
          </button>
        );
      }
    };
    const Track = () => {
      if (P_details.awb_code === null) {
      } else {
        return (
          <div>
            <h5 className="content-color my-1">
              Tracking Number:{P_details.awb_code}
            </h5>
            <a className="theme-color" href={url}>
              Track Order
            </a>
          </div>
        );
      }
    };
    const Notice = () => {
      if (P_details.return_status === "ReturnDenied") {
        return (
          <div>
            {/* <div class="divider"></div> */}
            <div className="alert alert-danger d-flex align-items-center top-space">
              <label>
                Your return request has been denied by admin. Please contact
                admin for any issue! At care@emechmart.com
              </label>
            </div>
          </div>
        );
      }
    };
    return loading ? (
      <Loading />
    ) : (
      <div>
        <header className="bg-transparent">
          <div className="back-links">
            <a href="/History">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Order Details</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- product detail start --> */}
        <div className="map-product-section px-15">
          <div className="order-box" style={{ background: "white" }}>
            <div className="d-flex align-items-center">
              <img
                src={P_details.productimage}
                className="img-fluid order-img"
                alt=""
              />
              <div className="media-body">
                <h4>{P_details.productname} </h4>
                <h5 className="content-color my-1">
                  {P_details.variation} Qty:{P_details.productquantity}
                </h5>
                <h5 className="content-color my-1">
                  Total Amount:{amount.toFixed(0)}
                </h5>
                <div className="d-flex">
                  <div>
                    <h6 className="content-color">Ordered:{Data.orderdate}</h6>
                  </div>
                  {/* <div>
                     <h6>Dispatched</h6>
                 <h6 className="content-color">Delivery Status:</h6>
                    </div> */}
                </div>
                {Track()}
              </div>
             
            </div>
            {/* <div className="delivery-status" >
                       <div className="d-flex">
                            <div>
                                <h6 className="content-color">Ordered:</h6>
                                <h6>26th May, 2021</h6> 
                            </div>
                            <div>
                                 <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6> 
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
        {/* <!-- product detail end --> */}

        {/* <!-- order tracking start --> */}
        {Notice()}
        <div className="order-track px-15" style={{ display: display }}>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 2 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat">Refunded</p>
              {/* <span className="order-track-text-sub">expected delivery on monday</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 3 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat">Refund process</p>
              {/* <span className="order-track-text-sub">10.00 am, 21/05/2020</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 2 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> Return Completed</p>
              {/* <span className="order-track-text-sub">12.00 am, 20/05/2020</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 1 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> Return Pocess</p>
              {/* <span className="order-track-text-sub">20/05/2020</span> */}
            </div>
          </div>
        </div>
        {/* <div className="rate-section px-15">
    <ul>
      <li><i className="iconly-Star icli"></i> Rate & Review Product</li>
      <li><i className="iconly-Star icli"></i> Need Help?</li>
    </ul>
  </div> */}
        <div className="divider"></div>
        {/* <!-- order tracking end --> */}
        <div className="px-15 section-b-space">
          <h6 className="tracking-title content-color">
            Return Details
          </h6>
          <div className="alert alert-primary d-flex align-items-center">
            <label>Waiting for approval!</label>
          </div>
          <div className="order-details">
            <ul>
              <li>
                <h4>
                  {" "}
                  <label className="fw-bold">
                    Return Status: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  </label>
                  {P_details.return_status}{" "}
                </h4>
              </li>
              <li>
                <h4>
                  {" "}
                  <label className="fw-bold">
                    Bank Account Holder Name:&emsp;
                  </label>
                  {Data.bankaccountholdername}
                </h4>
              </li>
              <li>
                <h4>
                  <label className="fw-bold">
                    Bank Account Number:&emsp;&emsp;&emsp;&nbsp;
                  </label>
                  {Data.bankaccountnumber}
                </h4>
              </li>
              <li>
                <h4>
                  {" "}
                  <label className="fw-bold">
                    Bank
                    IFSC:&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;
                  </label>
                  {Data.bankifsc}
                </h4>
              </li>
              <li>
                <h4>
                  {" "}
                  <label className="fw-bold">
                    Reason:&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;{" "}
                  </label>
                  {Data.reason}
                </h4>
              </li>
            </ul>
          </div>
        </div>
        <div className="divider"></div>

        {/* <!-- address section start --> */}
        <div className="d-flex">
          <div className="px-15" style={{ paddingRight: "24px" }}>
            <h6 className="tracking-title content-color">Delivery Address</h6>
            <h4 className="fw-bold mb-1">{deliveryAddress.fullname}</h4>
            <h4 className="content-color">{deliveryAddress.line1}</h4>
            <h4 className="content-color">{deliveryAddress.line2}</h4>
            <h4 className="content-color">
              {deliveryAddress.town},{deliveryAddress.state}
            </h4>
            <h4 className="content-color">{deliveryAddress.country}</h4>
            <h4 className="fw-bold mt-1 mb-minus-4">
              Phone No:{deliveryAddress.mobileno}
            </h4>
          </div>
        </div>
        <div className="divider"></div>
        {/* <!-- address section end --> */}

        {/* <!-- order details section start --> */}
        <div className="px-15 section-b-space">
          {/* <h6 className="tracking-title content-color">Price Details</h6>
    <div className="order-details">
      <ul>
        <li>
          <h4>Bag total <span>$220.00</span></h4>
        </li>
        <li>
          <h4>Bag savings <span className="text-green">-$20.00</span></h4>
        </li>
        <li>
          <h4>Coupon Discount <span>$20.00</span></h4>
        </li>
        <li>
          <h4>Delivery <span>$50.00</span></h4>
        </li>
      </ul>
      <div className="total-amount">
        <h4>Total Amount <span>$270.00</span></h4>
      </div> */}
          {button()}
          {/* </div> */}
        </div>
        {/* <!-- order details section end --> */}
      </div>
    );
  }
}
export default Return_track;
