import React, { Component } from "react";
import Heart from "react-animated-heart";
import { toast } from "react-toastify";
import {whishlist,Deletewhishlist ,Userlogin} from "./Helper";



export default class Hearticon extends Component{
   constructor(props){
        super(props);
        this.state={
            show:false
        }
    }
  async handleToggleClick() {
    if(Userlogin()===true){
      toast.dismiss();
     toast.info("Please login to use wishlist",{autocomplete:2000})

    }
    else{
        const { show } = this.state; 
    this.setState({
      show: !show
    });
    }
    
  }
 async clickevent(){
      await this.handleToggleClick()
      
   if(this.state.show===true){
       whishlist(this.props.dataFromParent)
   }
   else if(Userlogin()!==true){
       Deletewhishlist(this.props.dataFromParent)
   }
   else{

   }
  }
    render(){
        const{show}=this.state;
        return( 
             <Heart isClick={show} onClick={(e)=>this.clickevent()}/>
        )
    }
  }
