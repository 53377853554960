import React, { Component } from "react";


class Ebulk extends Component{

    render(){

        return(
            <div>
                 {/* <!-- loader strat -->
    <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}


    
    <header>
        <div className="back-links">
            <a href="/">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Bulk Purchase</h2>
                </div>
            </a>
        </div>
        {/* <div className="header-option">
            <ul>
                <li>
                    <a href="/"><i className="iconly-Buy icli"></i></a>
                </li>
            </ul>
        </div> */}
    </header>
   


    {/* <!-- empty cart start --> */}
    <section className="px-15">
        <div className="empty-cart-section text-center">
            <img src="assets/images/ebulk.png" className="img-fluid" alt=""/>
            <h2>Whoops !!nothing to show</h2>
            <p>

            </p>
            <a href="/" className="btn btn-solid w-100">start shopping</a>
        </div>
    </section>
            </div>
        );
    }

}export default Ebulk;