import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import $ from "jquery";
import { toast } from "react-toastify";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";
import { Userlogin } from "./Helper";

const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      name: "",
      email: "",
      photo: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2300);
    if (localStorage.getItem("U_Id") === null) {
      this.setState({
        name: " Hello User",
        email: "hellouser@gmail.com",
        photo: "assets/images/user/1.png",
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("U_Id")
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://api.emechmart.com/api/UserAccount/GetUserProfile",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res.Data);
          this.setState({
            info: res.Data,
            name: res.Data.fullname,
            email: res.Data.email,
            photo: res.Data.uploadphoto,
          });
        });
    }
  }
  componentWillUnmount() {
    clearTimeout(this.isLoading);
  }
  logout(e) {
    localStorage.removeItem("U_Id");
    toast.success("Logout Successfully", { autoClose: 2000 });
    this.props.history.push("/Login");
  }

  render() {
    const { info, name, email, photo, loading } = this.state;
    const edit = () => {
      if (Userlogin() === true) {
      } else {
        return (
          <Link
            to={{ pathname: "/Profile_setting", state: info }}
            className="edit-btn"
          >
            Edit
          </Link>
        );
      }
    };
    const pic = () => {
      if (info.ImageName === "" || Userlogin() === true) {
        return (
          <img
            src="assets/images/user/1.png"
            className="img-fluid user-img"
            alt=""
          />
        );
      } else {
        return <img src={photo} className="img-fluid" alt="" />;
      }
    };
    const logout = () => {
      if (Userlogin() === true) {
        return <></>;
      } else {
        return (
          <div className="px-15">
            <button
              className="btn btn-outline w-100 content-color"
              onClick={(e) => this.logout()}
            >
              LOG OUT
            </button>
          </div>
        );
      }
    };
    const menu = () => {
      if (Userlogin() === true) {
        return (
          <ul className="link-section">
            <li>
              <a href="/Login">
                <i className="iconly-Profile icli"></i>
                <div className="content">
                  <h4>Login</h4>
                  {/* <h6></h6> */}
                </div>
              </a>
            </li>
            <li>
              <a href="/Register">
                <i className="iconly-Document icli"></i>
                <div className="content">
                  <h4>Register</h4>
                  {/* <h6></h6> */}
                </div>
              </a>
            </li>
            <li>
              <a href="/Wishlist">
                <i className="iconly-Heart icli"></i>
                <div className="content">
                  <h4>Your Wishlist</h4>
                  <h6>Your Save Products</h6>
                </div>
              </a>
            </li>

            {/* <li>
                <a href="/Notification">
                    <i className="iconly-Notification icli"></i>
                    <div className="content">
                        <h4>Notification</h4>
                        <h6>Offers, Order tracking messages..</h6>
                    </div>
                </a>
            </li>
            <li>
                <a href="/Settings.html">
                    <i className="iconly-Setting icli"></i>
                    <div className="content">
                        <h4>Settings</h4>
                        <h6>Dark mode, RTL, Notification</h6>
                    </div>
                </a>
            </li> */}
            {/* <li>
                <a href="/Profile_setting">
                    <i className="iconly-Password icli"></i>
                    <div className="content">
                        <h4>Profile setting</h4>
                        <h6>Full Name, Password..</h6>
                    </div>
                </a>
            </li> */}
          </ul>
        );
      } else {
        return (
          <ul className="link-section">
            {/* <li>
              <a href="/Pages">
                <i className="iconly-Paper icli"></i>
                <div className="content">
                  <h4>Pages</h4>
                  <h6>Ongoing Orders, Recent Orders..</h6>
                </div>
              </a>
            </li> */}
             <li>
              <a href="/Wishlist">
                <i className="iconly-Heart icli"></i>
                <div className="content">
                  <h4>Your Wishlist</h4>
                  <h6>Your Save Products</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/History">
                <i className="iconly-Document icli"></i>
                <div className="content">
                  <h4>Orders</h4>
                  <h6>Ongoing Orders, Recent Orders..</h6>
                </div>
              </a>
            </li>
             <li>
              <a href="/Purchase">
                <i className="iconly-Paper icli"></i>
                <div className="content">
                  <h4>Bulk Purchase</h4>
                </div>
              </a>
            </li>
           
            {/* <li>
              <a href="/Saved_cards">
                <i className="iconly-Wallet icli"></i>
                <div className="content">
                  <h4>Payment</h4>
                  <h6>Saved Cards, Wallets</h6>
                </div>
              </a>
            </li> */}
            {/* <li>
              <a href="/Saved_address">
                <i className="iconly-Location icli"></i>
                <div className="content">
                  <h4>Saved Address</h4>
                  <h6>Home, office.. </h6>
                </div>
              </a>
            </li> */}
            {/* <li>
                <a href="#">
                    <img src="assets/images/flag.png" className="img-fluid" alt=""/>
                    <div className="content">
                        <h4>Langauge</h4>
                        <h6>Select your Language here..</h6>
                    </div>
                </a>
            </li> */}
            {/* <li>
                <a href="/Notification">
                    <i className="iconly-Notification icli"></i>
                    <div className="content">
                        <h4>Notification</h4>
                        <h6>Offers, Order tracking messages..</h6>
                    </div>
                </a>
            </li>
            <li>
                <a href="/Settings.html">
                    <i className="iconly-Setting icli"></i>
                    <div className="content">
                        <h4>Settings</h4>
                        <h6>Dark mode, RTL, Notification</h6>
                    </div>
                </a>
            </li> */}
            <li>
              <Link to={{ pathname: "/Profile_setting", state: info }}>
                <i className="iconly-Password icli"></i>
                <div className="content">
                  <h4>Profile setting</h4>
                  <h6>Full Name, Password..</h6>
                </div>
              </Link>
            </li>
          </ul>
        );
      }
    };

    return loading ? (
      <Loading />
    ) : (
      <div>
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}

        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Profile</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- profile section start --> */}
        <section className="top-space pt-0">
          <div className="profile-detail">
            <div className="media">
              {pic()}
              <div className="media-body">
                <h2>{name}</h2>
                <h6>{email}</h6>
                {edit()}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- profile section end --> */}

        {/* <!-- link section start --> */}
        <div className="sidebar-content">
          {menu()}
          <div className="divider"></div>
          <ul className="link-section">
            <li>
              <a href="/TC">
                <i className="iconly-Info-Square icli"></i>
                <div className="content">
                  <h4>Terms & Conditions</h4>
                  <h6>T&C for use of Platform</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/Help">
                <i className="iconly-Call icli"></i>
                <div className="content">
                  <h4>Help/Customer Care</h4>
                  <h6>Customer Support, FAQs</h6>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {logout()}
        {/* <!-- link section end --> */}

        <section className="panel-space"></section>

        <div className="bottom-panel">
          <ul>
            <li>
              <a href="/">
                <div className="icon">
                  <i className="iconly-Home icli"></i>
                  <i className="iconly-Home icbo"></i>
                </div>
                <span>home</span>
              </a>
            </li>
            <li>
              <a href="/Cat">
                <div className="icon">
                  <i className="iconly-Category icli"></i>
                  <i className="iconly-Category icbo"></i>
                </div>
                <span>category</span>
              </a>
            </li>
            <li>
              <a href="/Kart">
                <div className="icon">
                  <i className="iconly-Buy icli"></i>
                  <i className="iconly-Buy icbo"></i>
                </div>
                <span>cart</span>
              </a>
            </li>
            <li>
              <a href="/Wishlist">
                <div className="icon">
                  <i className="iconly-Heart icli"></i>
                  <i className="iconly-Heart icbo"></i>
                </div>
                <span>wishlist</span>
              </a>
            </li>
            <li className="active">
              <a href="/Profile">
                <div className="icon">
                  <i className="iconly-Profile icli"></i>
                  <i className="iconly-Profile icbo"></i>
                </div>
                <span>profile</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default Profile;
