import React from "react";
import { Link } from "react-router-dom";

export default class Return extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      Reasons: [],
      other: "none",
      reason_other: "",
    };
    this.commonChange = this.commonChange.bind(this);
  }
  commonChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value === "other") {
      this.setState({
        other: "block",
      });
    } else {
      this.setState({
        other: "none",
      });
    }
  }
  validate() {
    let reason = this.state.reason;
    let reason_other = this.state.reason_other;
    let errors = {};
    let isValid = true;

    if (!reason) {
      isValid = false;
      errors["reason"] = "Reason is Required.";
    }

    if (reason === "other") {
      if (reason_other === "") 
      isValid = false;
      errors["reason_other"] = "Reason is Required";
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  hendleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  componentDidMount() {
    const { state } = this.props.location;
    var value = "categoryid=" + encodeURIComponent(state.cid);
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: value,
    };
    fetch(
      "https://api.emechmart.com/api/Order/BindReasonsForOrderCancel",
      headers
    )
      .then((resp) => resp.json())
      .then((res) => {
        this.setState({
          Reasons: res.Data,
        });
      });
  }
  handleSubmit() {
    const { state } = this.props.location;

    if (this.validate()) {
      let input = {
        id: state.id,
        enc_id: state.enc_id,
        reason: this.state.reason,
        reason_other: this.state.reason_other,
      };
      this.props.history.push({ pathname: "/ReFund", state: input });
    }
  }

  render() {
    const { state } = this.props.location;
    const { Reasons, errors, other } = this.state;
    return (
      <div>
        <header>
          <div className="back-links">
            <Link to={{pathname:"/Track",state:state}}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Return Request</h2>
              </div>
            </Link>
          </div>
        </header>
        <div className="tab-content top-space px-15 pt-2">
          <div className="filter-box">
            <h2 className="billing">Reason for return * &nbsp;</h2>
            <div className="filter-content">
              <select
                className="form-select form-control form-theme"
                aria-label="Default select example"
                name="reason"
                onChange={this.commonChange}
                defaultValue=""
              >
                <option disabled value="">
                  Reason Behind Returning the Order
                </option>
                {/* <option value="india">india</option> */}
                {Reasons.map((data) => (
                  <option value={data.Value}>{data.Text}</option>
                ))}
                <option value="other">Other</option>
              </select>
              <div className="text-danger">{errors.reason}</div>
            </div>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "15px", display: other }}
          >
            <textarea
              className="form-control"
              id="one"
              name="reason_other"
              placeholder="Email"
              onChange={this.hendleChange}
              value={this.state.reason_other}
              required={true}
              style={{ height: "60px" }}
            />
            <label htmlFor="one">Your Reason</label>
            <div className="text-danger">{errors.reason_other}</div>
          </div>
          <button
            className="btn btn-solid w-100 "
            style={{marginTop:"1.5rem"}}
            onClick={(e) => this.handleSubmit()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}
