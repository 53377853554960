import $ from "jquery";
import {toast } from "react-toastify";
//import imageToBase64 from 'image-to-base64/browser';
import 'react-toastify/dist/ReactToastify.css';



export function Discount(OriginalPrice , Saleprice){
  
 var Dis= (OriginalPrice - Saleprice)/OriginalPrice*100
  var Disc=Math.round(Dis)
  return Disc;
}
export function Userlogin(){
   if(localStorage.getItem("U_Id")===null){
       return true;
   }else{
       return false;
   }
    
}
export function  Price(gst,price) {
   
  var total= ((gst*price)/100)
  var tag=parseInt(price);
  var tax=parseInt(total);
   var coast= tag + tax
  
  
  return coast;
  
}
export function whishlist(id) {
 
    if(Userlogin()===true){ 
     
        toast.info("Please login to use wishlist",{autocomplete:1000})
    }
    else{
       
      var param="productid="+encodeURIComponent(id)+"&quantity=0"

       const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      'Authorization':'Bearer '+localStorage.getItem("U_Id")},
      body:param
      
    }; 
      fetch("https://api.emechmart.com/api/Wishlist/AddtoWishlist",requestOptions)
      .then((res)=>res.json())
      .then((res)=>{
          console.log(res);
          let Success=res.Success
          if(Success===true){
          toast.dismiss();
           // toast.success("Added to wishlist!",{autoClose:2000})
          }
          else{
          toast.dismiss();
          //  toast.error("Something went wrong!",{autoClose:2000})
          }
      });
        
    }
    
}

export function Deletewhishlist(id){
    var key="productid="+encodeURIComponent(id)
   
     const headers={
             method:'POST',
             mode:"cors",
             headers:{'content-type':'application/x-www-form-urlencoded',
             'authorization':'Bearer '+localStorage.getItem("U_Id")},
             body:key
         };
    fetch("https://api.emechmart.com/api/Wishlist/DeleteItem", headers)
    .then((resp)=>resp.json())
    .then((res)=>{
        console.log(res);
        let success=res.Success;
        if(success===true){
          toast.dismiss();
         // toast.success("Remove Successsfully!",{autoClose:2000})
    //       const wishlistData = this.state.Data;
    //  this.setState({
    //      Data: wishlistData.filter(d => d.productid !== id),
    //    });
         // window.location.reload(false);
        }else{
          toast.dismiss();
          //  toast.error("Fail to remove!",{autoClose:2000})
        }

    })
    
}
  

export function Navbar(){


    $(".header-sidebar").addClass("show");
    $(".overlay-sidebar").addClass("show");
    $('body').css({
      'overflow': 'hidden'
    });
   $(".nav-bar").on('click', function () {
  
  
  });
  $(".overlay-sidebar").on('click', function () {
    $(".header-sidebar").removeClass("show");
    $(".overlay-sidebar").removeClass("show");
    $('body').css({
      'overflow': 'auto'
    });
  });
 
}
  
  
// export function GetVariations(name,value,id) {
//   if(name===undefined){
//     toast.error("Please Select the Color");
//   }
//   else{
//   alert(id);
//   var abc=document.getElementsByName('checkedradio');
//   for (let i of abc) {

// if (i.checked) {
//     console.log(i.value);
//   }
  
// }
// var color = document.querySelector('input[name="checkedradio"]:checked').value;

// var size=$("#floatingSelect").val();


// var APiPar=name+","+"Size"+",="+color+","+size+",";
// alert(APiPar);

// return APiPar;
// }

// };
 
  
