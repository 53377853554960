import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


export default class Bulk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        input:{},
        errors:{},
        inspect:"false"
    };
    this.commonChange = this.commonChange.bind(this);
    this.handlecheckbox = this.handlecheckbox.bind(this);

  }
   commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }
   handlecheckbox(e) {
    const target = e.target;
    if (target.checked) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
    }
  }
validate(){

      let input = this.state.input;
      let errors = {};
      let isValid = true;
           
     if (!input["qty"]) {
      isValid = false;
      errors["qty"] = "Quantity  is required";
     }

      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Please enter your name.";
      }
      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }
      if (typeof input["email"] !== "undefined") {
            var regx = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!regx.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
       if (!input["phone"]) {
        isValid = false;
        errors["phone"] = "Please enter your mobile number.";
      }
       if (typeof input["phone"] !== "undefined") {
              var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(input["phone"])) {
          isValid = false;
          errors["phone"] = "Please enter only number.";
        }else if(input["phone"].length !== 10){
          isValid = false;
          errors["phone"] = "Please enter valid phone number.";
        }
      }
       if (!input["pincode"]) {
      isValid = false;
      errors["pincode"] = "Pincode is required ";
    }
       if (typeof input["pincode"] !== "undefined") {
      if (input["pincode"].length !== 6) {
        isValid = false;
        errors["pincode"] = "Invalid Pincode.";
      } else if (!pattern.test(input["pincode"])) {
        isValid = false;
        errors["pincode"] = "Please enter only number.";
      }
    }
      if (!input["town"]) {
      isValid = false;
      errors["town"] = "Town/City is required";
    }
     
      
      this.setState({
        errors: errors
      }); 
      return isValid;
  }
  handlesubmit() {
      const{state}=this.props.location;
    if (this.validate()) {
        let input = this.state.input;

         var bdata={
             productid:state,
             email:input.email,
             fullname:input.name,
             mobilenumber:input.phone,
             pincode:input.pincode,
             city:input.town,
             description:input.Desc,
             qty:input.qty,
             inspect_required :this.state.inspect
         }
         console.log(bdata);
          var formBody = [];
      for (var property in bdata) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(bdata[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch(
        "https://api.emechmart.com/api/Product/BulkPurchase", requestOptions )
        .then((resp) => resp.json())
        .then((res)=>{
            console.log(res);
            toast.dismiss();
            toast.success(res.Message,{autoClose:4000})
            this.props.history.push("/")

        })
    }
  }
    
  render() {
      const{state}=this.props.location;

    return (
      <div>
        <header>
          <div className="back-links">
            <Link to={{pathname:"/Product",state:state}}>
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Bulk purchase form </h2>
              </div>
            </Link>
          </div>
        </header>

        <section class="top-space pt-2">
          <div class="address-form-section px-15">
            <div class="form-floating mb-4">
              <input
                type="number"
                class="form-control"
                id="two"
                name="qty"
                value={this.state.input.qty}
                onChange={this.commonChange}
                placeholder="Number of Qty."
              />
              <label for="two">Enter number of Qty. required</label>
              <div className="text-danger">
                {this.state.errors.qty}
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                name="name"
                placeholder="Full Name"
                value={this.state.input.name}
                onChange={this.commonChange}
              />
              <label htmlFor="one">Full Name</label>
              <div className="text-danger">
                {this.state.errors.name}
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="four"
                name="email"
                placeholder="email"
                //defaultValue={state.binfo.houseno}
                onChange={this.commonChange}
                //value={this.state.input.email}
              />
              <label htmlFor="four">email</label>
              <div className="text-danger">
                {this.state.errors.email}
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="two"
                name="phone"
                placeholder="Mobile Number"
                onChange={this.commonChange}
                //  value={this.state.input.phone}
              />
              <label htmlFor="two">Mobile Number</label>
              <div className="text-danger">
                {this.state.errors.phone}
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="number"
                className="form-control"
                id="three"
                name="pincode"
                placeholder="Pin Code"
                // defaultValue={state.binfo.pincode}
                onChange={this.commonChange}
               value={this.state.input.pincode}
              />
              <label htmlFor="three">Pin Code</label>
              <div className="text-danger">
                {this.state.errors.pincode}
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="seven"
                placeholder="town/city"
                name="town"
                onChange={this.commonChange}
               value={this.state.input.town}
              />
              <label htmlFor="seven">town/city</label>
              <div className="text-danger">{this.state.errors.town}</div>
            </div>
            <div className="form-floating mb-4">
              <textarea
                type="text"
                className="form-control"
                id="eight"
                placeholder="Description"
                name="Desc"
                defaultValue=""
                onChange={this.commonChange}
                style={{ padding: "11px", height: "90px" }}
                value={this.state.input.Desc}
              />
              <label htmlFor="seven">Description</label>
              <div className="text-danger">{this.state.errors.Desc}</div>
            </div>
          </div>
          <div class="type-address px-15">
            <div class="checkbox_animated">
              <div class="d-flex align-items-center mb-2">
                <input type="checkbox" name="inspect" id="ten" value="true"             
                 onChange={this.handlecheckbox}
/>
                <label for="ten">
                  Inspection require before the bulk order shipment.{" "}
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="panel-space"></section>

        <div className="cart-bottom row m-0">
          <div>
            {/* <div className="left-content col-5">
              <a href="#" className="title-color">
                RESET
              </a>
            </div> */}
            <button
              type="button"
              class="w-100 btn btn-solid"
              onClick={(e) => this.handlesubmit()}
            >
              Send Query
            </button>
          </div>
        </div>
      </div>
    );
  }
}
