import React from "react";
import { Link } from "react-router-dom";
import Ebulk from "./ebulk";

export default class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulk: [],
    };
  }
  componentDidMount() {
    var requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("U_Id") },
    };

    fetch(
      "https://api.emechmart.com/api/Product/GetAllBulkPurchase",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        this.setState({
          bulk: res.Data,
        });
      });
  }
  remove(id) {
    var index = "randid=" + encodeURIComponent(id);
    console.log(index);
    // Simple POST request with a JSON body using fetch
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: index,
    };
    fetch("https://api.emechmart.com/api/Product/DeleteBulkPurchase", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        const bulkdata = this.state.bulk;
        this.setState({
          bulk: bulkdata.filter((d) => d.randid !== id),
        });
      });
  }

  render() {
    const { bulk } = this.state;

    const List = () => {
      if (bulk === null) {
        return <Ebulk />;
      } else {
        return (
          <section className="cart-section pt-0 top-space">
            {bulk.map((data, index) => (
              <div key={data.product_data.product_id}>
                <div className="cart-box px-155">
                  <Link
                    to={{
                      pathname: "/Product",
                      state: data.product_data.product_id,
                    }}
                    className="cart-img"
                  >
                    <img
                      src={data.product_data.product_img}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="cart-content">
                    <Link
                      to={{
                        pathname: "/Product",
                        state: data.product_data.product_id,
                      }}
                    >
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: data.product_data.product_name,
                        }}
                      ></h4>
                      <h4 className="content-color">
                        Qty&nbsp;:&nbsp;{data.qty}
                      </h4>
                      <h4 className="content-color">
                        Name&nbsp;:&nbsp;{data.fullname}&ensp;&ensp;
                      </h4>
                      <h4 className="content-color">
                        City &nbsp;:&nbsp;{data.city}
                      </h4>
                      <h4 className="content-color">
                        Phone No.&nbsp;:&nbsp;{data.mobilenumber}
                      </h4>
                      {/* <h4 className="content-color"></h4>
                        <h4 className="content-color"></h4> */}
                    </Link>
                    <div className="price">
                      <h4>
                        Status:
                        <span>{data.status}</span>
                      </h4>
                    </div>

                    <div className="select-size-sec"></div>
                    <div className="cart-option">
                      <h4
                        data-bs-toggle="offcanvas"
                        onClick={(e) => this.remove(data.randid)}
                      >
                        <i className="iconly-Delete icli"></i>
                        Remove
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
            ))}
          </section>
        );
      }
    };
    return (
      <div>
        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Bulk Purchase</h2>
              </div>
            </a>
          </div>
        </header>
        {List()}
      </div>
    );
  }
}
