import React,{Component} from 'react';
import Cookies from 'universal-cookie';
import { Userlogin } from "./Helper";
// import "./assets/css/style.css";

const cookies=new Cookies();

var oldset
// alert(ind);
export default class Iconmenu extends Component{
    constructor(props){
        super(props)
        this.state={
          count:0
        }
    }
componentDidMount(){
   
    if(Userlogin()===true){
  //  console.log(cookies.get("local"));
  oldset=cookies.get("local")|| [];
 if(cookies.get("local")!==undefined && this.props.dataFromParent===undefined)
this.setState({
    count:oldset.length
})
}

// else if(cookies.get("local")!==undefined && this.props.dataFromParent!==undefined ){
// this.setState({
//     count:this.props.dataFromParent
// })
// }
else{
     const header = {
        method: "POST",
        mode: "cors",
        headers: { Authorization: "Bearer " + localStorage.getItem("U_Id") },
      };
      fetch("https://api.emechmart.com/api/Cart/ShowCartData", header)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          
          this.setState({
            count: res.Data.length,
          });
        });
    }
    
}
//  componentDidUpdate(){
   
//   if(ind===0)
//   {
// //    alert(ind+"ind");
// // if(cookies.get("local")!==undefined && this.props.dataFromParent!==undefined){
// //    alert("first");
// //     ind=1;
// //     this.setState({
// //         count:this.props.dataFromParent
// //     })
// //     }
//   //    else
//   // {
//   //     alert("second");
//   //      ind=1;
//   //   this.setState({
//   //       count:oldset.length
//   //   })
//   // }
//   }
 


//     var size=cookies.get("local")||[];
//     var length=size.length;
//     console.log(length);

//   }

    render(){

        const {count}=this.state

  
      const check=()=>{
      if(this.props.dataFromParent!==undefined )
        {
          if(this.props.dataFromParent===0){
        return(
          <span></span>
        )
      }
           else{
            return(
            <span className="cart_qty_cls" id="cartrecord">{this.props.dataFromParent}</span>
            )}
        }
        else{

          if(this.state.count===0){
        return(
          <span></span>
        )
      }
      else{
          //  alert(count+"count");
             return(
            <span className="cart_qty_cls" id="cartrecord">{count}</span>
              )}
        }
    }
        return(

                  <>
            {/* <span className="cart_qty_cls" id="cartrecord">{count}</span> */}
            {check()}
              
                <a href="/Kart">
                  <i className="iconly-Buy icli"></i>
                </a>
            </>
        );
    }
}