import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";


class Reset_email extends Component{
    constructor(props){
        super(props);
        this.state={
        input:{},
        errors:{},
        status:""
        }
        this.commonChange= this.commonChange.bind(this);
    }

 commonChange(e) {
let input = this.state.input;
    input[e.target.name] = e.target.value;
      this.setState({
      input
    }); 
     
  }
 reset() {
  
   let input = {};
       input["current_email"] = "";
        input["new_email"] = "";
          this.setState((currentState) => ({
             input:input
           }),
          )}
   validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!input["current_email"]) {
        isValid = false;
        errors["current_email"] = "Current Email Address is required";
      }
      if( !input["new_email"]){
       isValid = false;
        errors["new_email"] = "New EmailAddress is required";     
      }
      if (typeof input["current_email"] !== "undefined" ) {
        if (!pattern.test(input["current_email"])) {
          isValid = false;
          errors["current_email"] = "Enter valid Email Address.";
        }
      }
      if (typeof input["new_email"] !== "undefined" ) {
        if (!pattern.test(input["new_email"])) {
          isValid = false;
          errors["new_email"] = "Enter valid Email Address.";

        }
      }
      this.setState({
        errors: errors
      }); 
      return isValid;

   }

resetemail(e){
     if(this.validate()){
        
        
       var data={
               Old_Email_id:this.state.input.current_email,
               New_Email_id:this.state.input.new_email,     
       }
     }
      var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch(
      "https://api.emechmart.com/api/Useraccount/ChangeEmailid",requestOptions)
      .then((resp)=>resp.json())
      .then((res)=>{
          console.log(res);
          if(res.Success===true){
              toast.dismiss()
          toast.success(res.Message,{autoClose:4000})
         
          this.setState({
              status:"Please check your email & verify your email address"
          })
          localStorage.removeItem("U_Id");
           this.props.history.push("/Login")
          }
           else{
           toast.dismiss()
          toast.error(res.error,{aotoClose:3000})
          this.reset()
           }
      })

}


    render(){
        return(
            <>
<ToastContainer Colored theme='colored' closeButton={false} autoClose={4000} />

        {/* <!-- top design start --> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />
        {/* <!-- top design end --> */}

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Change EmailAddress</h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="one"
                placeholder="Current Email"
                name="current_email"
                value={this.state.input.current_email}
                onChange={this.commonChange}
              />
              <label htmlFor="one">Current EmailAddress *</label>
              <div className="text-danger">{this.state.errors.current_email}</div>

            </div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="two"
                placeholder="New Email"
                name="new_email"
               value={this.state.input.new_email}
                onChange={this.commonChange}
              />
              <label htmlFor="two">New EmailAddress *</label>
              <div className="text-danger">{this.state.errors.new_email}</div>

            </div>
            <button className="btn btn-solid w-100" onClick={(e)=>this.resetemail()}>
              CHANGE EMAILADDRESS
            </button>
            <h4 className="status">{this.state.status}</h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
                
            </>
        )
    }
}export default Reset_email