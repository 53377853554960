/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { QuantityPicker } from "react-qty-picker";
import Cookies from "universal-cookie";
//import Iconmenu from "./iconmenu.js"
import Loader from "react-js-loader";
import "react-toastify/dist/ReactToastify.css";
import { Userlogin, whishlist } from "./Helper";
import Ekart from "./empty-cart";

var templocal = [];
const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);

class Kart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      local: [],
      loading: true,
      total_price: "",
      show: false,
      validator: "",
      promocode: "",
      items: [],
      notice: "",
      tax: 0,
      discountprice: 0,
      dprice: 0,
      subtotal: 0,
      status: "Apply",
    };
    this.commonChange = this.commonChange.bind(this);
    this.ToggleClick = this.ToggleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  ToggleClick = () => {
    this.setState({
      show: true,
    });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    
  }

  

  commonChange(e, i, price) {
    const { local } = this.state;
    if (e < local[i].AVL_Stock) {
      local[i].Cart_Price = price * e;

      this.setState({
        local: local,
      });
      console.log(local[i].dprice);
      if (e > local[i].product_qty) {
        this.setState((prevState) => ({
          tax: prevState.tax + parseFloat(local[i].tax),
          subtotal: prevState.subtotal + parseInt(local[i].mrpprice),
          discountprice: prevState.discountprice + parseInt(local[i].dprice),
        }));
      } else {
        this.setState((prevState) => ({
          tax: prevState.tax - parseFloat(local[i].tax),
          subtotal: prevState.subtotal - parseInt(local[i].mrpprice),
          discountprice: prevState.discountprice - parseInt(local[i].dprice),
        }));
      }

      if (Userlogin() === true) {
        const cookies = new Cookies();

        //console.log(cookies.get("local"));
        const oldset = cookies.get("local") || [];

        //  oldset.splice(i,1)

        oldset[i].product_qty = e;
        oldset[i].Cart_Price = local[i].var_price * e;
        //  oldset.push(qty)
        cookies.set("local", JSON.stringify(oldset));
        // console.log(cookies.get("local"));
        this.setState({
          local: cookies.get("local"),
        });
      } else {
        var param = {
          var_id: local[i].var_id,
          product_id: local[i].product_id,
          product_qty: e,
          product_price: local[i].Cart_Price,
        };
        // console.log(param);
        var formBody = [];
        for (var property in param) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(param[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.getItem("U_Id"),
          },
          body: formBody,
        };
        fetch("https://api.emechmart.com/api/Cart/UpdateItem", requestOptions)
          .then((resp) => resp.json())
          .then((res) => {
            console.log(res);
            const { local } = this.state;
            local[i].product_qty = res.Data;
            console.log(local[i].Cart_Price);
          //   var newtemplocal = [];
          //   newtemplocal = templocal.map(function (currentValue, index, arr) {
          //     if (
          //       currentValue.product_id === local[i].product_id &&
          //       currentValue.var_id === local[i].var_id
          //     ) {
          //       currentValue.Cart_Price = local[i].Cart_Price;
              
          //     }
          //     return currentValue;
          //   });
          // templocal =newtemplocal.map((obj) => ({ ...obj }));
            
         console.log(templocal);
            this.setState((currentState) => ({
              local: local,
              
            }));
          });
        // console.log(e);
        // if (local[i].product_discount !== "0") {
        // this.setState({
        //   discountprice: this.state.dprice * e,
        // });
      }
    } else {
      toast.error("Out of Stock", { autoClose: 2000 });
    }
  }

  checkcode(e) {
    var promocode;

    if (Userlogin() === true) {
      toast.dismiss();
      toast.info("Login to Use Promocode", { autoClose: 2000 });
    } else if (this.state.promocode === "") {
    } else {
      if (this.state.status === "Cancel") {
        promocode = "";
      } else {
        promocode = this.state.promocode;
      }

      var data = "promocodename=" + encodeURIComponent(promocode);
          console.log(data);
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: data,
      };
      fetch(
        "https://api.emechmart.com/api/Order/GetProductsForCheckout",
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          this.ToggleClick();
          this.setState((currentState) => ({
            validator: resp.Data.promocode_applied,
            items: resp.Data.OrderedProducts,
          }));
          if (resp.Data.promocode_applied === 1) {
            localStorage.setItem("promocode", this.state.promocode);
            this.setState((currentState) => ({
              status: "Cancel",
            }));
          }

          let tax = 0;
          let disc = 0;

          if (this.state.validator === 1) {
            const { items } = this.state;
            for (var i = 0; i < items.length; i++) {
              const { local } = this.state;

              tax +=
                parseFloat(items[i].totaltax) *
                parseInt(items[i].productquantity);
              disc +=
                parseInt(items[i].discountprice) *
                parseInt(items[i].productquantity);

              this.setState({
                tax: tax,
                discountprice: disc,
              });

              var newlocal = [];
              if (items[i].promocode_applicable === "1") {
                this.setState({
                  dprice: parseInt(items[i].discountprice),
                  // discountprice:
                  //   parseInt(items[i].discountprice) *
                  //   parseInt(items[i].productquantity),
                });

                newlocal = local.map(function (currentValue, index, arr) {
                  if (currentValue.product_id === items[i].productrandid) {
                    currentValue.Cart_Price =
                      parseInt(items[i].finalprice) *
                      parseInt(items[i].productquantity);
                    currentValue.product_discount = items[i].promo_discount;
                    currentValue.var_price = items[i].finalprice;
                    currentValue.tax = items[i].totaltax;
                    currentValue.dprice = items[i].discountprice;

                    //  templocal[index].Cart_Price=Tempdataprice;
                    // currentValue.tax=items[i].totaltax
                  }
                  return currentValue;
                });

                this.setState({
                  local: newlocal,
                });
              }
            }
          } else {
            // console.log(templocal);
            // var copylocal = [];
            // copylocal = templocal.map((obj) => ({ ...obj }));

            if (this.state.status === "Cancel") {
              localStorage.removeItem("promocode");
              const { items } = this.state;
              var tax1 = 0;
              var disc1 = 0;
              let sub = 0;
              for (var o = 0; o < items.length; o++) {
                const { local } = this.state;
                local[o].product_discount = "0";
                local[o].Cart_Price=parseInt(items[o].finalprice)*parseInt(items[o].productquantity)
                local[o].tax=items[o].totaltax
                local[o].var_price=items[o].finalprice
                 console.log(items[o].discountprice);
                tax1 +=
                  parseFloat(items[o].totaltax) *
                  parseInt(items[o].productquantity);
                disc1 +=
                  parseInt(items[o].discountprice) *
                  parseInt(items[o].productquantity);

                sub +=
                  parseInt(items[o].vmrp) * parseInt(items[o].productquantity);
                this.setState({
                  dprice: parseInt(items[o].discountprice),
                  discountprice: disc1,
                  tax: tax1,
                  local: local,
                  promocode: "",
                  subtotal: sub,
                });
              }

              this.setState({
                status: "Apply",
              });
            }
          }
        });
    }
  }
  async gettax() {
    const cookies = new Cookies();
    const pdata = cookies.get("local");
    console.log(pdata);
    await this.setState((currentState) => ({
      local: pdata,
    }));
  }
  componentDidMount() {
    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2300);

    if (Userlogin() === true) {
      this.gettax().then((res) => {
        for (var i = 0; i < this.state.local.length; i++) {
          const { local } = this.state;

          this.setState({
            tax: this.state.tax + local[i].tax * local[i].product_qty,
            subtotal:
              this.state.subtotal +
              parseInt(local[i].mrpprice) * local[i].product_qty,
            discountprice:
              this.state.discountprice + local[i].dprice * local[i].product_qty,
          });
        }
      });
    } else {
      const header = {
        method: "POST",
        mode: "cors",
        headers: { Authorization: "Bearer " + localStorage.getItem("U_Id") },
      };
      fetch("https://api.emechmart.com/api/Cart/ShowCartData", header)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);

          templocal = res.Data.map((obj) => ({ ...obj }));
          this.setState((currentState) => ({
            local: res.Data,
            notice: res.extra_data,
          }));
          if (res.extra_data !== "") {
            toast.dismiss();
            toast.error(
              "These items are removed from cart due to change in price or stocks :" +
                res.extra_data,
              { autoclose: 5000 }
            );
          }

          for (var i = 0; i < this.state.local.length; i++) {
            const { local } = this.state;

            // eslint-disable-next-line no-loop-func
            this.setState((prevState) => ({
              tax:
                prevState.tax +
                parseFloat(local[i].tax) * parseInt(local[i].product_qty),
              subtotal:
                prevState.subtotal +
                parseInt(local[i].mrpprice) * parseInt(local[i].product_qty),
              discountprice:
                prevState.discountprice +
                parseInt(local[i].dprice) * parseInt(local[i].product_qty),
            }));
          }
        });
    }
  }
  onplaceorder() {
    if (Userlogin() === true) {
      this.props.history.push("/Login");
    } else {
      this.props.history.push("/Delivery");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.isLoading);
  }
  deleteitem(productid, var_id, i) {
    if (Userlogin() === true) {
      const cookies = new Cookies();
      const oldset = cookies.get("local") || [];
      oldset.splice(i, 1);
      cookies.set("local", JSON.stringify(oldset));
      // window.location.reload(false);
      const cartData = this.state.local;

      var newtax = this.state.tax - cartData[i].tax * cartData[i].product_qty;
      var newsubtotal =
        this.state.subtotal -
        parseInt(cartData[i].mrpprice) * cartData[i].product_qty;
      var newdisc =
        this.state.discountprice -
        parseInt(cartData[i].dprice) * cartData[i].product_qty;
      this.setState({
        local: cartData.filter((d) => d.product_id !== productid),
        tax: newtax,
        subtotal: newsubtotal,
        discountprice: newdisc,
      });
    } else {
      var details = {
        product_id: productid,
        var_id: var_id,
      };

      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Simple POST request with a JSON body using fetch
      const headers = {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch("https://api.emechmart.com/api/Cart/DeleteItem", headers)
        .then((resp) => resp.json())
        .then((res) => {
          let success = res.Success;
          if (success === true) {
            const cartData = this.state.local;

            var newtax =
              this.state.tax - cartData[i].tax * cartData[i].product_qty;

            if (cartData[i].product_discount !== "0") {
              localStorage.removeItem("promocode");
              this.setState({
                status: "Apply",
                promocode: "",
                validator: 0,
              });
            }
            var newsubtotal =
              this.state.subtotal -
              parseInt(cartData[i].mrpprice) * cartData[i].product_qty;

            var newdisc =
              this.state.discountprice -
              parseInt(cartData[i].dprice) * cartData[i].product_qty;
            this.setState({
              local: cartData.filter((d) => d.product_id !== productid),
              tax: newtax,
              subtotal: newsubtotal,
              discountprice: newdisc,
            });
          } else {
            toast.error("Something went Wrong", { autoClose: 2000 });
          }
        });
    }
  }

  render() {
    const {
      local,
      loading,
      show,
      validator,
      discountprice,
      tax,
      subtotal,
      status,
    } = this.state;
    const codesheet = () => {
      if (validator === 0) {
        return (
          <div className="service-section">
            <ul>
              <li className="img-fluid" style={{ color: "Red" }}>
                Promocode invalid!!!
              </li>
            </ul>
          </div>
        );
      } else if(validator === 1){
        return (
          <div className="service-section">
            <ul>
              <li className="img-fluid" style={{ color: "green" }}>
                Promocode applied!!
              </li>
            </ul>
          </div>
        );
      }
      else{
        return null;
      }
    };
    const discount = (i) => {
      if (local[i].product_discount === "0") {
        return (
          <h4>
            {"\u20B9"}
            {local[i].var_price}
            <del>
              {"\u20B9"}
              {local[i].mrpprice}
            </del>
            &ensp;&ensp;Total:{"\u20B9"}
            {local[i].Cart_Price}
            <span></span>
          </h4>
        );
      } else {
        return (
          <h4>
            {"\u20B9"}
            {local[i].var_price}
            <del>
              {"\u20B9"}
              {local[i].mrpprice}
            </del>
            &ensp;&ensp;
            <span>
              NewTotal:{"\u20B9"}
              {local[i].Cart_Price}{" "}
            </span>
          </h4>
        );
      }
    };

    //  const Stock=()=>{
    //    if(local[i].product_qty>local[i].)
    //  }

    const ekart = () => {
      if (local === undefined || local.length === 0) {
        return <Ekart />;
      } else {
        const result = local.reduce(
          (total, currentValue) =>
            (total = total + parseInt(currentValue.Cart_Price)),
          0
        );
        const total = result + tax;
        return (
          <div>
            <ToastContainer
              hideProgressBar
              Colored
              theme="colored"
              closeButton={false}
            />
            <section className="cart-section pt-0 top-space">
              {local.map((data, index) => (
                <div
                  id={data.product_id + "_" + data.var_id}
                  key={data.product_id}
                >
                  <div className="cart-box px-155">
                    <Link
                      to={{ pathname: "/Product", state: data.product_id }}
                      className="cart-img"
                    >
                      <img
                        src={data.product_image}
                        className="img-fluid"
                        alt=""
                      />
                    </Link>
                    <div className="cart-content">
                      <Link
                        to={{ pathname: "/Product", state: data.product_id }}
                      >
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: data.product_name,
                          }}
                        ></h4>
                        <h4 className="content-color">{data.variation}</h4>
                      </Link>
                      {/* <h5 className="content-color"></h5> */}
                      <div className="price">
                        {/* <h4>
                          {"\u20B9"}
                          {data.var_price}&ensp;&ensp;<del>{"\u20B9"}{data.mrpprice}</del>
                          <br/>
                            <span></span>
                         </h4> */}

                        {discount(index)}
                      </div>
                      <div className="select-size-sec">
                        <QuantityPicker
                          height="35px"
                          max={data.maximum}
                          min={data.minimum}
                          value={data.product_qty}
                          onChange={(value) =>
                            this.commonChange(value, index, data.var_price)
                          }
                        />
                      </div>

                      {/* <div > */}
                      {/* <a
                        href="javascript:void(0)"
                         data-bs-toggle="offcanvas"
                         //data-bs-target="#selectQty"
                        className="opion"
                         > */}
                      {/* <h4>Qty: </h4>
                        <input
                          type="number"
                          className="form-control form-theme"
                          min={data.minimum}
                          max={data.maximum}
                          style={{ width: "40% " }}
                          defaultValue="1"
                           onChange={(e)=>this.commonChange(e,index,data.var_price)}
                        />  */}
                      {/* <i className="iconly-Arrow-Down-2 icli"></i> */}
                      {/* </a>
                      {/* </div> */}
                      <div className="cart-option">
                        <h5
                          data-bs-toggle="offcanvas"
                          onClick={(e) => whishlist(data.product_id)}
                        >
                          <i className="iconly-Heart icli"></i> Move to wishlist
                        </h5>
                        <span className="divider-cls">|</span>
                        <h5
                          data-bs-toggle="offcanvas"
                          onClick={(e) =>
                            this.deleteitem(data.product_id, data.var_id, index)
                          }
                        >
                          <i className="iconly-Delete icli"></i>
                          Remove
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                </div>
              ))}

              {/* <div className="cart-box px-15">
            <a href="/Product" className="cart-img">
              <img
                src="assets/images/products/2.jpg"
                className="img-fluid"
                alt=""
              />
            </a>
            <div className="cart-content">
              <a href="/Product">
                <h4>Pink Hoodie t-shirt full </h4>
              </a>
              <h5 className="content-color">by Mango</h5>
              <div className="price">
                <h4>
                  $32.00 <del>$35.00</del>
                  <span>20%</span>
                </h4>
              </div>
              <div className="select-size-sec">
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#selectQty"
                  className="opion"
                >
                  <h6>Qty: 1</h6>
                  <i className="iconly-Arrow-Down-2 icli"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#selectSize"
                  className="opion"
                >
                  <h6>Size: S</h6>
                  <i className="iconly-Arrow-Down-2 icli"></i>
                </a>
              </div>
              <div className="cart-option">
                <h5 data-bs-toggle="offcanvas" data-bs-target="#removecart">
                  <i className="iconly-Heart icli"></i> Move to wishlist
                </h5>
                <span className="divider-cls">|</span>
                <h5 data-bs-toggle="offcanvas" data-bs-target="#removecart">
                  <i className="iconly-Delete icli"></i>
                  Remove
                </h5>
              </div>
            </div>
          </div>   */}
            </section>
            {/* <!-- cart items end --> */}

            {/* <div className="divider"></div> */}

            {/* <!-- coupon section --> */}
            <section className="px-15 pt-0">
              <h2 className="page-title" style={{ marginLeft: "10px" }}>
                Apply Promocode
              </h2>
              <div className=" check-delivery-section product-detail-box px-15">
                <div
                  className="pincode-form"
                  style={{ marginLeft: "-10px", marginRight: "-10px" }}
                >
                  <input
                    className="form-control form-theme"
                    placeholder=" Enter Promocode"
                    name="promocode"
                    onChange={this.handleChange}
                    value={this.state.promocode}
                  />
                  <a href={null} onClick={(e) => this.checkcode()}>
                    {status}
                  </a>
                </div>
                {show === true ? codesheet() : null}
              </div>
              {/* <a href="#" className="show-more">Show More (5 offers)</a> */}
            </section>
            {/* <section className="px-15 pt-0">
              <h2 className="title">Coupons:</h2>
              <div className="coupon-section">
                <i className="iconly-Discount icli icon-discount"></i>
                <input
                  className="form-control form-theme"
                  placeholder="Apply Coupons"
                />
                <i className="iconly-Arrow-Right-2 icli icon-right"></i>
              </div>
            </section>*/}
            <div className="divider"></div>
            {/* <!-- coupon end --> */}

            {/* <!-- order details start --> */}
            <section id="order-details" className="px-15 pt-0">
              <h2 className="title">Order Details:</h2>
              <div className="order-details">
                <ul>
                  <li>
                    <h4>
                      Sub Total{" "}
                      <span>
                        {"\u20B9"}
                        {subtotal}
                      </span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Discount{" "}
                      <span className="text-green">
                        -{"\u20B9"}
                        {discountprice}
                      </span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Bag total{" "}
                      <span>
                        {"\u20B9"}
                        {result}
                      </span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Tax{" "}
                      <span>
                        {"\u20B9"}
                        {tax.toFixed(0)}
                      </span>
                    </h4>
                  </li>
                </ul>
                <div className="total-amount">
                  <h4>
                    Total Amount{" "}
                    <span>
                      {"\u20B9"}
                      {total.toFixed(0)}
                    </span>
                  </h4>
                </div>
                {/* <div className="delivery-info">
                  <img
                    src="assets/images/truck.gif"
                    className="img-fluid"
                    alt=""
                  />
                  <h4>No Delivery Charges applied on this order </h4>
                </div> */}
              </div>
            </section>
            <div className="divider"></div>
            {/* <!-- order details end --> */}

            {/* 
    <!-- service section start --> */}
            <section className="service-wrapper px-15 pt-0">
              <div className="row">
                <div className="col-4">
                  <div className="service-wrap">
                    <div className="icon-box">
                      <img
                        src="assets/svg/returning.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <span>10 Day Return</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="service-wrap">
                    <div className="icon-box">
                      <img
                        src="assets/svg/24-hours.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <span>24/7 Support</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="service-wrap">
                    <div className="icon-box">
                      <img
                        src="assets/svg/wallet.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <span>Secure Payment</span>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- service section end --> */}

            <section className="panel-space"></section>

            <div className="cart-bottom">
              <div>
                <div className="left-content">
                  {/* <h4>$270.00</h4> */}
                  <a href="/">Continue Shopping</a>
                </div>
                <a
                  href
                  className="btn btn-solid"
                  onClick={(e) => this.onplaceorder()}
                >
                  Place Order
                </a>
              </div>
            </div>

            {/* <!-- select qty offcanvas start --> */}
            {/* <div
              className="offcanvas offcanvas-bottom h-auto qty-canvas"
              id="selectQty"
            >
              <div className="offcanvas-body small">
                <h4>Select Quanity:</h4>
                <div className="qty-counter">
                  <div className="input-group">
                    <button
                      type="button"
                      className="btn quantity-left-minus"
                      data-type="minus"
                      data-field=""
                    >
                      <img
                        src="assets/svg/minus-square.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                    <input
                      type="text"
                      name="quantity"
                      className="form-control form-theme qty-input input-number"
                      defaultValue="1"
                    />
                    <button
                      type="button"
                      className="btn quantity-right-plus"
                      data-type="plus"
                      data-field=""
                    >
                      <img
                        src="assets/svg/plus-square.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <a
                  href={null}
                  className="btn btn-solid w-100"
                  data-bs-dismiss="offcanvas"
                >
                  Add to Bag
                </a>
              </div>
            </div> */}
            {/* <!-- select qty offcanvas end --> */}

            {/* <!-- select size offcanvas start --> */}
            {/* <div
              className="offcanvas offcanvas-bottom h-auto qty-canvas"
              id="selectSize"
            >
              <div className="offcanvas-body small">
                <h4>Select Size:</h4>
                <div className="size-detail mb-2 mt-2">
                  <ul className="size-select">
                    <li>S</li>
                    <li>M</li>
                    <li>L</li>
                    <li className="disable">XL</li>
                  </ul>
                </div>
                <div className="price mb-3">
                  <h4>
                    $32.00 <del>$35.00</del>
                    <span>20%</span>
                  </h4>
                </div>
                <a
                  href="javascript:void(0)"
                  className="btn btn-solid w-100"
                  data-bs-dismiss="offcanvas"
                >
                  DONE
                </a>
              </div>
            </div> */}
            {/* <!-- select size offcanvas end --> */}

            {/* <!-- remove item canvas start --> */}
            {/* <div
              className="offcanvas offcanvas-bottom h-auto removecart-canvas"
              id="removecart"
            >
              <div className="offcanvas-body small">
                <div className="content">
                  <h4>Remove Item:</h4>
                  <p>
                    Are you sure you want to remove or move this item from the
                    cart?
                  </p>
                </div>
                <div className="bottom-cart-panel">
                  <div className="row">
                    <div className="col-7">
                      <a href="/Wishlist" className="title-color">
                        MOVE TO WISHLIST
                      </a>
                    </div>
                    <div className="col-5">
                      <a href="#" className="theme-color">
                        REMOVE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        );
      }
    };
    return loading ? (
      <Loading />
    ) : (
      <div className="parent">
        {/* <div className="loader">
          <span></span>
          <span></span>
        </div> */}
        <ToastContainer Colored theme="colored" closeButton={false} />
        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Shopping Cart</h2>
                <h6>Step 1 of 3</h6>
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/Wishlist">
                  <i className="iconly-Heart icli"></i>
                </a>
              </li>
            </ul>
          </div>
        </header>

        {/* <!-- cart items start --> */}
        {ekart()}
      </div>
    );
  }
}
export default Kart;
