import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Saved_address extends Component{

    render(){
let url="";
        return(
            <div>
 {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
    

    
    <header>
        <div className="back-links">
            <a href="/Profile">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>saved address</h2>
                </div>
            </a>
        </div>
    </header>
    


    {/* <!-- delivery option section start --> */}
    <section className="top-space px-15 pt-0">
        <div className="delivery-option-section">
            <ul>
                <li>
                    <div className="check-box">
                        <div className="form-check d-flex ps-0">
                            <input checked className="radio_animated" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                            <div>
                                <h4 className="name">Carolina S Seo</h4>
                                <div className="addess">
                                    <h4>3501 Maloy Court, </h4>
                                    <h4>East Elmhurst, New York City</h4>
                                    <h4>NY 11369</h4>
                                </div>
                                <h4>Phone No: 903-239-1284</h4>
                                <h6 className="label">HOME</h6>
                            </div>
                        </div>
                    </div>
                    <div className="buttons l-15">
                        <a href={url}>Remove</a>
                        <a href={url}>edit</a>
                    </div>
                </li>
                <li>
                    <div className="check-box">
                        <div className="form-check d-flex ps-0">
                            <input className=" radio_animated" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault2"></label>
                            <div>
                                <h4 className="name">Arthur M Willingham</h4>
                                <div className="addess">
                                    <h4>3059 Elk City Road</h4>
                                    <h4>Indianapolis, Indiana</h4>
                                    <h4>IN 46229</h4>
                                </div>
                                <h4>Phone No: 317-898-0622</h4>
                                <h6 className="label">OFFICE</h6>
                            </div>
                        </div>
                    </div>
                    <div className="buttons l-15">
                        <a href={url}>Remove</a>
                        <a href={url}>edit</a>
                    </div>
                </li>
            </ul>
            <a href="/New_address" className="btn btn-outline text-capitalize w-100 mt-3">add New address</a>
        </div>
    </section>
    {/* <!-- delivery option section end --> */}


    
    <section className="panel-space"></section>
    
                
            </div>
        );
    }

}export default Saved_address;