import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";


class Coupon extends Component {

  render() {
     let url="";
    return (
      <div className="master">
        {/* <div className="loader">
          <span></span>
          <span></span>
        </div> */}

        <header>
          <div className="back-links">
            <a href="/Shop">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Coupons</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- apply coupons section start --> */}
        <section className="px-15 top-space pt-0">
          <div className="search-coupons">
            <input
              className="form-control form-theme"
              placeholder="Apply Coupons"
            />
            <i className="iconly-Discount icli"></i>
          </div>
          <ul className="coupon-listing">
            <li>
              <div className="coupon-box">
                <div className="top-bar">
                  <h4>MULTIKART10</h4>
                  <span>Save $20.00</span>
                  <a href={url}>APPLY</a>
                </div>
                <p>
                  Use code MULTIKART10 to get flat 10% off on minimum order of
                  $200.00. Offer valid for first time users only
                </p>
                <a href="/TC" className="text-green">
                  View T&C
                </a>
              </div>
            </li>
            <li>
              <div className="coupon-box">
                <div className="top-bar">
                  <h4>WELCOME</h4>
                  <span>Save $22.00</span>
                  <a href={url}>APPLY</a>
                </div>
                <p>
                  Use code WELCOME to get flat 10% off on minimum order of
                  $200.00. Offer valid for first time users only
                </p>
                <a href="/TC" className="text-green">
                  View T&C
                </a>
              </div>
            </li>
            <li>
              <div className="coupon-box">
                <div className="top-bar">
                  <h4>LUCKY50</h4>
                  <span>Save $32.00</span>
                  <a href={url}>APPLY</a>
                </div>
                <p>
                  Use code LUCKY50 to get flat 10% off on minimum order of
                  $200.00. Offer valid for first time users only
                </p>
                <a href="/TC" className="text-green">
                  View T&C
                </a>
              </div>
            </li>
            <li>
              <div className="coupon-box">
                <div className="top-bar">
                  <h4>SUMMERSALE</h4>
                  <span>Save $15.00</span>
                  <a href={url}>APPLY</a>
                </div>
                <p>
                  Use code SUMMERSALE to get flat 10% off on minimum order of
                  $200.00. Offer valid for first time users only
                </p>
                <a href="/TC" className="text-green">
                  View T&C
                </a>
              </div>
            </li>
          </ul>
        </section>
        {/* <!-- apply coupons section end --> */}

        <section className="panel-space"></section>

        <div className="cart-bottom">
          <div>
            <div className="left-content">
              <h4>$270.00</h4>
              <a href="#order-details" className="theme-color">
                View details
              </a>
            </div>
            <a href="/Delivery" className="btn btn-solid">
              Place Order
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Coupon;
