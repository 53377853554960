import React, { Component } from "react";
import { Link } from 'react-router-dom';
//import Loader from "react-js-loader";
import Iconmenu from "./iconmenu.js"
//import Loader from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import "./assets/css/style.css";
// import "./assets/css/vendors/bootstrap.css";
// import "./assets/css/vendors/slick-theme.css";
// import "./assets/css/vendors/slick.css";
// import "./assets/css/vendors/iconly.css";

class Inner_category extends Component{
         constructor(props){
             super(props);
             this.state={
                 Data: [],
                 subcat: [],
                 groupname: "",
                 groupdesc: "",
                 groupimg: "",
                 groupurl:""
             };
             console.log(this.state);
         }

      componentDidMount(){
         const {state}=this.props.location
         console.log(state)
        var param="group_url=" + encodeURIComponent(state)
         var options={
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
      body:param
      };

      fetch("https://api.emechmart.com/api/CategroyAPI/GetSubgroupsAndCategories",options)
      .then((resp)=>resp.json())
      .then((resp)=>{
          console.log(resp)
          this.setState({
              Data:resp.Data,
              subcat:resp.Data.sub_cat_data,
              groupname:resp.Data.group.group_name,
              groupdesc: resp.Data.group.group_desc,
              groupimg:resp.Data.group.group_image,
              groupurl:resp.Data.group.group_url,

          });
      })
      



         }
        //  redirect(url,title){
        //      var data={
        //          url:url,
        //          title:title
        //      }
        //    this.props.history.push({pathname:"/Shop",state:data})  
             
        //  }
    render(){
     
      const{subcat, groupname,groupdesc,groupimg,groupurl}=this.state;
      console.log(this.state);
        return(
            <div>
{/* <div className="loader">
       <Loader type="bubble-ping" bgColor={"#007744"}  color={'#007744'} size={50} timeout={3000}/>
    </div> */}
      <header>
        <div className="back-links">
            <a href="/Cat">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                
                    <h2>Categories <span><i className="iconly-Arrow-Right-2 icli"></i>{groupname}</span></h2>
                
                </div>
            </a>
        </div>
        <div className="header-option">
            <ul>
                <li>
                    <a href="/Wishlist"><i className="iconly-Heart icli"></i></a>
                </li>
                <li>
                   <Iconmenu />
                </li>
            </ul>
        </div>
    </header>
    

    
    <section className="category-listing px-15 top-space pt-0 cate-padding">
        <Link to={{pathname:"/Shop",state:groupurl+"//"}} className="category-wrap">
            <div className="content-part">
                <h2>{groupname}</h2>
                <h4 dangerouslySetInnerHTML={{__html: groupdesc}}></h4>
            </div>
            <div className="img-part">
                <img src={groupimg} className="img-fluid" alt=""/>
            </div>
        </Link>
    </section>
    {/* <!-- category title end --> */}

{/* 
    <!-- category menu start --> */}
    <section className="px-15 category-menu">
        <div className="accordion px-15">
        {subcat.map((Data)=>(
            
            <div className="accordion-item" key={(Data.index)}>
                <h2 className="accordion-header" >
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target={"#"+Data.subgroup_name.replaceAll(" ","-").replaceAll("&","-")}>
                        {Data.subgroup_name}
                    </button>
                </h2>
                   
                <div id={Data.subgroup_name.replaceAll(" ","-").replaceAll("&","-")} className="accordion-collapse collapse">
                    <div className="accordion-body">
                        <ul> { Data.category_data.map((Data1)=>(
                            <li><Link to={{pathname:"/Shop", state:Data1.category_url}}>{Data1.category_name}</Link></li>

                            ))} 
                        </ul>
                    </div>
                </div>
               
            </div>
            ))}
           
        </div>
    </section>
    {/* <!-- category menu end --> */}


    {/* <!-- inner category start --> */}
    {/* <section className="inner-category px-15">
        <div className="row gx-3">
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/flowerprint.png" className="img-fluid" alt=""/>
                    <h4>Flowerprint</h4>
                </a>
            </div>
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/denim.png" className="img-fluid" alt=""/>
                    <h4>Denim</h4>
                </a>
            </div>
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/skirts.png" className="img-fluid" alt=""/>
                    <h4>Skirts</h4>
                </a>
            </div>
        </div>
    </section> */}
    {/* <!-- inner category end --> */}


    {/* <!-- brand section start --> */}
    {/* <section className="brand-section px-15">
        <h2 className="title">Biggest Deals on Top Brands</h2>
        <div className="row g-3">
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/1.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/2.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/3.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/4.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/5.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="#">
                    <img src="assets/images/brand-logos/6.png" className="img-fluid" alt=""/>
                </a>
            </div>
        </div>
    </section> */}
    {/* <!-- brand section end --> */}


    
    <section className="panel-space"></section>
    


    <div className="bottom-panel">
        <ul>
            <li>
                <a href="/">
                    <div className="icon">
                        <i className="iconly-Home icli"></i>
                        <i className="iconly-Home icbo"></i>
                    </div>
                    <span>home</span>
                </a>
            </li>
            <li className="active">
                <a href="/Cat">
                    <div className="icon">
                        <i className="iconly-Category icli"></i>
                        <i className="iconly-Category icbo"></i>
                    </div>
                    <span>category</span>
                </a>
            </li>
            <li>
                <a href="/Kart">
                    <div className="icon">
                        <i className="iconly-Buy icli"></i>
                        <i className="iconly-Buy icbo"></i>
                    </div>
                    <span>cart</span>
                </a>
            </li>
            <li>
                <a href="/Wishlist">
                    <div className="icon">
                        <i className="iconly-Heart icli"></i>
                        <i className="iconly-Heart icbo"></i>
                    </div>
                    <span>wishlist</span>
                </a>
            </li>
            <li>
                <a href="/Profile">
                    <div className="icon">
                        <i className="iconly-Profile icli"></i>
                        <i className="iconly-Profile icbo"></i>
                    </div>
                    <span>profile</span>
                </a>
            </li>
        </ul>
    </div>


    
   
                
            </div>
        );
    }

}export default Inner_category;