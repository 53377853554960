import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      filterlist:[],
      ongoing: [],
      Delivered: [],
      search:"",
      
    };
    this.handleChange = this.handleChange.bind(this);

  }

   handleChange(e) {
     const{Data,}=this.state
     var list=Data
     
    
    var list1=[]
  console.log(list1);
    list1=list.filter(function (el,index,array){
         console.log("in");
         console.log(list1);
        
        let pattern= new RegExp(e.target.value, "i")
      if(e.target.value==="" || el.productname.match(pattern) || el.status.match(pattern)){
           console.log(true);
        return true;

      }
      else{
        console.log(false);
        return false;
      }
      })
   console.log(list1,"new");
      this.setState({
        filterlist:list1
      })
     
  }
  componentDidMount() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("U_Id"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch("https://api.emechmart.com/api/Order/GetAllOrders", requestOptions)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        this.setState((currentState) => ({
          Data: res.Data,
          filterlist:res.Data,
          delivered:res.Data.filter((d)=>d.status==="Delivered")

        }));
        console.log(this.state.Data);
      });
  }
  getparam(i) {
    const { Data } = this.state;
    var param = {
      id: Data[i].orderid,
      enc_id: Data[i].enc_orderitemid,
    };
    return param;
  }
  getcolor(i){
    const { filterlist } = this.state;
console.log(filterlist[i].status);
    if(filterlist[i].status==="Delivered"){
      return "green"
    }
    else if(filterlist[i].status==="Cancelled"){
      
      return "Red"
    }
    else{
      return "#e8ba2a"
    }
  }
  render() {
    const {filterlist} = this.state;
   console.log(filterlist);
    const Track = (status, i) => {
      if (status === "1") {
        return (
          <div>
          <Link
            to={{ pathname: "/Return_track", state: this.getparam(i) }}
            className="theme-color"
          >
            Track Return process
          </Link>
          </div>
        );
      }
    };
    return (
      <div>
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}

        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Order History</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- search panel start --> */}
        <div className="search-panel top-space px-15">
          <div className="search-bar" style={{width:"100%"}}>
            <input
              type="text"
              name="search"
              className="form-control form-theme"
              placeholder="Search"
              onChange={this.handleChange}
            />
            <i className="iconly-Search icli search-icon"></i>
            {/* <i className="iconly-Camera icli camera-icon"></i> */}
          </div>
          {/* <div className="filter-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasfilter">
            <i className="iconly-Filter icli"></i>
        </div> */}
        </div>
        {/* <!-- search panel end --> */}

        <section className="px-15">
          <h2 className="page-title">Open Orders</h2>
          <ul className="order-design">
            {filterlist.map((data, index) => (
              <li key={index}>
                <div className="order-box">
                  <div className="d-flex align-items-center order-designspace">
                    <img
                      src={data.productimage}
                      className="img-fluid order-img"
                      alt=""
                    />
                    <div className="media-body">
                      <h4>{data.productname} </h4>
                      <h5 className="content-color my-1">
                        {data.variation} &nbsp;Qty : {data.productquantity}
                      </h5>
                      <Link
                        to={{ pathname: "/Track", state: this.getparam(index) }}
                        className="theme-color"
                      >
                        Track Order
                      </Link>
                      {Track(data.return_status,index)}
                    </div>
                    <span className="status-label text-white" style={{backgroundColor:this.getcolor(index)}}>{data.status}</span>
                  </div>
                  <div className="delivery-status">
                     <div className="d-flex">
                      <div>
                        <h6 className="content-color">Ordered:</h6>
                        <h6>{data.orderdate}</h6>
                      </div>
                      <div>
                        <h6 className="content-color">Payment Status:</h6>
                        <h6>{data.payment_status}</h6>
                      </div>
                    </div> 
                  </div>
                </div>
              </li>
            ))}
            {/* <li>
                <div className="order-box">
                    <div className="d-flex align-items-center">
                        <img src="assets/images/products/2.jpg" className="img-fluid order-img" alt=""/>
                        <div className="media-body">
                            <h4>Pink Hoodie t-shirt full </h4>
                            <h5 className="content-color my-1">Size: S, Qty: 1</h5>
                            <a className="theme-color" href="#">View Details</a>
                        </div>
                        <span className="status-label">ongoing</span>
                    </div>
                    <div className="delivery-status">
                        <div className="d-flex">
                            <div>
                                <h6 className="content-color">Ordered:</h6>
                                <h6>26th May, 2021</h6>
                            </div>
                            <div>
                                <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </li> */}
          </ul>
        </section>
        {/* <div className="divider"></div>
  
    {/* <!-- section start --> */}
        {/* <section className="section-b-space pt-0 px-15">
        <h2 className="page-title">Past Orders</h2>
        <ul className="order-listing">
            <li>
                <div className="order-box">
                    <div className="d-flex align-items-center">
                        <img src="assets/images/products/3.jpg" className="img-fluid order-img" alt=""/>
                        <div className="media-body">
                            <h4>Pink Hoodie t-shirt full </h4>
                            <h5 className="content-color my-1">Size: S, Qty: 1</h5>
                            <a className="theme-color" href="#">View Details</a>
                        </div>
                        <spanc lassName="status-label bg-theme text-white">delivered</span>
                    </div>
                    <div className="delivery-status">
                        <div className="d-flex">
                            <div>
                                <h6 className="content-color">Ordered:</h6>
                                <h6>26th May, 2021</h6>
                            </div>
                            <div>
                                <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rate-section">
                    <ul>
                        <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i
                                className="iconly-Star icli"></i> Rate & Review Product</li>
                        <li><i className="iconly-Star icli"></i> Need Help?</li>
                    </ul>
                </div>
            </li>
            <li>
                <div className="order-box">
                    <div className="d-flex align-items-center">
                        <img src="assets/images/products/4.jpg" className="img-fluid order-img" alt=""/>
                        <div className="media-body">
                            <h4>Pink Hoodie t-shirt full </h4>
                            <h5 className="content-color my-1">Size: S, Qty: 1</h5>
                            <a className="theme-color" href="#">View Details</a>
                        </div>
                        <span className="status-label bg-theme text-white">delivered</span>
                    </div>
                    <div className="delivery-status">
                        <div className="d-flex">
                            <div>
                                <h6 className="content-color">Ordered:</h6>
                                <h6>26th May, 2021</h6>
                            </div>
                            <div>
                                <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rate-section">
                    <ul>
                        <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i
                                className="iconly-Star icli"></i> Rate & Review Product</li>
                        <li><i className="iconly-Star icli"></i> Need Help?</li>
                    </ul>
                </div>
            </li>
        </ul>
    </section> */}
        {/* <!-- section end --> */}

        {/* <!-- add review canvas start --> */}
        {/* <div
          className="offcanvas offcanvas-bottom h-auto"
          tabIndex="-1"
          id="offcanvasreview"
        >
          <div className="offcanvas-body">
            <h2 className="mb-2">Write Review</h2>
            <div className="d-flex align-items-center">
              <h4 className="content-color me-2">Your rating:</h4>
              <ul className="ratings">
                <li>
                  <i className="iconly-Star icbo"></i>
                </li>
                <li>
                  <i className="iconly-Star icbo"></i>
                </li>
                <li>
                  <i className="iconly-Star icbo"></i>
                </li>
                <li>
                  <i className="iconly-Star icbo"></i>
                </li>
                <li>
                  <i className="iconly-Star icbo empty"></i>
                </li>
              </ul>
            </div>
            <h4 className="content-color mt-2 mb-2">Review:</h4>
            <form className="mb-4 section-b-space">
              <textarea rows="4" className="form-control">
                {" "}
              </textarea>
            </form>
            <div className="cart-bottom row m-0">
              <div>
                <div className="left-content col-5">
                  <a href="#" className="title-color">
                    BACK
                  </a>
                </div>
                <a
                  data-bs-dismiss="offcanvas"
                  href="javascript:void(0)"
                  className="btn btn-solid col-7 text-uppercase"
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- add review canvas end --> */}

        {/* 
    <!-- add review canvas start --> */}
        {/* <div
          className="offcanvas offcanvas-bottom h-auto"
          tabIndex="-1"
          id="offcanvasfilter"
        >
          <div className="offcanvas-body">
            <h2 className="mb-2 mt-cls">Filters</h2>
            <form className="mb-2">
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  checked
                  className="radio_animated"
                  type="radio"
                  name="filter"
                  id="all"
                  value="all"
                />
                <label htmlFor="all" className="content-color">
                  All Orders
                </label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="filter"
                  id="open"
                  value="open"
                />
                <label htmlFor="open" className="content-color">
                  Open Orders
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="filter"
                  id="return"
                  value="return"
                />
                <label htmlFor="return" className="content-color">
                  Return Orders
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="filter"
                  id="cancelled"
                  value="cancelled"
                />
                <label htmlFor="cancelled" className="content-color">
                  Cancelled Orders
                </label>
              </div>
            </form>
            <h2 className="mb-2">Time Filter</h2>
            <form className="section-b-space mb-3">
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  checked
                  className="radio_animated"
                  type="radio"
                  name="time"
                  id="last30"
                  value="last30"
                />
                <label htmlFor="last30" className="content-color">
                  Last 30 Days
                </label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="time"
                  id="last6"
                  value="last6"
                />
                <label htmlFor="last6" className="content-color">
                  Last 6 Months
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="time"
                  id="2021"
                  value="2021"
                />
                <label htmlFor="2021" className="content-color">
                  2021
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="time"
                  id="2020"
                  value="2020"
                />
                <label htmlFor="2020" className="content-color">
                  2020
                </label>
              </div>
            </form>
            <div className="cart-bottom row m-0">
              <div>
                <div className="left-content col-5">
                  <a href="#" className="title-color">
                    BACK
                  </a>
                </div>
                <a
                  data-bs-dismiss="offcanvas"
                  href="javascript:void(0)"
                  className="btn btn-solid col-7 text-uppercase"
                >
                  Apply
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
export default History;
