import React, { Component } from "react";

class Help extends Component {
  render() {
    return (
      <div>
        {/* <!-- loader strat -->
    <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}

        <header>
          <div className="back-links">
            <a href="/Profile">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>help center</h2>
              </div>
            </a>
          </div>
        </header>

        <section className="px-15 top-space pt-0">
          {/* <div className="help-img">
            <img src="assets/images/help.jpg" className="img-fluid rounded-1 w-100" alt=""/>
        </div> */}
          <div className="faq-section section-t-space section-b-space">
            {/* <h4 className="fw-bold mb-1 mt-cls">Help Center</h4>
            <p className="content-color">
              Please get in touch and we will be happy to help you.
              Get quick customer support byselecting your item 
            </p> */}
            <div className="contactpage-blockcenter">
              <div className="contactpage-block">
                <span><i className="iconly-Location icli"></i></span>
                315-317, 3rd Floor, Diamond Complex,<br />
                 SH 41, Industrial Area, Chhapi,<br />
                 Gujarat (India) 385210
              </div>
              <div className="contactpage-block">
                <span><i className="iconly-Calling icli"></i></span>
                +91 6357392592
              </div>
              <div className="contactpage-block">
                <span><i className="iconly-Time-Circle icli"></i></span>
                11-00 Am To 5-30 Pm
              </div>
              <div className="contactpage-block">
                <span><i className="iconly-Chat icli"></i></span>
                care@emechmart.com
              </div>
            </div>
         

            {/* <h4 className="fw-bold mb-3">What issues are you facing?</h4>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne">
                            I want to track my order
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            I want to manage my order
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree">
                            I did not receive Instant Cashback
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour">
                            I want help with other issues
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive">
                            I am unable to pay using wallet
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix">
                            I want to unsubscribe from promotional emails and SMS
                        </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven">
                            I want help with returns & refunds
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body content-color">The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied
                            by English versions from the 1914 translation by H. Rackham.</div>
                    </div>
                </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
}
export default Help;
