import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class No_order extends Component{

    render(){

        return(
            <div>
{/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
    


    
    <header>
        <div className="back-links">
            <a href="/Profile">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Order History</h2>
                </div>
            </a>
        </div>
    </header>
    


   
    <section className="px-15">
        <div className="empty-cart-section text-center">
            <img src="assets/images/empty-cart.png" className="img-fluid" alt=""/>
            <h2>Whoops !! No Orders Yet</h2>
            <p>Looks like you haven’t made any orders yet.</p>
            <a href="/Shop" className="btn btn-solid w-100">start shopping</a>
        </div>
    </section>
    
                
            </div>
        );
    }

}export default No_order;