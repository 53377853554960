import React from 'react';
import copy from "copy-to-clipboard";



 export default class Copy extends React.Component{
    constructor(props){
        super(props);
        this.state={
            title:"Tap to copy"
        }
    }
     copytoclipboard() {

    // const{promocode}=this.state
     copy(this.props.dataFromParent);
    console.log(this.props.dataFromParent);
      this.setState({
      title:"Copied"
    })
    
  }
  render(){
      const{title}=this.state
      return(
                    <h6
                      className="content-color"
                      style={{ "margin-bottom": "20px" }}
                      onClick={(e)=>this.copytoclipboard()}
             >
                    {title}
                    </h6>
      );
  }
}