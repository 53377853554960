import React, { useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./component/history";
import About from "./component/about-us";
import Kart from "./component/cart";
import Coupon from "./component/coupons";
import Delivery from "./component/delivery";
import Login from "./component/login";
import Form from "./component/form-style";
import Main from "./component/main";
import Register from "./component/register";
import Cat from "./component/category";
import Forgot from "./component/forgot-password";
import Profile from "./component/profile";
import Profile_setting from "./component/profile-setting";
import Shop from "./component/shop";
import Help from "./component/help";
import Accord from "./component/accordion";
import Ekart from "./component/empty-cart";
import New_address from "./component/new-address";
import No_order from "./component/no-order";
import Notification from "./component/notification";
import Onboard from "./component/onboarding";
import History from "./component/order-history";
import Order_placed from "./component/order-placed";
import Track from "./component/order-tracking";
import Payment from "./component/payment";
import Product from "./component/product";
import Reset_password from "./component/reset-password";
import Saved_address from "./component/saved-address";
import Saved_cards from "./component/saved-cards";
import Search from "./component/search";
import Settings from "./component/settings";
//import Tabs from "./component/tabs";
import TC from "./component/terms-condition";
import Wishlist from "./component/wishlist";
//import Pricing_slider from "./component/pricing-slider";
import Inner_category from "./component/inner-category";
import Pages from "./component/pages";
//import Modal_offcanvas from "./component/modals-offcanvas";
import OTP from "./component/otp";
import Home from "./component/homepage";
import Reset_phone from "./component/reset-phone";
import Reset_email from "./component/reset-email";
import Ewishlist from "./component/empty-whishlist";
import Rename from "./component/rename";
import Razor from "./component/razorpay";
import Return from "./component/return";
import Refund from "./component/refundinfo";
import Return_track from "./component/returntrack";
import Bulk from "./component/bulkform";
import Ebulk from "./component/ebulk";
import Purchase from "./component/getpurchase";
import Review from "./component/getreview";
import Rate from "./component/rateproduct";
import { withRouter } from "react-router-dom";
import "./component/assets/css/style.css";
import { onMessageListener } from "./firebaseInit";
import Notifications from "./component/Notifications";
import ReactNotificationComponent from "./component/ReactNotification";
import Cancel_order from "./component/order-cancel"
import Reaturn_Policy from "./component/Return-policy"


function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  console.log(show, notification);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.data.title,
        body: payload.data.body,
      });
      
      console.log(show);
      setShow(false);
      setNotification({
        title: "",
        body: "",
      });
    })
    .catch((err) => console.log("failed", err));
  return (
    
    <div>
      {show===true && notification.title!=="" ? (
       
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : null
      
      }
      <Notifications/>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Register" component={Register} />
          <Route exact path="/Kart" component={Kart} />
          <Route exact path="/Shop" component={Shop} />
          <Route exact path="/Profile" component={Profile} />
          <Route exact path="/Cat" component={Cat} />
          <Route exact path="/Forgot" component={Forgot} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Coupon" component={Coupon} />
          <Route exact path="/Delivery" component={Delivery} />
          <Route exact path="/Form" component={Form} />
          <Route exact path="/Profile_setting" component={Profile_setting} />
          <Route exact path="/Help" component={Help} />
          <Route exact path="/Accord" component={Accord} />
          <Route exact path="/Ekart" component={Ekart} />
          <Route exact path="/New_address" component={New_address} />
          <Route exact path="/No_order" component={No_order} />
          <Route exact path="/Notification" component={Notification} />
          <Route exact path="/Onboard" component={Onboard} />
          <Route exact path="/History" component={History} />
          <Route exact path="/Order_placed" component={Order_placed} />
          <Route exact path="/Track" component={Track} />
          <Route exact path="/Saved_address" component={Saved_address} />
          <Route exact path="/Saved_cards" component={Saved_cards} />
          <Route exact path="/Search" component={Search} />
          <Route exact path="/Payment" component={Payment} />
          <Route exact path="/Product" component={Product} />
          <Route exact path="/Reset_password" component={Reset_password} />
          <Route exact path="/Settings" component={Settings} />
          <Route exact path="/TC" component={TC} />
          <Route exact path="/Wishlist" component={Wishlist} />
          <Route exact path="/Inner_category" component={Inner_category} />
          <Route exact path="/Pages" component={Pages} />
          <Route exact path="/OTP" component={OTP} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/Ewishlist" component={Ewishlist} />
          <Route exact path="/Reset_phone" component={Reset_phone} />
          <Route exact path="/Reset_email" component={Reset_email} />
          <Route exact path="/Rename" component={Rename} />
          <Route exact path="/Razor" component={Razor} />
          <Route exact path="/Return" component={Return} />
          <Route exact path="/Refund" component={Refund} />
          <Route exact path="/Return_track" component={Return_track} />
          <Route exact path="/Bulk" component={Bulk} />
          <Route exact path="/Purchase" component={Purchase} />
          <Route exact path="/Ebulk" component={Ebulk} />
          <Route exact path="/Review" component={Review} />
          <Route exact path="/Rate" component={Rate} />
          <Route exact path="/Cancel_order" component={Cancel_order} />
          <Route exact path="/Reaturn_Policy" component={Reaturn_Policy} />


        </Switch>
      </Router>
    </div>
  );
}

export default withRouter(App);
