import React, { Component } from "react";


class Form extends Component{

    render(){

        return(
            <div>
 {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}


    <header>
        <div className="back-links">
            <a href="/Pages">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Form Style</h2>
                </div>
            </a>
        </div>
    </header>
   


   
    <section className="px-15 top-space ratio_45 pt-0">
        <div>
            <h2 className="page-title pb-2">Input Style</h2>
            <div className="form-floating mb-4">
                <input type="text" className="form-control" id="one" placeholder="Full Name"/>
                <label htmlFor="one">Full Name</label>
            </div>
            <div className="form-floating mb-4">
                <input type="password" className="form-control" id="one1" placeholder="password"/>
                <label htmlFor="one1">Password</label>
            </div>
            <div className="form-floating mb-4">
                <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                    <option value="1">Select 1</option>
                    <option value="2">Select 2</option>
                    <option value="3">Select 3</option>
                </select>
                <label htmlFor="floatingSelect">Selection</label>
            </div>
        </div>
        <div>
            <h2 className="page-title">Radio Box</h2>
            <form className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <input checked className="radio_animated" type="radio" name="filter" id="all" value="all"/>
                    <label htmlFor="all" className="content-color">Default Radio</label>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <input className="radio_animated" type="radio" name="filter" id="open" value="open"/>
                    <label htmlFor="open" className="content-color">Default Radio 1</label>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <input className="radio_animated" type="radio" name="filter" id="return" value="return"/>
                    <label htmlFor="return" className="content-color">Default Radio 2</label>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <input className="radio_animated" type="radio" name="filter" id="cancelled" value="cancelled"/>
                    <label htmlFor="cancelled" className="content-color">Default Radio 3</label>
                </div>
            </form>
            <h2 className="page-title mt-4">Checkbox</h2>
            <form>
                <div className="checkbox_animated">
                    <div className="d-flex align-items-center mb-2">
                        <input type="checkbox" name="address" id="onecheckbox" value="male" checked />
                        <label htmlFor="onecheckbox">Checkbox Option 1</label>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <input type="checkbox" name="address" id="onecheckbox2" value="male"/>
                        <label htmlFor="onecheckbox2">Checkbox Option 2</label>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <input type="checkbox" name="address" id="onecheckbox3" value="male"/>
                        <label htmlFor="onecheckbox3">Checkbox Option 3</label>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <input type="checkbox" name="address" id="onecheckbox4" value="male"/>
                        <label htmlFor="onecheckbox4">Checkbox Option 4</label>
                    </div>
                </div>
            </form>
        </div>
    </section>







            </div>
        );
    }

}export default Form;