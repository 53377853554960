import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class TC extends Component {
  render() {
    return (
      <div>
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
     */}

        <header>
          <div className="back-links">
            <a href="/Profile">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Terms & Condition</h2>
              </div>
            </a>
          </div>
        </header>

        <section className="px-15 top-space pt-0">
          <h2 className="mb-3">Terms and Conditions for Emechmart</h2>
          <h4 className="mb-1">
            KINDLY GO THROUGH FOLLOWING TERMS AND CONDITIONS CAREFULLY:
          </h4>
          <p className="content-color">
            The following demonstrates User Agreement between N & T ENGITECH
            PVT. LTD. (hereinafter referred to as "EMECHMART") and the user(s)
            of the website- www.emechmart.com. Before user subscribes to and/or
            begins participating in or using web site, EMECHMART believes that
            user(s) have fully read, understood and accept the Agreement. All
            the terms and conditions listed here for the B2B e-commerce and B2C
            marketplace businesses are in accordance with the FDI policy. If
            user do not agree to or wish to be bound by Agreement, user may not
            access to or otherwise use the website. This Policy should be at all
            times read along with the Terms of Use of the Website.
          </p>
          <h4 className="mb-1">USER AGREEMENT</h4>
          <p className="content-color">
            User’s use of the EMECHMART website - www.emechmart.com (hereinafter
            referred to as "the website") and services available on a website is
            governed by the following terms and conditions. This User Agreement
            for the website shall come into effect on Month Day, Year. EMECHMART
            may change, modify, amend, or update this agreement from time to
            time without any prior notification to user(s) and the amended and
            restated terms and conditions of use shall be effective immediately
            on posting. Users are advised to regularly check for any amendments
            or updates to the terms and conditions contained in this User
            Agreement. If users do not adhere to the changes, user must stop
            using the service. User’s continuous use of the service will signify
            user’s acceptance of the changed terms.
          </p>
          <p className="content-color">
            You are granted limited license only for purposes of viewing the
            material contained on this Website.
          </p>
          <h4 className="mb-1">ELIGIBILITY OF USER(S)</h4>
          <p className="content-color mb-1">
            User(s) means any individual or business entity/organization that
            legally operates in India or in other countries, uses and has the
            right to use the services provided by EMECHMART. Our services are
            available only to those individuals or companies who can form
            legally binding contracts under the applicable law i.e. Indian
            Contract Act, 1872. As a minor if user wish to purchase or sell an
            item on the Website such purchase or sale may be made by user’s
            legal guardian or parents who have registered as users of the
            Website. EMECHMART advises its users that while accessing the
            website, they must follow/abide by the related laws.
          </p>

          <p className="content-color mb-2">
            EMECHMART is not responsible for the possible consequences caused by
            user’s behavior during use of website. EMECHMART may, in its sole
            discretion, reserve the right to terminate user’s membership and
            refuse to provide user with access to the Website at any time. We
            record buying and browsing activities of our users including but not
            limited to USER’S contact details and profiles and uses the same to
            provide value-added services to our users. If user are registering
            as a business entity, user represent that user are duly authorized
            by the business entity to accept this User Agreement and user have
            the authority to bind that business entity to this User Agreement.
            Kindly note our website is also not a risks proof website.
          </p>
          <h4 className="mb-1">REGISTRATION AND USER’S ACCOUNT</h4>
          <p className="content-color">
            To become a Registered User(s) there is a proper procedure which is
            for the convenience of user(s) so that they can easily log-in.
            User(s) can become a Registered User(s) by filling an on-line
            registration form on the website by giving desired information
            (name, contact information, details of its business, etc.).
            EMECHMART will establish an account ("Account") for the user(s) upon
            registration and assign a user alias ("User ID") and password
            ("Password") for log-in access to user’s Account. If user use the
            Website as Registered User, user are responsible for maintaining the
            confidentiality of user’s User ID and Password. User are responsible
            for maintaining the confidentiality of user’s personal and
            non-personal information and for restricting access to user’s
            computer, computer system and computer network, and user are
            responsible for all activities that occur under user’s User ID and
            Password , email id and cell number as the case may be. If user
            register on behalf of a business entity, user represent that
            business entity and (a) user have the authority to bind the entity
            to terms and condition of use and/or this Agreement; (b) the address
            user use when registering is the principal place of business of such
            business entity; and (c) all other information submitted to
            EMECHMART during the registration process is true, accurate, current
            and complete. By becoming a Registered User, user consent to the
            inclusion of user’s personal and non-personal data in our on-line
            database and authorize EMECHMART to share such information with
            other user(s). EMECHMART may refuse registration and deny the
            membership and associated User ID and Password to any user for
            whatever reason. Website may suspend or terminate a registered
            membership at any time without any prior notification in interest of
            EMECHMART or general interest of its visitors/other members without
            giving any reason thereof. Despite User’s organization’s contact
            number(s) are on Do Not Call Registry, User consent to be contacted
            by EMECHMART through phone calls, SMS notifications or any other
            means of communication, in respect to the services provided by
            EMECHMART. User agree, inter alia, to: 1. Provide true, accurate,
            current and complete information about user as prompted by
            EMECHMART's registration form or provided by User 2. Maintain and
            promptly update the personal and non-personal Data to keep it true,
            accurate, current and complete. If user provide any information that
            is untrue, inaccurate, not current or incomplete or EMECHMART has
            reasonable grounds to suspect that such information is untrue,
            inaccurate, not current or incomplete, or not in accordance with the
            User Agreement, EMECHMART has the right to indefinitely suspend or
            terminate or block access of user’s membership with the Website and
            refuse to provide user with access to the Website.
          </p>
          <h4 className="mb-1">USE OF EMECHMART</h4>
          <p className="content-color">
            That the user understands and agrees that EMECHMART and the Website
            merely provide hosting services to its Registered Users and persons
            browsing/visiting the Website. User(s) hereby grant an irrevocable,
            perpetual, worldwide and royalty-free, sub-licensable (through
            multiple tiers) license to EMECHMART to display and use all
            information provided by them in accordance with the purposes set
            forth in agreement and to exercise the copyright, publicity, and
            database rights user have in such material or information, in any
            form of media, third party copyrights, trademarks, trade secret
            rights, patents and other personal or proprietary rights affecting
            or relating to material or information displayed on the web site,
            including but not limited to rights of personality and rights of
            privacy, or affecting or relating to products that are offered or
            displayed on the website. EMECHMART will only use User’s Information
            in accordance with the User Agreement and EMECHMART Privacy Policy.
            User represents and confirms that user shall have absolute right,
            title and authority to deal in and offer for sale such items, goods
            or products. From time to time, user shall be responsible for
            providing information relating to the items or services proposed to
            be sold by user. In this connection, user undertakes that all such
            information shall be accurate in all respects. User shall not
            exaggerate or over emphasize the item-specifics of such items or
            services so as to mislead other Users in any manner. The user shall
            take note of following aspects and abide by the same:
          </p>
          <p className="content-color">
            Kindly note that as per Information Technology (Intermediaries
            guidelines) Rules, 2011, in case of non-compliance with user
            agreement and privacy policy for access or usage of intermediary
            computer resource, the Intermediary has the right to immediately
            terminate the access or usage rights of the users to the computer
            resource of Intermediary and remove non-compliant information.
          </p>
          <p className="content-color">
            User shall not host, display, upload, modify, publish, transmit,
            update or share any information or share/list(s) any information or
            cite it if it belongs to another person and to which User do not
            have any right to or is: Is grossly harmful, harassing, blasphemous,
            defamatory, obscene, pornographic, pedophilic, libelous, invasive of
            another's privacy, hateful, or racially, ethnically objectionable,
            disparaging, relating or encouraging money laundering or gambling,
            or otherwise unlawful in any manner whatever; or unlawfully
            threatening or unlawfully harassing including but not limited to
            "indecent representation of women" within the meaning of the
            Indecent Representation of Women (Prohibition) Act, 1986.
          </p>

          <p className="content-color">
            Infringes any patent, trademark, copyright or other proprietary
            rights or third party’s trade secrets or rights of publicity or
            privacy or shall not be fraudulent or involve the sale of
            counterfeit or stolen items of third parties that it feels may be of
            interest to the user or if the user has shown interest in the same.
          </p>
          <p className="content-color">
            Deceives or misleads the addressee/ users about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature.
          </p>

          <p className="content-color">
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains any Trojan horses, worms, time
            bombs, or other computer programming routines that may damage,
            detrimentally interfere with, diminish value of, surreptitiously
            intercept or expropriate any system, data or personal information.
          </p>
          <p className="content-color">
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains any Trojan horses, worms, time
            bombs, or other computer programming routines that may damage,
            detrimentally interfere with, diminish value of, surreptitiously
            intercept or expropriate any system, data or personal information.
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains any Trojan horses, worms, time
            bombs, or other computer programming routines that may damage,
            detrimentally interfere with, diminish value of, surreptitiously
            intercept or expropriate any system, data or personal information.
            Threatens the unity, integrity, defense, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </p>
          <p className="content-color">
            Shall not, directly or indirectly, offer, attempt to offer, trade or
            attempt to trade in any item, the dealing of which is prohibited or
            restricted in any manner under the provisions of any applicable law,
            rule, regulation or guideline for the time being in force.
          </p>
          <p className="content-color">
            Shall not create liability for us or cause us to lose (in whole or
            in part) the services of our ISPs or other suppliers; and
          </p>
          <p className="content-color">
            Shall not link directly or indirectly to or include descriptions of
            items, goods or services that are prohibited under the User
            Agreement or any other applicable law for the time being in force
            including but not limited to the Drugs and Cosmetics Act, 1940, the
            Drugs And Magic Remedies (Objectionable Advertisements) Act, 1954,
            the Indian Penal Code, 1860, Information Technology Act 2000 as
            amended time to time and rules there under.
          </p>
          <p className="content-color">
            User shall at all times ensure full compliance with the applicable
            provisions of the Information Technology Act, 2000 and rules there
            under as applicable and as amended from time to time and also all
            applicable Domestic laws, rules and regulations (including the
            provisions of any applicable Exchange Control Laws or Regulations in
            Force) and International Laws, Foreign Exchange Laws, Statutes,
            Ordinances and Regulations (including, but not limited to Sales Tax/
            VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty,
            Local Levies) regarding user’s use of our services. User shall not
            engage in any transaction in an item or service, which is prohibited
            by the provisions of any applicable law including exchange control
            laws or regulations for the time being in force. In particular user
            shall ensure that if any of user’s items listed on the Website
            qualifies as an "Antiquity" or "Art treasure" as defined in the Act
            ("Artwork"), user shall indicate that such Artwork is
            "non-exportable" and sold subject to the provisions of the Arts and
            Antiquities Act.
          </p>

          <h4 className="mb-1">ELECTRONIC COMMUNICATIONS</h4>
          <p className="content-color">
            When User use the Website or send emails or other data, information
            or communication to EMECHMART, User agree and understand that User
            are communicating with EMECHMART through electronic records and User
            consent to receive communications via electronic records from
            EMECHMART periodically and as and when required.
          </p>
          <h4 className="mb-1">INDEMNIFICATION</h4>
          <p className="content-color">
            The buyer shall keep EMECHMART.COM indemnified against freight, and
            other charges including duties, taxes, octroi, cess, clearance
            charges and any other charge/levy by whatsoever name called, levied
            on shipments plus disputes and actions with their Consignees if any
            related to quality, quantity and condition. The buyer shall keep
            EMECHMART.COM indemnified from any sequential liability due to the
            product related issue or due to the delay in delivery of the
            product. The buyer shall keep EMECHMART.COM indemnified against all
            duties, taxes, octroi, cess, clearance charges and any other
            charge/levy by whatsoever name called, levied on shipments and shall
            keep EMECHMART.COM indemnified against any loss, damage, costs,
            expenses arising out of any action or proceedings initiated by any
            authority (judicial or regulatory of the like) on account of any
            act/omissions on its part. The buyer will be solely responsible to
            comply with all statutory requirements (State, Central
            Laws/Statutes, Tax Laws and Local Laws) applicable in relation to
            booking, sale and transportation of the shipments carried and
            delivered by EMECHMART.COM in pursuance of this agreement. The buyer
            shall keep EMECHMART.COM indemnified against any loss, damage,
            costs, expenses arising out of any action or proceedings initiated
            by any authority (judicial or regulatory or the like) on account of
            any act/omissions on its part.
          </p>
         
        </section>
      </div>
    );
  }
}
export default TC;
