import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-js-loader";
import Iconmenu from "./iconmenu.js"
import{Userlogin,Discount} from "./Helper"
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";


const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Wishlist extends Component{
constructor(props){
    super(props)
    this.state={
        Data:[],
        loading: true,
    }
}
componentDidMount(){
    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2300);
    if(Userlogin()===true)
    {
        this.props.history.push("/Ewishlist")
    }
    else{
        const headers={
             headers:{
             'Authorization':'Bearer '+localStorage.getItem("U_Id")}
         }

        fetch("https://api.emechmart.com/api/Wishlist/GetWishlistData",headers)
        .then((response)=>response.json())
        .then((res)=>{
            console.log(res);
            this.setState({
                Data:res.Data,
            })
        })
    }
}
componentWillUnmount() {
    clearTimeout(this.isLoading);
  }
removeitem(id){
    var key="productid="+encodeURIComponent(id)
   
     const headers={
             method:'POST',
             mode:"cors",
             headers:{'content-type':'application/x-www-form-urlencoded',
             'authorization':'Bearer '+localStorage.getItem("U_Id")},
             body:key
         };
    fetch("https://api.emechmart.com/api/Wishlist/DeleteItem", headers)
    .then((resp)=>resp.json())
    .then((res)=>{
        let success=res.Success;
        if(success===true){
          toast.success("Remove Successsfully!",{autoClose:2000})
          const wishlistData = this.state.Data;
     this.setState({
         Data: wishlistData.filter(d => d.productid !== id),
       });
          //window.location.reload(false);
        }else{
            toast.error("Fail to remove!",{autoClose:2000})
        }

    })
    
}

    render(){
     
     const{Data,loading}=this.state;
     
      const available = (stock) => {
      if (stock!=="1") {
        return (
          <label style={{ color: "Red", "margin-bottom": "3px" }}>
            OutofStock
          </label>
        );
      } else {
        return (
          <label style={{ color: "#007744", "margin-bottom": "3px" }}>
            InStock
          </label>
        );
      }
    };
     
     
     
     
     const wishlist=()=>{
     if(Data===undefined || Data.length===0){
         return(
             <section className="px-15">
        <div className="empty-cart-section text-center">
            <img src="assets/images/empty-cart.png" className="img-fluid" alt=""/>
            <h2>No items in wishlist!</h2>
            <a href="/" className="btn btn-solid w-100">start shopping</a>
        </div>
    </section>
         )
     
     }
     else{
         return(
 <section className="cart-section pt-0 top-space section-b-space">
         {Data.map((Data)=>(
             <div>
        <div className="cart-box px-15" key={Data.wishlistid}>
            <Link to={{pathname:"/Product", state:Data.productid}} className="cart-img">
                <img src={Data.productimage} className="img-fluid" alt=""/>
            </Link>
            <div className="cart-content">
                <a href="/Product">
                    <h4>{Data.productname} </h4>
                </a>
                {/* <h5 className="content-color">by Mango</h5> */}
                <div className="price">
                    <h4>{'\u20B9'}{Data.vprice} <del>{'\u20B9'}{Data.productprice}</del><span>{Discount(Data.productprice,Data.vprice)}%</span></h4>
                </div>
                 <div className="price">
                 {available(Data.productavailability)}
                    {/* <h4>Availability :<span>{Data.productavailability}</span></h4> */}
                </div>
                <div className="cart-option">
                    <h5 data-bs-toggle="offcanvas"><i className="iconly-Buy icli"></i> Add to
                        Cart</h5>
                    <span className="divider-cls">|</span>
                    <h5 data-bs-toggle="offcanvas" onClick={(e)=>this.removeitem(Data.productid)}><i className="iconly-Delete icli"></i>
                        Remove
                    </h5>
                </div>
            </div>   
        </div>
       <div className="divider"></div> 
       </div>   
        ))}     
    </section>
         )
     }
 }
        return loading ? (
      <Loading />
    ) : (
            <div>
<ToastContainer  Colored theme='colored' closeButton={false} />
    
    <header>
        <div className="back-links">
            <a href="/">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Your Wishlists</h2>
                </div>
            </a>
        </div>
        <div className="header-option">
            <ul>
                <li>
                   <Iconmenu />
                </li>
            </ul>
        </div>
    </header>
   


    {/* <!-- cart items start --> */}
       {wishlist()}

    {/* <!-- cart items end --> */}


    {/* <!-- remove item canvas start --> */}
    {/* <div className="offcanvas offcanvas-bottom h-auto removecart-canvas" id="removecart">
        <div className="offcanvas-body small">
            <div className="content">
                <h4>Remove Item:</h4> 
                <p>Are you sure you want to remove or move this item from the cart?</p>
            </div>
            <div className="bottom-cart-panel">
                <div className="row">
                    <div className="col-7">
                        <a href="/Wishlist" className="title-color">MOVE TO WISHLIST</a>
                    </div>
                    <div className="col-5">
                        <a href="#" className="theme-color">REMOVE</a>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- remove item canvas end --> */}
                
            </div>
        );
    }

}export default Wishlist;