import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";


var binfo,dinfo;
const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Delivery extends Component{
     constructor(props){
       super(props);
       this.state={
         B_info:[],
         D_info:[],
         cartinfo:[],
         baddress:"",
         d_address:"",
         binfo:{},
         dinfo:{},
         dpincode:"",
         loading: true
       }
    this.commonChange = this.commonChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
     }
     componentDidMount(){
    //     this.isLoading = setTimeout(() => {
    //   this.setState({ loading: false });
    // }, 2300);

   var category="category=Billing"
 const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer "+localStorage.getItem("U_Id") },
      body: category,
    };
    fetch("https://api.emechmart.com/api/UserAccount/GetAllDeliveryAddress", requestOptions)
    .then((response)=>response.json())
    .then((res)=>{
      console.log(res);
      
      if(res.Data===""){
        this.props.history.push("/New_address")
      }else{
      this.setState({
        loading: false,
        B_info:res.Data
      })}
    })

     var dcategory="category=Delivery"
 const drequestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer "+localStorage.getItem("U_Id") },
      body: dcategory,
    };
    fetch("https://api.emechmart.com/api/UserAccount/GetAllDeliveryAddress", drequestOptions)
    .then((response)=>response.json())
    .then((res)=>{
      console.log(res);
      if(res.Data===""){
        this.props.history.push("/New_address")
      }else
     { this.setState({
        D_info:res.Data,
        
      })}
    })
     var data="promocodename=''"
   const crequestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer "+localStorage.getItem("U_Id") },
      body: data,
    };
    fetch("https://api.emechmart.com/api/Order/GetProductsForCheckout",crequestOptions)
    .then((resp)=>resp.json())
    .then((res)=>{
      console.log(res)
      this.setState({
        cartinfo:res.Data.OrderedProducts
      })
    })
     
  }
  // componentWillUnmount() {
  //   clearTimeout(this.isLoading);
  // }
  
  commonChange(event) {
    this.setState({
      baddress: event.target.value,
      
    });
  console.log(event.target.value,"b");
  }
  handleChange(e){
    const{D_info}=this.state
    var pincode1;
    const value=e.target
    const id=value.value
console.log(e.target.value,"d");

    pincode1=D_info.filter((d)=>d.randid===id)
    this.setState({
      d_address:e.target.value,
    })
    if(e.target.value!==""){
      this.setState({
      dpincode:pincode1[0].pincode
      })
    }
console.log(e.target.value,"d");
  }
handlesubmit(){
  const{baddress,d_address,dpincode}=this.state
  if(baddress===""|| d_address===""){
    toast.dismiss()
    toast.error("Please select Address",{autoClose:3000});
  }
  else{
    this.props.history.push({pathname:"/Payment",state:{baddress,d_address,dpincode}})
  }
}
  async editprocess(){
 
    const bdata="randid="+encodeURIComponent(this.state.baddress)+"&category=Billing"
    console.log("b"+bdata);
 const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer "+localStorage.getItem("U_Id") },
      body: bdata,
    };

    const response= (
    await fetch("https://api.emechmart.com/api/UserAccount/GetDeliveryAddress",requestOptions)
    ).json()
        var res=await response
        if(res!==""){
        binfo= res.Data
      
        }

    const ddata="randid="+encodeURIComponent(this.state.d_address)+"&category=Delivery"
    console.log("d   "+ddata);

     const drequestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer "+localStorage.getItem("U_Id") },
      body: ddata,
    };
    const dresponse=(
    await  fetch("https://api.emechmart.com/api/UserAccount/GetDeliveryAddress",drequestOptions))
    .json()
    var drep=await dresponse
    if(drep!==""){
      dinfo=drep.Data

    }
  
  }
 processgo(){
 
  this.editprocess().then((res)=>{
     this.props.history.push({ pathname:"/New_address" ,state:{binfo,dinfo,}})
  })
  
}
  render(){
  const{B_info,D_info,baddress,d_address,cartinfo,loading}=this.state
  console.log(this.state.dpincode);

const edit=()=>{
  if(baddress!=="" && d_address!==""){
    return(
  <button  className="btn btn-outline text-capitalize w-100 mt-3" onClick={(e)=>this.processgo()}> Edit address</button>

    )
  }
}

      return loading ? (
      <Loading />
    ) :
      (
<div className="box">
    

  <ToastContainer hideProgressBar  Colored theme='colored' closeButton={false} />

  
  <header>
    <div className="back-links">
      <a href="/Kart">
        <i className="iconly-Arrow-Left icli"></i>
        <div className="content">
          <h2>Delivery Details</h2>
          <h6>Step 2 of 3</h6>
        </div>
      </a>
    </div>
    <div className="header-option">
      <ul>
        <li>
          <a href="/Wishlist"><i className="iconly-Heart icli"></i></a>
        </li>
      </ul>
    </div>
  </header>
  


  {/* <!-- delivery option section start --> */}
  <section className="top-space px-15">
    <div className="delivery-option-section">
    <div className="modal-body">
            <div className="filter-box">
                  <h2 className="billing" >Billing &nbsp; Address:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="baddress"
                       onChange={this.commonChange}
                    >
                      <option  value="">
                        Select Address
                      </option>
                      {/* <option value="india">india</option> */}
                      {B_info.map((data)=>(
                      <option value={data.randid}>{data.fullname}, {data.houseno}, {data.landmark}
                        {data.area}, {data.state}
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="edit-address">
                </div>
                </div>
                 <div className="d-divider"></div>
                <div className="modal-body">
                 <div className="filter-box">
                  <h2 className="billing">Delivery &nbsp; Address:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="d_address"
                      onChange={this.handleChange}
                    >
                      <option value="">
                        Select Address
                      </option>
                      
                       {D_info.map((data)=>(
                      <option value={data.randid}>{data.fullname}, {data.houseno}, {data.landmark}
                        {data.area}, {data.state}
                      </option>
                      ))}
                      
                    </select>
                  </div>
                </div>
                <div className="edit-address">
                </div>
              </div>
      {/* <ul>
        <li>
          <div className="check-box active">
            <div className="form-check d-flex ps-0">
              <input className="radio_animated" checked type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
              <label className="form-check-label" htmlFor="flexRadioDefault1"> </label>
              <div>
                <h4 className="name">Carolina S Seo</h4>
                <div className="addess">
                  <h4>3501 Maloy Court, </h4>
                  <h4>East Elmhurst, New York City</h4>
                  <h4>NY 11369</h4>
                </div>
                <h4>Phone No: 903-239-1284</h4>
                <h6 className="label">HOME</h6>
              </div>
            </div>
          </div>
          <div className="buttons">
            <a href="#">Remove</a>
            <a href="#">edit</a>
          </div>
        </li>
        <li>
          <div className="check-box">
            <div className="form-check d-flex ps-0">
              <input className="radio_animated" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
              <label className="form-check-label" htmlFor="flexRadioDefault2"></label>
              <div>
                <h4 className="name">Arthur M Willingham</h4>
                <div className="addess">
                  <h4>3059 Elk City Road</h4>
                  <h4>Indianapolis, Indiana</h4>
                  <h4>IN 46229</h4>
                </div>
                <h4>Phone No: 317-898-0622</h4>
                <h6 className="label">OFFICE</h6>
              </div>
            </div>
          </div>
          <div className="buttons">
            <a href="#">Remove</a>
            <a href="#">edit</a>
          </div>
        </li>
      </ul> */}
      {edit()}
      <a href="/New_address" className="btn btn-outline text-capitalize w-100 mt-3">add New address</a>
    </div>
  </section>
  <div className="divider"></div>
  {/* <!-- delivery option section end --> */}


  {/* <!-- expected delivery section start --> */}
  <section className="px-15 pt-0">
    <h2 className="page-title">Product Summary</h2>
    <div className="product-section">
      <div className="row gy-3">
      {cartinfo.map((data)=>(
        <div className="col-12">
          <div className="product-inline">
            <Link to={{pathname:"/Product",state:data.productrandid}}>
              <img src={data.productimage} className="img-fluid" alt=""/>
            </Link>
            <div className="product-inline-content">
              <div>
               <Link to={{pathname:"/Product",state:data.productrandid}}>
                  <h4 className="name">{data.productname} </h4>
                <div className="price">
                  <h4 className="content-color">Quantity : {data.productquantity}&ensp;&ensp;MRP: {"\u20B9"}{data.finalprice}
                 {/* <span>25th July</span> */}
                 </h4>
                </div>
              </Link> 
              </div>
            </div>
          </div>
        </div>
        ))}
        {/* <div className="col-12">
          <div className="product-inline">
            <a href="/Product">
              <img src="assets/images/products/2.jpg" className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href="/Product">
                  <h4 className="content-color">Men Blue Denim Jacket</h4>
                </a>
                <div className="price">
                  <h4>Delivery by <span>25th July</span></h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  {/* <!-- expected delivery section end --> */}


 
  <section className="panel-space"></section>



 
  <div className="delivery-cart cart-bottom">
    <div>
      {/* <div className="left-content">
        <h4>$270.00</h4>
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasdetails"
          className="theme-color">View details</a>
      </div> */}
      <button class="w-100 btn btn-solid" onClick={(e)=>this.handlesubmit()}>Proceed to payment</button> 
 
    </div>
  </div>
 


  {/* <!-- order details canvas start --> */}
  <div className="offcanvas offcanvas-bottom h-auto" tabindex="-1" id="offcanvasdetails">
    <div className="offcanvas-body">
      <div className="order-details">
        <ul>
          <li>
            <h4>Bag total <span>$220.00</span></h4>
          </li>
          <li>
            <h4>Bag savings <span className="text-green">-$20.00</span></h4>
          </li>
          <li>
            <h4>Coupon Discount <span>$20.00</span></h4>
          </li>
          <li>
            <h4>Delivery <span>$50.00</span></h4>
          </li>
        </ul>
        <div className="total-amount">
          <h4>Total Amount <span>$270.00</span></h4>
        </div>
      </div>
    </div>
  </div>
</div>
      );
  }





}export default Delivery;