import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

const statelist = [
  { Text: "Andaman and Nicobar Islands", Value: "Andaman and Nicobar Islands" },
  { Text: "Andhra Pradesh", Value: "Andhra Pradesh" },
  { Text: "Arunachal Pradesh", Value: "Arunachal Pradesh" },
  { Text: "Assam", Value: "Assam" },
  { Text: "Bihar", Value: "Bihar" },
  { Text: "Chandigarh", Value: "Chandigarh" },
  { Text: "Chhattisgarh", Value: "Chhattisgarh" },
  { Text: "Dadra and Nagar Haveli", Value: "Dadra and Nagar Haveli" },

  { Text: "Daman and Diu", Value: "Daman and Diu" },
  { Text: "Delhi", Value: "Delhi" },

  {
    Text: "National Capital Territory of Delhi",
    Value: "National Capital Territory of Delhi",
  },
  { Text: "Goa", Value: "Goa" },
  { Text: "Gujarat", Value: "Gujarat" },
  { Text: "Haryana", Value: "Haryana" },
  { Text: "Himachal Pradesh", Value: "Himachal Pradesh" },
  { Text: "Jammu and Kashmir", Value: "Jammu and Kashmir" },
  { Text: "Jharkhand", Value: "Jharkhand" },
  { Text: "Karnataka", Value: "Karnataka" },
  { Text: "Kerala", Value: "Kerala" },

  { Text: "Lakshadweep", Value: "Lakshadweep" },
  { Text: "Madhya Pradesh", Value: "Madhya Pradesh" },
  { Text: "Maharashtra", Value: "Maharashtra" },
  { Text: "Manipur", Value: "Manipur" },
  { Text: "Meghalaya", Value: "Meghalaya" },
  { Text: "Mizoram", Value: "Mizoram" },
  { Text: "Nagaland", Value: "Nagaland" },
  { Text: "Odisha", Value: "Odisha" },
  { Text: "Puducherry", Value: "Puducherry" },
  { Text: "Punjab", Value: "Punjab" },
  { Text: "Rajasthan", Value: "Rajasthan" },

  { Text: "Sikkim", Value: "Sikkim" },
  { Text: "Tamil Nadu", Value: "Tamil Nadu" },
  { Text: "Telangana", Value: "Telangana" },
  { Text: "Tripura", Value: "Tripura" },
  { Text: "Uttar Pradesh", Value: "Uttar Pradesh" },
  { Text: "Uttarakhand", Value: "Uttarakhand" },
  { Text: "West Bengal", Value: "West Bengal" },
];

class New_address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      dinput: {},
      errors: {},
      address: "No",
      daddress: "No",
      method: "add",
      brandid:"",
      drandid:""
    };
    this.commonChange = this.commonChange.bind(this);
    this.dcommonChange = this.dcommonChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlecheckbox = this.handlecheckbox.bind(this);
  }
  componentDidMount() {
    var dinput = {};
    const { state } = this.props.location;
    if (state !== undefined) {
      dinput["dname"] = state.dinfo.fullname;
      dinput["dphone"] = state.dinfo.mobileno;
      dinput["demail"] = state.dinfo.email;
      dinput["dgst"] = state.dinfo.gstno;
      dinput["dpincode"] = state.dinfo.pincode;
      dinput["dflat"] = state.dinfo.houseno;
      dinput["darea"] = state.dinfo.area;
      dinput["dlandmark"] = state.dinfo.landmark;
      dinput["dcity"] = state.dinfo.town;
      dinput["dstate1"] = state.dinfo.state;
      dinput["dcountry"] = state.dinfo.country;
      this.setState({
        input: state.binfo,
        dinput: dinput,
        method: "edit",
        brandid:state.binfo.randid,
        drandid:state.dinfo.randid
      });
    }
  }
  commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }
  dcommonChange(event) {
    let dinput = this.state.dinput;
    dinput[event.target.name] = event.target.value;
    this.setState({
      dinput,
    });
  }

  handleChange(e) {
    var dinput = {};

    const target = e.target;
    

    if (target.checked) {
      dinput["dname"] = this.state.input.fullname;
      dinput["dphone"] = this.state.input.mobileno;
      dinput["demail"] = this.state.input.email;
      dinput["dgst"] = this.state.input.gstno;
      dinput["dpincode"] = this.state.input.pincode;
      dinput["dflat"] = this.state.input.houseno;
      dinput["darea"] = this.state.input.area;
      dinput["dlandmark"] = this.state.input.landmark;
      dinput["dcity"] = this.state.input.town;
      dinput["dstate1"] = this.state.input.state;
      dinput["dcountry"] = this.state.input.country;

      this.setState({
        dinput: dinput,
      });
    } else {
      dinput["dname"] = "";
      dinput["dphone"] = "";
      dinput["demail"] = "";
      dinput["dgst"] = "";
      dinput["dpincode"] = "";
      dinput["dflat"] = "";
      dinput["darea"] = "";
      dinput["dlandmark"] = "";
      dinput["dcity"] = "";
      dinput["dstate1"] = "";
      dinput["dcountry"] = "";

      this.setState({
        dinput: dinput,
      });
    }
  }
  validate() {
    let input = this.state.input;
    let dinput = this.state.dinput;
    let errors = {};
    let isValid = true;
    var regex = new RegExp(
      "^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$)"
    );
    var pattern = new RegExp(/^[0-9\b]+$/);
    var patterne = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["fullname"]) {
      isValid = false;
      errors["fullname"] = "Full Name is required";
    }
    if (!dinput["dname"]) {
      isValid = false;
      errors["dname"] = "Full Name is required";
    }
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Email is required";
    }
    if (!dinput["demail"]) {
      isValid = false;
      errors["demail"] = "Email is required";
    }
    if (typeof input["email"] !== "undefined") {
      if (!patterne.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid Email Id";
      }
    }
    if (typeof dinput["demail"] !== "undefined") {
      if (!patterne.test(dinput["demail"])) {
        isValid = false;
        errors["demail"] = "Please enter valid Email Id";
      }
    }
    if (!input["mobileno"]) {
      isValid = false;
      errors["mobileno"] = "Mobile Number is required";
    }
    if (!dinput["dphone"]) {
      isValid = false;
      errors["dphone"] = "Mobile Number is required";
    }
    if (typeof input["mobileno"] !== "undefined") {
      if (!pattern.test(input["mobileno"])) {
        isValid = false;
        errors["mobileno"] = "Please enter only number.";
      } else if (input["mobileno"].length !== 10) {
        isValid = false;
        errors["mobileno"] = "Invalid Mobile Number.";
      }
    }
    if (typeof dinput["dphone"] !== "undefined") {
      if (!pattern.test(dinput["dphone"])) {
        isValid = false;
        errors["dphone"] = "Please enter only number.";
      } else if (dinput["dphone"].length !== 10) {
        isValid = false;
        errors["dphone"] = "Invalid Mobile Number.";
      }
    }
    if (!input["gstno"]) {
      input["gstno"] = "";
      isValid = true;
    }
    if (!dinput["dgst"]) {
      dinput["dgst"] = "";
      isValid = true;
    }
    if (typeof input["gstno"] !== "undefined") {
      if (input["gstno"] === "") {
        isValid = true;
      } else if (!regex.test(input["gstno"])) {
        isValid = false;
        errors["gstno"] = "Invalid GST number";
      }
    }
    if (typeof dinput["dgst"] !== "undefined") {
      if (dinput["dgst"] === "") {
        isValid = true;
      } else if (!regex.test(dinput["dgst"])) {
        isValid = false;
        errors["dgst"] = "Invalid GST number";
      }
    }
    if (!input["pincode"]) {
      isValid = false;
      errors["pincode"] = "Pincode is required ";
    }
    if (!dinput["dpincode"]) {
      isValid = false;
      errors["dpincode"] = "Pincode is required ";
    }
    if (typeof input["pincode"] !== "undefined") {
      if (input["pincode"].length !== 6) {
        isValid = false;
        errors["pincode"] = "Invalid Pincode.";
      } else if (!pattern.test(input["pincode"])) {
        isValid = false;
        errors["pincode"] = "Please enter only number.";
      }
    }
    if (typeof dinput["dpincode"] !== "undefined") {
      if (dinput["dpincode"].length !== 6) {
        isValid = false;
        errors["dpincode"] = "Invalid Pincode.";
      } else if (!pattern.test(dinput["dpincode"])) {
        isValid = false;
        errors["dpincode"] = "Please enter only number.";
      }
    }
    if (!input["houseno"]) {
      isValid = false;
      errors["houseno"] = "House Number is required";
    }
    if (!dinput["dflat"]) {
      isValid = false;
      errors["dflat"] = "House Number is required";
    }
    if (!input["area"]) {
      isValid = false;
      errors["area"] = "Area is required";
    }
    if (!dinput["darea"]) {
      isValid = false;
      errors["darea"] = "Area is required";
    }
    if (!input["landmark"]) {
      isValid = false;
      errors["landmark"] = "Landmark is required";
    }
    if (!dinput["dlandmark"]) {
      isValid = false;
      errors["dlandmark"] = "Landmark is required";
    }
    if (!input["town"]) {
      isValid = false;
      errors["town"] = "Town/City is required";
    }
    if (!dinput["dcity"]) {
      isValid = false;
      errors["dcity"] = "Town/City is required";
    }
    if (!input["state"]) {
      isValid = false;
      errors["state"] = "State is required";
    }
    if (!dinput["dstate1"]) {
      isValid = false;
      errors["dstate1"] = "State is required";
    }
    if (!input["country"]) {
      isValid = false;
      errors["country"] = "Select Country";
    }
    if (!dinput["dcountry"]) {
      isValid = false;
      errors["dcountry"] = "Select Country";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }
  handlecheckbox(e) {
    const target = e.target;
    if (target.checked) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
    }
  }
  reset() {
    let input = {};
    input["fullname"] = "";
    input["email"] = "";
    input["mobileno"] = "";
    input["pincode"] = "";
    input["houseno"] = "";
    input["area"] = "";
    input["landmark"] = "";
    input["town"] = "";
    input["gstno"] = "";
    input["state"]="";
    input["country"]=""
    let dinput={};
      dinput["dname"] =    "";
      dinput["dphone"] =   "";
      dinput["demail"] =   "";
      dinput["dgst"] =     "";
      dinput["dpincode"] = "";
      dinput["dflat"] =    "";
      dinput["darea"] =    "";
      dinput["dlandmark"] = "";
      dinput["dcity"] =     "";
      dinput["dstate1"] =   "";
      dinput["dcountry"] =  "";

    this.setState({
      input: input,
    });
  }
  handlesubmit() {
    if (this.validate()) {
    
      let input = this.state.input;
      var Biliing = {
        country: input.country,
        fullname: input.fullname,
        mobileno: input.mobileno,
        pincode: input.pincode,
        landmark: input.landmark,
        town: input.town,
        state: input.state,
        houseno: input.houseno,
        area: input.area,
        addresstype: "",
        setasdefault: this.state.address,
        email: input.email,
        gstno: input.gstno,
        category: "Billing",
        method: this.state.method,
        randid:this.state.brandid
      };
      let dinput = this.state.dinput;
      var Delivery = {
        country: dinput.dcountry,
        fullname: dinput.dname,
        mobileno: dinput.dphone,
        pincode: dinput.dpincode,
        landmark: dinput.dlandmark,
        town: dinput.dcity,
        state: dinput.dstate1,
        houseno: dinput.dflat,
        area: dinput.darea,
        addresstype: "",
        setasdefault: this.state.daddress,
        email: dinput.demail,
        gstno: dinput.dgst,
        category: "Delivery",
        method: this.state.method,
        randid:this.state.drandid

      };
      
      var formBody = [];
      for (var property in Biliing) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(Biliing[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch(
        "https://api.emechmart.com/api/UserAccount/AddEditDeliveryAddress",
        requestOptions
      )
        .then((resp) => resp.json())
        .then((res) => {
         
        });
      var dformBody = [];
      for (var dproperty in Delivery) {
        var dencodedKey = encodeURIComponent(dproperty);
        var dencodedValue = encodeURIComponent(Delivery[dproperty]);
        dformBody.push(dencodedKey + "=" + dencodedValue);
      }
      dformBody = dformBody.join("&");
      const drequestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: dformBody,
      };
      fetch(
        "https://api.emechmart.com/api/UserAccount/AddEditDeliveryAddress",
        drequestOptions
      )
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
        });

      this.reset();
      this.props.history.push("/Delivery")
    }
  }
  render() {
    
    return (
      <div>
        <header>
          <div className="back-links">
            <a href="/Kart">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Add a new address </h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- address details start --> */}
        <section className="top-space pt-2">
          <div class="tab-section ">
            <ul
              class="nav nav-tabs theme-tab"
              style={{ "padding-left": "35px" }}
            >
              <li class="nav-item cust_style">
                <button
                  class="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#billing"
                  type="button"
                >
                  Billing Details
                </button>
              </li>
              <li class="nav-item cust_style">
                <button
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#delivery"
                  type="button"
                >
                  Delivery Details
                </button>
              </li>
            </ul>

            <div className="tab-content px-15">
              <div className=" tab-pane fade show active" id="billing">
                <div className="address-form-section px-15">
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="one"
                      name="fullname"
                      // defaultValue={state.binfo.fullname}
                      placeholder="Full Name"
                      value={this.state.input.fullname}
                      onChange={this.commonChange}
                    />
                    <label htmlFor="one">
                      Full Name/Company Name/Organization
                    </label>
                    <div className="text-danger">
                      {this.state.errors.fullname}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="two"
                      name="mobileno"
                      // defaultValue={state.binfo.mobileno}
                      placeholder="Mobile Number"
                      onChange={this.commonChange}
                      value={this.state.input.mobileno}
                    />
                    <label htmlFor="two">Mobile Number</label>
                    <div className="text-danger">
                      {this.state.errors.mobileno}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      id="eight"
                      name="email"
                      placeholder="Email id"
                      // defaultValue={state.binfo.email}
                      onChange={this.commonChange}
                      value={this.state.input.email}
                    />
                    <label htmlFor="eight">Email</label>
                    <div className="text-danger">{this.state.errors.email}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="nine"
                      name="gstno"
                      placeholder="GST No"
                      // defaultValue={state.binfo.gstno}
                      onChange={this.commonChange}
                      value={this.state.input.gstno}
                    />
                    <label htmlFor="nine">GST No.</label>
                    <div className="text-danger">{this.state.errors.gstno}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="number"
                      className="form-control"
                      id="three"
                      name="pincode"
                      placeholder="Pin Code"
                      // defaultValue={state.binfo.pincode}
                      onChange={this.commonChange}
                      value={this.state.input.pincode}
                    />
                    <label htmlFor="three">Pin Code</label>
                    <div className="text-danger">
                      {this.state.errors.pincode}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="four"
                      name="houseno"
                      placeholder="flat, house No., building.."
                      //defaultValue={state.binfo.houseno}
                      onChange={this.commonChange}
                      value={this.state.input.houseno}
                    />
                    <label htmlFor="four">flat, house No., building..</label>
                    <div className="text-danger">
                      {this.state.errors.houseno}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="five"
                      name="area"
                      placeholder="area, colony, street"
                      // defaultValue={state.binfo.area}
                      onChange={this.commonChange}
                      value={this.state.input.area}
                    />
                    <label htmlFor="five">area, colony, street</label>
                    <div className="text-danger">{this.state.errors.area}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="six"
                      placeholder="landmark"
                      name="landmark"
                      // defaultValue={state.binfo.landmark}
                      onChange={this.commonChange}
                      value={this.state.input.landmark}
                    />
                    <label htmlFor="six">landmark</label>
                    <div className="text-danger">
                      {this.state.errors.landmark}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="seven"
                      placeholder="town/city"
                      name="town"
                      //defaultValue={state.binfo.town}
                      onChange={this.commonChange}
                      value={this.state.input.town}
                    />
                    <label htmlFor="seven">town/city</label>
                    <div className="text-danger">{this.state.errors.town}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <select
                      className="form-select"
                      id="floatingSelect1"
                      aria-label="Floating label select example"
                      name="state"
                      // defaultValue={state.binfo.state}
                      onChange={this.commonChange}
                      value={this.state.input.state}
                    >
                      <option selected value="">
                        Select state
                      </option>
                      {statelist.map((list) => (
                        <option value={list.Value}>{list.Text}</option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelect1">
                      state/province/region
                    </label>
                    <div className="text-danger">{this.state.errors.state}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      name="country"
                      // defaultValue={state.binfo.country}
                      onChange={this.commonChange}
                      value={this.state.input.country}
                    >
                      <option selected value="">
                        Select country
                      </option>
                      {/* <option value="2">Austrlia</option> */}
                      <option value="India">India</option>
                    </select>
                    <label htmlFor="floatingSelect">Country/Region</label>
                    <div className="text-danger">
                      {this.state.errors.country}
                    </div>
                  </div>
                </div>
                {/* <div className="divider"></div> */}
                <div className="type-address px-15 ">
                  {/* <h2 className="page-title">Type of address</h2>
             <form className="d-flex">
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="Home"
                  value="male"
                />
                <label htmlFor="Home">Home</label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="office"
                  value="office"
                />
                <label htmlFor="office">Office</label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="others"
                  value="others"
                />
                <label htmlFor="others">Others</label>
              </div>
            </form> */}
                  <div className="checkbox_animated">
                    <div className="d-flex align-items-center mb-2">
                      <input
                        type="checkbox"
                        name="address"
                        id="ten"
                        value="Yes"
                        onChange={this.handlecheckbox}
                      />
                      <label htmlFor="ten">make default address </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" tab-pane fade" id="delivery">
                <div className="address-form-section px-15">
                  <div className="checkbox_animated">
                    <div className="d-flex align-items-center mb-2">
                      <input
                        type="checkbox"
                        name="same"
                        id="dten"
                        value="male"
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor="dten"
                        style={{
                          marginBottom: "14px",
                          marginLeft: "4px",
                        }}
                      >
                        Same as Biliing address
                      </label>
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="done"
                      name="dname"
                      placeholder="Full Name"
                      value={this.state.dinput.dname}
                      onChange={this.dcommonChange}
                    />
                    <label htmlFor="one">
                      Full Name/Company Name/Organization
                    </label>
                    <div className="text-danger">{this.state.errors.dname}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dtwo"
                      name="dphone"
                      placeholder="Mobile Number"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dphone}
                    />
                    <label htmlFor="two">Mobile Number</label>
                    <div className="text-danger">
                      {this.state.errors.dphone}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      id="deight"
                      name="demail"
                      placeholder="Email id"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.demail}
                    />
                    <label htmlFor="eight">Email</label>
                    <div className="text-danger">
                      {this.state.errors.demail}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dnine"
                      name="dgst"
                      placeholder="GST No"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dgst}
                    />
                    <label htmlFor="nine">GST No.</label>
                    <div className="text-danger">{this.state.errors.dgst}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="number"
                      className="form-control"
                      id="dthree"
                      name="dpincode"
                      placeholder="Pin Code"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dpincode}
                    />
                    <label htmlFor="three">Pin Code</label>
                    <div className="text-danger">
                      {this.state.errors.dpincode}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dfour"
                      name="dflat"
                      placeholder="flat, house No., building.."
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dflat}
                    />
                    <label htmlFor="four">flat, house No., building..</label>
                    <div className="text-danger">{this.state.errors.dflat}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dfive"
                      name="darea"
                      placeholder="area, colony, street"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.darea}
                    />
                    <label htmlFor="five">area, colony, street</label>
                    <div className="text-danger">{this.state.errors.darea}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dsix"
                      placeholder="landmark"
                      name="dlandmark"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dlandmark}
                    />
                    <label htmlFor="six">landmark</label>
                    <div className="text-danger">
                      {this.state.errors.dlandmark}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="dseven"
                      placeholder="town/city"
                      name="dcity"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dcity}
                    />
                    <label htmlFor="seven">town/city</label>
                    <div className="text-danger">{this.state.errors.dcity}</div>
                  </div>
                  <div className="form-floating mb-4">
                    <select
                      className="form-select"
                      id="dfloatingSelect1"
                      aria-label="Floating label select example"
                      name="dstate1"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dstate1}
                    >
                      <option selected value="">
                        Select state
                      </option>
                      {statelist.map((list) => (
                        <option value={list.Value}>{list.Text}</option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelect1">
                      state/province/region
                    </label>
                    <div className="text-danger">
                      {this.state.errors.dstate1}
                    </div>
                  </div>
                  <div className="form-floating mb-4">
                    <select
                      className="form-select"
                      id="dfloatingSelect"
                      aria-label="Floating label select example"
                      name="dcountry"
                      onChange={this.dcommonChange}
                      value={this.state.dinput.dcountry}
                    >
                      <option selected value="">
                        Select country
                      </option>
                      {/* <option value="2">Austrlia</option> */}
                      <option value="India">India</option>
                    </select>
                    <label htmlFor="floatingSelect">Country/Region</label>
                    <div className="text-danger">
                      {this.state.errors.dcountry}
                    </div>
                  </div>
                </div>
                {/* <div className="divider"></div> */}
                <div className="type-address px-15 ">
                  {/* <h2 className="page-title">Type of address</h2>
            <form className="d-flex">
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="Home"
                  value="male"
                />
                <label htmlFor="Home">Home</label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="office"
                  value="office"
                />
                <label htmlFor="office">Office</label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input
                  className="radio_animated"
                  type="radio"
                  name="gender"
                  id="others"
                  value="others"
                />
                <label htmlFor="others">Others</label>
              </div>
            </form> */}
                  {/* <div className="checkbox_animated">
                    <div className="d-flex align-items-center mb-2">
                      <input
                        type="checkbox"
                        name="daddress"
                        id="dsave"
                        value="Yes"
                        onChange={this.handlecheckbox}
                      />
                      <label htmlFor="dsave">Save this Address</label>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- address details end --> */}

        <section className="panel-space"></section>

        <div className="cart-bottom row m-0">
          <div>
            {/* <div className="left-content col-5">
              <a href="#" className="title-color">
                RESET
              </a>
            </div> */}
            <button class="w-100 btn btn-solid"
              onClick={(e) => this.handlesubmit()}
            >
              Add Address
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default New_address;
