import React, { useState, useEffect } from "react";
import { getToken } from "../firebaseInit.js";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  console.log("Token found", isTokenFound);
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);
        var token="Token="+encodeURIComponent(data);
        
         const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: token,
      };
       fetch("https://api.emechmart.com/api/Cart/InserNotificationTocken",requestOptions)
       .then((resp)=>resp.json())
       .then((res)=>{
         console.log(res);
       })
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);
  return <></>;
};
//Notifications.propTypes = {};
export default Notifications;
