import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
//import {Userlogin} from "./Helper";

class OTP extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
    };
    this.commonChange = this.commonChange.bind(this);
  }
  commonChange(event) {
    this.setState({
      otp: event.target.value,
    });
  }
  handleSubmit = (e) => {
   
    const { state } = this.props.location;
  
      
      var pdata={
        mobile_no:state.mobile_no,
        otp:this.state.otp,
        Method:state.Method,
        Old_mobile_no:state.Old_no
      }
 var formBody = [];
    for (var property in pdata) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(pdata[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    console.log(pdata);
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
         Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    
    fetch(
      "https://api.emechmart.com/api/UserAccount/RegisterOTP",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if(res.Success===true){
           toast.dismiss();
        toast.success(res.Message,{autoClose: 2000});
        this.setState({
          otp:""
        })
        if(state.Method==="Register"){
           this.gettoken()
        }
        else{
          localStorage.removeItem("U_Id");
           this.props.history.push("/Login")
        
        }
       
        }
        else{
          toast.dismiss();
        toast.error(res.Message,{autoClose: 2000});
        this.setState({
          otp:""
        })
        
        }
      });
  };

  resend(e){
     const { state } = this.props.location;
    var details =
      "mobile_no=" +
      encodeURIComponent(state.mobile_no) 
      const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: details,
    };
    fetch(
      "https://api.emechmart.com/api/UserAccount/ResendOTP",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if(res.Success===true){
           toast.dismiss();
        toast.success(res.Message,{autoClose: 2000});
         this.setState({
          otp:""
        })
        }
        else{
          toast.dismiss();
        toast.error(res.Message,{autoClose: 2000});
        this.setState({
          otp:""
        })
        }
       
      });
  }
gettoken(){
     const { state } = this.props.location;

  var details="userName=" + encodeURIComponent(state.email) +
    "&password=" + encodeURIComponent(state.password) +
    "&grant_type=password"

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
      body:details
      
    };
    fetch("https://api.emechmart.com/login",requestOptions)
    .then((response)=> response.json())
    .then((res)=>{
      let token = res.access_token;
      console.log(token);
      if(token === undefined){ 
        //  alert("please enter Valid Details")
  
        // toast.warning('Please enter Valid Details')
           toast.dismiss();
        // toast.error(res.error_description,{autoClose: 2000});
        
      }
      else{   
        toast.dismiss();
        // toast.success("API SUCCESS Done",{autoClose:2000});
        localStorage.setItem("U_Id",res.access_token);   
    const cookies=new Cookies();
    const cartdata=cookies.get("local");
    if(cartdata===undefined){

    }
    else{
      for(var i=0;i<cartdata.length;i++){  
var pdata = {
          product_id: cartdata[i].product_id,
          product_price: cartdata[i].Cart_Price,
          product_discount:cartdata[i].discountprice,
          product_qty: cartdata[i].product_qty,
          variations:cartdata[i].variation,
        };
        console.log(pdata);
        // localStorage.setItem('Detail',JSON.stringify(localcart))
        var formBody = [];
        for (var property in pdata) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(pdata[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.getItem("U_Id"),
          },
          body: formBody,
        };
        fetch("https://api.emechmart.com/api/Cart/AddToCart", requestOptions)
          .then((response) => response.json())
          .then((resp) => {
            console.log(resp);
            
           
            
          });
}    
    }

  this.props.history.push("/");
      }       
    })
}
  render() {
    return (
      <div>
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}
{/* <ToastContainer Colored theme='colored' closeButton={false} /> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* 
    <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>OTP send to your mobile</h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                placeholder="Address"
                name="otp"
                onChange={this.commonChange}
                value={this.state.otp}
              />
              <label htmlFor="one">OTP</label>
            </div>
            <div>
            <button
              className="btn btn-solid w-100"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              onClick={(e)=>this.handleSubmit()}
            >
              Verify
            </button>
            </div>
            <div style={{"padding-top":"20px"}}>
             <button
              className="btn btn-solid w-100"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              onClick={(e)=>this.resend()}
            >
              Resend OTP
            </button>
            </div>
          </div>
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">
              Back to{" "}
              <a className="title-color text-decoration-underline" href="/Login">
                Sign In
              </a>
            </h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>

        {/* <!-- otp canvas start --> */}
        
      </div>
    );
  }
}
export default OTP;
