import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import Loader from "react-js-loader";
import Iconmenu from "./iconmenu.js";
import Hearticon from "./Hearticon.js";
// import { ToastContainer, toast } from "react-toastify";
import { Discount } from "./Helper";
import MultiRangeSlider from "./multirangeslider";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";
import "./assets/css/vendors/pricing-slider.css";
// import "./assets/js/pricing-slider.js";

const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Shop extends Component {
  data = { id: "" };
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      tittle: "",
      Brands: [],
      checkedItems: [],
      pricedrop: "",
      ratedrop: "",
      discdrop: "",
      msg: true,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.commonChange = this.commonChange.bind(this);
  }
  componentDidMount() {
    const { state } = this.props.location;

    var content = state.split("/");

    if (content !== undefined) {
      var param = {
        GroupUrl: content[0],
        SubGroupUrl: content[1],
        CategoryUrl: content[2],
      };
      console.log(param);
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formBody,
      };
      fetch(
        "https://api.emechmart.com/api/Product/GetShopedata",
        requestOptions
      )
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          this.setState({
            Data: res.Data,
            tittle: res.page_title,
            loading: false,
          });
        });
    }
  }
  commonChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange(e) {
    const target = e.target;
    var value = target.value;

    if (target.checked) {
      this.state.checkedItems.push(value);
    } else {
      const found = (element) => element === value;
      // console.log(this.state.checkedItems.findIndex(found));
      this.state.checkedItems.splice(
        this.state.checkedItems.findIndex(found),
        1
      );
    }

    // do whatever you want with isChecked value
  }
  applyfilter(e) {
    var minvalue1 = $("#minvalue").text();
    var maxvalue1 = $("#maxvalue").text();
    const { state } = this.props.location;
    var content = state.split("/");
    //  console.log(content)
    var colorsCSV = this.state.checkedItems.join(",");
    // console.log(colorsCSV);

    var param = {
      GroupUrl: content[0],
      SubGroupUrl: content[1],
      CategoryUrl: content[2],
      Brands: colorsCSV,
      OrderbyPrice: this.state.pricedrop,
      Ratting: this.state.ratedrop,
      // Discount: this.state.discdrop,
      From_Price: minvalue1,
      To_Price: maxvalue1,
    };

    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Product/GetShopedata", requestOptions)
      .then((resp) => resp.json())
      .then((res) => {
        let Success = res.Success;
        if (Success === true) {
          this.setState({
            Data: res.Data,
            msg: Success,
          });
        } else {
          this.setState({
            msg: Success,
          });
        }
      });
  }
  search() {
    this.props.history.push("/Search");
  }

  getBrands(e) {
    fetch("https://api.emechmart.com/api/Product/GetBrand")
      .then((response) => response.json())
      .then((resp) => {
        // console.log(resp);
        this.setState({
          Brands: resp.Data,
        });
      });
  }

  render() {
    const { Data, tittle, Brands, msg, loading } = this.state;
    const { state } = this.props.location;

    const body = () => {
      if (
        state === undefined ||
        state.length === 0 ||
        msg !== true ||
        Data === null
      ) {
        return (
          <section className="px-15">
            <div className="empty-cart-section text-center">
              <img
                src="assets/images/empty-cart.png"
                className="img-fluid"
                alt=""
              />
              <h2>Whoops !! No products found</h2>
              {/* <a href="/" className="btn btn-solid w-100">start shopping</a> */}
            </div>
          </section>
        );
      } else {
        return (
          <section className="px-15 lg-t-space">
            <div className="row gy-3 gx-3">
              {Data.map((Data) => (
                <div className="col-6 text-center" key={Data.randid}>
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <Link to={{ pathname: "/Product", state: Data.randid }}>
                        <img
                          src={Data.productimage}
                          alt=""
                          className="img-fluid bg-img"
                          style={{ width: "70%", padding: "20px" }}
                        />
                      </Link>
                      <div className="wishlist-btn">
                        <Hearticon dataFromParent={Data.product_id} />
                      </div>
                      {/* <label>new</label> */}
                    </div>
                    <Link to={{ pathname: "/Product", state: Data.randid }}>
                      <div className="product-content star-center">
                        <ul className="ratings reactstar">
                          <ReactStars
                            count={5}
                            edit={false}
                            value={parseFloat(Data.product_star)}
                            size={18}
                            char={<i className="iconly-Star icbo"></i>}
                            color="#b7babe"
                            activeColor="#007744"
                          />
                        </ul>
                        <a href="/Product">
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: Data.product_name,
                            }}
                          ></h4>
                        </a>
                        <div className="price">
                          <h4>
                            {"\u20B9"}
                            {Data.Product_Price} <del>{Data.mrp}</del>
                            <span>
                              {Discount(Data.mrp, Data.Product_Price)}%
                            </span>
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}

              {/* <div className="col-6">
        <div className="product-box ratio_square">
          <div className="img-part">
            <a href="/Product"><img alt="" src="assets/images/products/8.jpg" className="img-fluid bg-img"/></a>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
          <div className="product-content">
            <ul className="ratings">
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo empty"></i></li>
            </ul>
            <a href="/Product">
              <h4>Blue Denim Jacket</h4>
            </a>
            <div className="price">
              <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
            </div>
          </div>
        </div>
      </div>
     */}
            </div>
          </section>
        );
      }
    };
    return loading ? (
      <Loading />
    ) : (
      <div>
        {/* <ToastContainer
          hideProgressBar
          Colored
          theme="colored"
          closeButton={false}
        /> */}

        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>{tittle}</h2>
                {/* <h6></h6> */}
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              {/* <li>
                <a href="/Search">
                  <i className="iconly-Search icli"></i>
                </a>
              </li> */}
              <li>
                <a href="/Wishlist">
                  <i className="iconly-Heart icli"></i>
                </a>
              </li>
              <li>
                <Iconmenu />
              </li>
            </ul>
          </div>
        </header>

        {/* <!-- search panel start --> */}

        <div className="search-panel top-space px-15">
          <div className="search-bar" onClick={(e) => this.search()}>
            <input
              className="form-control form-theme"
              placeholder="Search"
              onClick={(e) => this.search()}
            />
            <i
              className="iconly-Search icli search-icon"
              onClick={(e) => this.search()}
            ></i>
          </div>
          <div
            className="filter-btn"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            onClick={(e) => this.getBrands()}
          >
            <i className="iconly-Filter icli"></i>
          </div>
        </div>
        {/* <!-- search panel end --> */}

        {/* <!-- products start --> */}
        {body()}

        {/* <!-- products end --> */}

        {/* <!--  filter modal start  --> */}
        <div
          className="modal filter-modal"
          id="filterModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Filters</h2>
                <a href={null} data-bs-dismiss="modal">
                  <img
                    src="assets/svg/x-dark.svg"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="modal-body">
                <div className="filter-box">
                  <h2 className="filter-title">Sort By Price:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="pricedrop"
                      onChange={this.commonChange}
                      defaultValue=""
                    >
                      <option value="">Sort By Price</option>
                      <option value="price,desc">Price: High to Low</option>
                      <option value="price,asc">Price: Low to High</option>
                    </select>
                  </div>
                </div>
                {/* <div className="filter-box">
                  <h2 className="filter-title">Sort By Date:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      
                    >
                      <option selected>Recommended</option>
                      <option value="1">Newest First</option>
                      <option value="2">Oldest First</option>
                    </select>
                  </div>
                 </div> */}
                <div className="filter-box">
                  <h2 className="filter-title">Brand:</h2>
                  <div className="filter-content">
                    {Brands.map((data) => (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="hobbies"
                          value={data.Brandid}
                          onChange={this.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={data.Brandid}
                        >
                          {data.BrandName}
                        </label>
                      </div>
                    ))}
                    {/* <li className="col-6 active" >
                        <div className="filter-col">{data.BrandName}</div>
                      </li> */}
                    {/* <li className="col-6">
                        <div className="filter-col">Zara</div>
                      </li>
                      <li className="col-6 active">
                        <div className="filter-col">Mast & harbour</div>
                      </li>
                      <li className="col-6">
                        <div className="filter-col">Tokyo talkies</div>
                      </li>
                      <li className="col-6">
                        <div className="filter-col">Vogue</div>
                      </li>
                      <li className="col-6">
                        <div className="filter-col">gucci</div>
                      </li> */}
                  </div>
                </div>
                <div className="filter-box">
                  <h2 className="filter-title">Sort By Ratigs:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="ratedrop"
                      onChange={this.commonChange}
                      defaultValue=""
                    >
                      <option value="">Select Ratings</option>
                      <option value="1,5">1-5</option>
                      <option value="2,5">2-5</option>
                      <option value="3,5">3-5</option>
                      <option value="4,5">4-5</option>
                    </select>
                  </div>
                </div>
                {/* <div className="filter-box">
                  <h2 className="filter-title">Size:</h2>
                  <div className="filter-content">
                    <ul className="row filter-row g-3">
                      <li className="col-4">
                        <div className="filter-col">small</div>
                      </li>
                      <li className="col-4">
                        <div className="filter-col">Medium</div>
                      </li>
                      <li className="col-4">
                        <div className="filter-col">large</div>
                      </li>
                      <li className="col-4">
                        <div className="filter-col">XL</div>
                      </li>
                      <li className="col-4">
                        <div className="filter-col">2XL</div>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="filter-box">
                  <h2 className="filter-title">Price:</h2>
                  <MultiRangeSlider
                    min={0}
                    max={50000}
                    onChange={({ min, max }) =>
                      console.log(`min = ${min}, max = ${max}`)
                    }
                  />

                  {/* <div className="filter-content">
                    <div className="range-slider pricing-slider">
                      <input type="text" className="js-range-slider" value="" />
                    </div>
                  </div> */}
                </div>
                <div className="filter-box">
                  <h2 className="filter-title">Sort By Discount:</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="discdrop"
                      onChange={this.commonChange}
                      defaultValue=""
                    >
                      <option value="">Select Discount</option>
                      <option value="0,10">0%-10%</option>
                      <option value="10,25">10%-25%</option>
                      <option value="25,50">25%-50%</option>
                      <option value="50,60">50%-60%</option>
                      <option value="60,80">60%-80%</option>
                    </select>
                  </div>
                </div>
            
              </div>
              <div className="modal-footer">
                <a href={null} className="reset-link" data-bs-dismiss="modal">
                  RESET
                </a>
                <a
                  href={null}
                  className="btn btn-solid"
                  data-bs-dismiss="modal"
                  onClick={(e) => this.applyfilter()}
                >
                  apply filters
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- filter modal end --> */}

        <section className="panel-space"></section>

        <div className="bottom-panel">
          <ul>
            <li>
              <a href="/">
                <div className="icon">
                  <i className="iconly-Home icli"></i>
                  <i className="iconly-Home icbo"></i>
                </div>
                <span>home</span>
              </a>
            </li>
            <li>
              <a href="/Cat">
                <div className="icon">
                  <i className="iconly-Category icli"></i>
                  <i className="iconly-Category icbo"></i>
                </div>
                <span>category</span>
              </a>
            </li>
            <li>
              <a href="/Kart">
                <div className="icon">
                  <i className="iconly-Buy icli"></i>
                  <i className="iconly-Buy icbo"></i>
                </div>
                <span>cart</span>
              </a>
            </li>
            <li>
              <a href="/Wishlist">
                <div className="icon">
                  <i className="iconly-Heart icli"></i>
                  <i className="iconly-Heart icbo"></i>
                </div>
                <span>wishlist</span>
              </a>
            </li>
            <li>
              <a href="/Profile">
                <div className="icon">
                  <i className="iconly-Profile icli"></i>
                  <i className="iconly-Profile icbo"></i>
                </div>
                <span>profile</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default Shop;
