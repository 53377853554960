/* eslint-disable no-undef */
import React from 'react';
import { ToastContainer, toast } from "react-toastify";





export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
      console.log(this.state);
     if (this.state.error && this.state.errorInfo  ) {
       toast.error("Something went wrong.",{autoClose:3000})
window.location.href="/"
      // Error path
      return (
       <div>
       <ToastContainer
              hideProgressBar
              Colored
              theme="colored"
              closeButton={false}
            />
       <h1>Something went wrong.</h1>
        {/* <p>Caught an Error: {this.state.error.toString()}</p> */}
        <div>
          {/* {this.state.errorInfo.componentStack} */}
        </div>
      </div>
      );
    }
    // Normally, just render children
    // else{
        return this.props.children;
    // }
    
  }  
}
  