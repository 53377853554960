import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
// import { withRouter } from 'react-router-dom'
import "./assets/css/style.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      errors: {},
    };
    this.commonChange = this.commonChange.bind(this);
  }

  commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  reset() {
    let input = {};
    input["email"] = "";
    input["password"] = "";
    this.setState((currentState) => ({
      input: input,
    }));
  }
  handleSubmit = (e) => {
    if (this.validate()) {
      var details =
        "userName=" +
        encodeURIComponent(this.state.input.email) +
        "&password=" +
        encodeURIComponent(this.state.input.password) +
        "&grant_type=password";
     console.log(details);
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: details,
      };
      fetch("https://api.emechmart.com/login", requestOptions)
        .then((response) => response.json())
        .then((res) => {
          let token = res.access_token;
          
          if (token === undefined) {
            //  alert("please enter Valid Details")

            // toast.warning('Please enter Valid Details')
            toast.dismiss()
            toast.error(res.error_description, { autoClose: 2000 });
            this.reset();
          } else {
           
            toast.dismiss()
            toast.success("Login Successfully", { autoClose: 2000 });
            localStorage.setItem("U_Id", res.access_token);
            const cookies = new Cookies();
            const cartdata = cookies.get("local");
            if (cartdata === undefined) {
             
               this.props.history.push("/");
            } else {

              for (var i = 0; i < cartdata.length; i++) {
                var pdata = {
                  product_id: cartdata[i].product_id,
                  product_price: cartdata[i].Cart_Price,
                  product_discount: cartdata[i].discountprice,
                  product_qty: cartdata[i].product_qty,
                  variations: cartdata[i].variation,
                };
                
                // localStorage.setItem('Detail',JSON.stringify(localcart))
                var formBody = [];
                for (var property in pdata) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(pdata[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
                }
                formBody = formBody.join("&");
                // Simple POST request with a JSON body using fetch
                const requestOptions = {
                  method: "POST",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + localStorage.getItem("U_Id"),
                  },
                  body: formBody,
                };
                fetch(
                  "https://api.emechmart.com/api/Cart/AddToCart",
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((resp) => {
                    let Success = resp.Success;
                    console.log(Success);
                  });
              }
            }
            this.props.history.push("/");
          }
        });
    }
  };

  render() {
   
    return (
      <div>
        {/* <!-- loader strat -->
    <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}

        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />

        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>

        {/* <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>
            Hey, <br />
            Login Now
          </h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="one"
                name="email"
                placeholder="Email"
                value={this.state.input.email}
                onChange={this.commonChange}
                required={true}
              />
              <label htmlFor="one">Email</label>
              <div className="text-danger">{this.state.errors.email}</div>
            </div>
            <div className="form-floating mb-2">
              <input
                type="password"
                className="form-control"
                id="two"
                name="password"
                value={this.state.input.password}
                placeholder="password"
                onChange={this.commonChange}
                required
              />
              <label htmlFor="two">Password</label>
              <div className="text-danger">{this.state.errors.password}</div>
            </div>
            <div className="text-end mb-4">
              <a href="/Forgot" className="theme-color">
                Forgot Password ?
              </a>
            </div>
            <button
             
              className="btn btn-solid w-100"
              onClick={(e) => this.handleSubmit()}
            >
              Sign in
            </button>
            {/* <ToastContainer Colored theme="colored" closeButton={false}  autoClose={4000}/> */}
          </div>
          {/* <div className="or-divider">
            <span>Or sign in with</span>
          </div> */}
          {/* <div className="social-auth">
            <ul>
              <li>
                <a href="#">
                  <img
                    src="assets/images/social/google.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/images/social/fb.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </li>
              <li className="apple-icon">
                <a href="#">
                  <img
                    src="assets/images/social/apple.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div> */}
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">
              If you are new,{" "}
              <a
                className="title-color text-decoration-underline"
                href="/Register"
              >
                Create Now
              </a>
            </h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
      </div>
    );
  }
}
export default Login;
