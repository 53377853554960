/* eslint-disable no-undef */
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7c1eiRAg0JsroZVu2YbPYqkCWqmdsg9A",
  authDomain: "emechmart.firebaseapp.com",
  projectId: "emechmart",
  storageBucket: "emechmart.appspot.com",
  messagingSenderId: "892893982265",
  appId: "1:892893982265:web:16e773a47fd4458affa7e8",
  measurementId: "G-3DECVRMKW4"
};
firebase.initializeApp(firebaseConfig);
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const messaging = firebase.messaging();
const { REACT_APP_VAPID_KEY } = process.env.REACT_APP_VAPID_KEY;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
  return currentToken;
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
