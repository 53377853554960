import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import imageToBase64 from 'image-to-base64/browser';
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";
//import { Userlogin,Onupload} from "./Helper";

const statelist = [
  { Text: "Andaman and Nicobar Islands", Value: "Andaman and Nicobar Islands" },
  { Text: "Andhra Pradesh", Value: "Andhra Pradesh" },
  { Text: "Arunachal Pradesh", Value: "Arunachal Pradesh" },
  { Text: "Assam", Value: "Assam" },
  { Text: "Bihar", Value: "Bihar" },
  { Text: "Chandigarh", Value: "Chandigarh" },
  { Text: "Chhattisgarh", Value: "Chhattisgarh" },
  { Text: "Dadra and Nagar Haveli", Value: "Dadra and Nagar Haveli" },

  { Text: "Daman and Diu", Value: "Daman and Diu" },
  { Text: "Delhi", Value: "Delhi" },

  {
    Text: "National Capital Territory of Delhi",
    Value: "National Capital Territory of Delhi",
  },
  { Text: "Goa", Value: "Goa" },
  { Text: "Gujarat", Value: "Gujarat" },
  { Text: "Haryana", Value: "Haryana" },
  { Text: "Himachal Pradesh", Value: "Himachal Pradesh" },
  { Text: "Jammu and Kashmir", Value: "Jammu and Kashmir" },
  { Text: "Jharkhand", Value: "Jharkhand" },
  { Text: "Karnataka", Value: "Karnataka" },
  { Text: "Kerala", Value: "Kerala" },

  { Text: "Lakshadweep", Value: "Lakshadweep" },
  { Text: "Madhya Pradesh", Value: "Madhya Pradesh" },
  { Text: "Maharashtra", Value: "Maharashtra" },
  { Text: "Manipur", Value: "Manipur" },
  { Text: "Meghalaya", Value: "Meghalaya" },
  { Text: "Mizoram", Value: "Mizoram" },
  { Text: "Nagaland", Value: "Nagaland" },
  { Text: "Odisha", Value: "Odisha" },
  { Text: "Puducherry", Value: "Puducherry" },
  { Text: "Punjab", Value: "Punjab" },
  { Text: "Rajasthan", Value: "Rajasthan" },

  { Text: "Sikkim", Value: "Sikkim" },
  { Text: "Tamil Nadu", Value: "Tamil Nadu" },
  { Text: "Telangana", Value: "Telangana" },
  { Text: "Tripura", Value: "Tripura" },
  { Text: "Uttar Pradesh", Value: "Uttar Pradesh" },
  { Text: "Uttarakhand", Value: "Uttarakhand" },
  { Text: "West Bengal", Value: "West Bengal" },
];

class Profile_setting extends Component {
  
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      gender: state.gender,
      city: state.city,
      pincode:state.pincode,
      country:state.country,
      state1: state.state,
      dob: state.dateofbirth,
      home: state.homeaddress,
      office: state.officeaddress,
      selectedFile: state.uploadphoto,
      ImageName:state.ImageName,
      randid:state.randid,
      hiddenFile:"",
      errors:{}
    };

    this.commonChange = this.commonChange.bind(this);
  }
  commonChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  fileChangedHandler = (event) =>  {
    const {state}=this.props.location;
  this.setState({ selectedFile: URL.createObjectURL(event.target.files[0]) })
  console.log(event.target.files[0].name)
  imageToBase64(URL.createObjectURL(event.target.files[0])) 
    .then(
        (response) => {
          this.setState({
            hiddenFile:response,
            ImageName:event.target.files[0].name

          })
         
        console.log(this.state.hiddenFile)
        
 });
    
}
validate(){
  const{pincode}=this.state
  let errors = {};
   let isValid = true;
   
  if(pincode!==""){
        if (pincode.length !== 6) {
        isValid = false;
        errors["pincode"] = "Invalid Pincode.";
    }
  }
   this.setState({
      errors: errors,
    });
    return isValid;

}
  updatdata(e) {

     if (this.validate()) {
    var details = {
      gender: this.state.gender,
      homeaddress: this.state.home,
      officeaddress: this.state.office,
      pincode: this.state.pincode,
      country: this.state.country,
      state: this.state.state1,
      dateofbirth: this.state.dob,
      city:this.state.city,
      ImageName:this.state.ImageName,
      randid:this.state.randid,
      hiddenFile:this.state.hiddenFile
    };
    console.log(details);
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch(
      "https://api.emechmart.com/api/UserAccount/EditProfile",
      requestOptions
    )
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        let Success=res.Success
        if(Success ===true){
          toast.dismiss()
          toast.success("Profile Updated Successfully",{aotoClose:2000});
        }
        else{
          toast.dismiss()
          toast.error("Failed to update",{aotoClose:2000})
        }
      });
     }  

  }

  render() {
    const { state } = this.props.location;
    console.log(state.ImageName);
   

    
    
    return (
      <div>
        {/* <div className="loader">
          <span></span>
          <span></span>
        </div> */}
 {/* <ToastContainer Colored theme='colored' closeButton={false} autoClose={2000} /> */}
        <header>
          <div className="back-links">
            <a href="/Profile">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Profile Setting</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- user avtar section --> */}
        <section className="user-avtar-section top-space pt-0 px-15">

           <img
            src={this.state.ImageName===""?"assets/images/user/1.png":this.state.selectedFile}
            alt="No_Image"
            htmlFor="cropzee-input"
            data-cropzee="cropzee-input"
            id="image"
            className="img-fluid Edit-profile-profile_photo"
          />
          <span className="edit-icon">
            <input type="hidden" name="hiddenFile" id="cropImage" />
            <input
              type="file"
              id="cropzee-input"
              accept="image/*"
              name="file"
              onChange={this.fileChangedHandler}
              className="iconly-Edit-Square icli  edit-profile-file"
              style={{ "font-size": "22px", color: "green" }}
            />
          </span>
        </section>
        {/* <!-- user avtar end --> */}

        {/* <!-- detail form start --> */}
        <section className="detail-form-section px-15">
          <h2 className="page-title mb-4">Personal Details</h2>
          <form>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                placeholder="First Name"
                value={state.fullname}
              />
              <label htmlFor="one">Full Name</label>
              <a className="change-btn" href="/Rename">
                change
              </a>
            </div>
            <div className="form-floating mb-4">
              <input
                type="number"
                className="form-control"
                id="six"
                value={state.mobileno}
                placeholder="Mobile Number"
              />
              <label htmlFor="six">Mobile Number</label>
              <a className="change-btn" href="/Reset_phone">
                change
              </a>
            </div>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="seven"
                value={state.email}
                placeholder="Password"
              />
              <label htmlFor="seven">Email</label>
              <a className="change-btn" href="/Reset_email">
                change
              </a>
            </div>
            {/* <div className="form-floating mb-4"> */}
             <a href="/Reset_password" className="btn btn-outline text-capitalize w-100 ">Change Password</a>
            {/* </div> */}
          </form>
        </section>
        <div className="divider"></div>
        <section className="detail-form-section pt-0 px-15">
          <form className="pt-cls">
            <div className="form-floating mb-4">
              <input
                type="date"
                className="form-control"
                id="two"
                name="dob"
                placeholder="Date of Birth"
                defaultValue={state.dateofbirth}
                onChange={this.commonChange}
              />
              <label htmlFor="two">Date of Birth</label>
            </div>
            <div className="form-floating mb-4">
              <select
                className="form-select"
                id="floatingSelect"
                onChange={this.commonChange}
                defaultValue={state.gender}
                name="gender"
              >
              {/* <option selected value={state.gender} >
                  {state.gender}
                </option> */}
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                {/* <option value="3">other</option> */}
              </select>
              <label htmlFor="floatingSelect">Gender</label>
            </div>
          </form>
        </section>
        <div className="divider"></div>
        <section className="detail-form-section pt-0 px-15">
          {/* <div className="address-form-section px-15"> */}
          <div className="form-floating mb-4">
            <select
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              onChange={this.commonChange}
              defaultValue={state.country}
              name="country"
            >
              {/* <option selected value="India">
                India
              </option> */}
              {/* <option value="Austrlia">Austrlia</option> */}
              <option value="India">India</option>
            </select>
            <label htmlFor="floatingSelect">Country/Region</label>
          </div>
          <div className="form-floating mb-4">
            <select
              className="form-select"
              id="floatingSelect1"
              aria-label="Floating label select example"
              defaultValue={state.state}
              onChange={this.commonChange}
              name="state1"
            >
              {statelist.map((list) => (
                <option value={list.Value} >
                  {list.Text}
                </option>
              ))}
              {/* <option value="2">state</option>
              <option value="3">state</option> */}
            </select>
            <label htmlFor="floatingSelect1">state/province/region</label>
          </div>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="nine"
              placeholder="town/city"
              name="city"
              defaultValue={state.city}
              onChange={this.commonChange}
            />
            <label htmlFor="nine">town/city</label>
          </div>
          <div className="form-floating mb-4">
            <input
              type="number"
              className="form-control"
              id="three"
              placeholder="Pin Code"
              name="pincode"
              defaultValue={state.pincode}
              onChange={this.commonChange}
            />
            <label htmlFor="three">Pin Code</label>
            <div className="text-danger">
                      {this.state.errors.pincode}
                    </div>
          </div>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="four"
              placeholder="flat, house No., building.."
              name="home"
              defaultValue={state.homeaddress}
              onChange={this.commonChange}
            />
            <label htmlFor="four">AddressLine 1</label>
          </div>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="five"
              placeholder="area, colony, street"
              name="office"
              defaultValue={state.officeaddress}
              onChange={this.commonChange}
            />
            <label htmlFor="five">AddressLine 2</label>
          </div>
          {/* </div> */}
        </section>
        {/* <!-- detail form end --> */}

        <section className="panel-space"></section>

        <div className="cart-bottom row m-0">
          <div>
            <div className="left-content col-5">
              <a href="/Profile" className="title-color">
                CANCEL
              </a>
            </div>
            <button
              className="btn btn-solid col-7 text-uppercase"
              onClick={(e) => this.updatdata()}
            >
              Save Details
            </button>
           
          </div>
        </div>
      </div>
    );
  }
}
export default Profile_setting;
