import React from "react";
//importScripts("https://checkout.razorpay.com/v1/checkout.js");
//import axios from "axios";



export default class Razor extends React.Component {

 

  componentDidMount() {
    const { state } = this.props.location;

    var options = {
      key: state.apikey,
      amount: state.amount, // 2000 paise = INR 20, amount in paisa
      name: state.name,
      description: "",
      order_id: state.orderid,
      "modal": {
        "ondismiss": function () {
         window.location.replace("https://m.emechmart.com/Cancel_order");
        }
    },
      "handler": function (response) {
        console.log(response);
  
        var values = {
          payment_status: "Success",
          signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_paymentid: response.razorpay_payment_id,
          orderid: state.eid,
        };
        console.log(values);
        var formBody = [];
        for (var property in values) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(values[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        const headers = {
          method: "POST",
          mode: "cors",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            authorization: "Bearer " + localStorage.getItem("U_Id"),
          },
          body: formBody,
        };
        fetch("https://api.emechmart.com/api/Order/CapturePayment", headers)
          .then((resp) => resp.json())
          .then((res) => {
            console.log(res);
            
            if (res.Success === true) {

              localStorage.setItem("order",JSON.stringify(res.Data));
              window.location.href = "https://m.emechmart.com/Order_placed";
              // this.props.history.push({pathname:"http://localhost:3000/Order_placed",state:res.Data})
            }
          })
          .catch((e) => console.log(e));
      },
      prefill: {
        name: state.name,
      },
      notes: {
        address: "Hello World",
      },
      theme: {
        color: "#528ff0",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function  SendFailedResponse(response) {

 console.log(response);
    console.log(response.error.metadata.order_id);
 
    // if (response !== undefined) { 
      
      var data = {
        payment_status: "Failed",
        error: response.error.code,
        error_reason: response.error.reason,
        pOrderid:state.eid,
        razorpay_orderid:response.error.metadata.order_id,
        razorpay_paymentid:response.error.metadata.payment_id
      };
      
      console.log(data);
      var formBody = [];
      for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const headers = {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch("https://api.emechmart.com/api/Order/CapturePayment", headers)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          if (res.Success === "success") {

          } else {
            alert("error");
          }
        });
    // }


      
    });
    

    //  });
  }

  

  render() {
    
    return (
      <div id="loader">
     
      </div>
    );
  }
}
