/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";
import "./assets/css/style.css";
class About extends Component {
  render() {
    return (
      <div classNameName="parent">
        {/* <!-- loader strat --> */}
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
        {/* <!-- loader end --> */}

        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>about us</h2>
              </div>
            </a>
          </div>
        </header>

        <section className="px-15 top-space pt-0 about-section">
          <h2 className="fw-bold mb-2">Introduction</h2>
          <div className="help-img">
          <div class="row g-3 brand-section">
            <div class="col-6">
                <a class="brand-box22" href="#">
                    <img src="assets/images/logo.png" class="img-fluid" alt=""/>
                </a>
            </div>
            <div class="col-6">
                <a class="brand-box22" href="#">
                    <img src="assets/images/TNK logo.jpg" class="img-fluid" alt=""/>
                </a>
            </div>
            </div>
          </div>
          {/* <h4 className="mb-2",content-color>
            Multikart is premier fashion destination for the latest trends and
            hottest styles.
          </h4> */}
          <p className="my-2">
            Emechmart is an ecommerce venture of N & T Engitech Pvt. Ltd. ( TNK
            group of companies), an ISO and CE Certified Company engaged in
            Supply of Equipment’s and Spares for Edible Oil Refinery and related
            plants & projects. N & T Engitech Pvt. Ltd. ( TNK group of
            companies) is formed by a Technocrat having an experience in the
            edible oil industry field for more than 15 years. N & T Engitech
            Pvt. Ltd. ( TNK group of companies) is one of the most trusted
            suppliers of equipment’s and spares related to Edible oil industry
            and other concerning projects; known for its excellence in quality
            as well as a strong customer support. On Emechmart sellers are can
            register themselves to sell their products, and also useful for the
            potential interested buyers; delivering a satisfying experience.
          </p>
          <p className="mb-2">
            Emechmart is an e-commerce platform which intensively inclined
            towards B2B procurement of industrial and domestics Tools,
            Couplings, Chains, Welding Rod, Valves, Gaskets, Lubricants
            Products, Sprockets, Nuts & Bolts, Pulleys, Pipe Fittings, Gland
            Ropes, Paints, Steam Trap, Cables, Electrical hardware, Switchgears,
            Motors, Steel Materials, Sanitary Bath Wave, Sanitary wares,
            Hardware items, Furniture Fittings, Roofing Sheets, Gauges, Meters,
            Actuators, Laboratory Equipment's, Fire Hydrant Systems, Downcomer
            System, Fire Alarm And Smoke Detection System, Sprinkler System,
            Water Spray Systems, CO2 Flooding Systems, Fire Extinguishers,
            Hydrant Accessories, O-Rings, Oil Seals, PHE Gasket, Tyres, Belts,
            Agriculture tools, Cultivation Tools, Irrigation Fitting and Tools,
            Automobile Vehicles Accessories and Parts, Pressure Switch, Pressure
            Indicator, Temperature Indicator, Level Sensor, Hexane Detector,
            Level Switch, Utensils, Oven, Cooler, Dishwashers, Cooking
            Equipment's, Stationery Papers, Writing and Correction Pen and
            Holder, Staplers and Staples, Punching Machine, Packs and Pouches,
            Stamps and Stamp Supplies. Calculators, Diaries and Materials.{" "}
          </p>
          {/* <div className="about-stats">
            <div className="row g-3 mb-4">
              <div className="col-md-4 col-6">
                <div className="stats-box">
                  <div className="top-part">
                    <img
                      src="assets/svg/about/users.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      150+ <span>users</span>
                    </h2>
                  </div>
                  <h6 className="content-color">
                    Multikart have 150+ register users online store
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="stats-box">
                  <div className="top-part">
                    <img
                      src="assets/svg/about/stores.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      50+ <span>stores</span>
                    </h2>
                  </div>
                  <h6 className="content-color">
                    Multikart have 50+ stores worldwide.
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="stats-box">
                  <div className="top-part">
                    <img
                      src="assets/svg/about/delivery.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      1.5M+ <span>orders</span>
                    </h2>
                  </div>
                  <h6 className="content-color">
                    Multikart has 1.5M+ total orders till now.{" "}
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="stats-box">
                  <div className="top-part">
                    <img
                      src="assets/svg/about/diamond.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      100+ <span>Brands</span>
                    </h2>
                  </div>
                  <h6 className="content-color">
                    Multikart has 100+ brands in our stores.{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <p className="content-color">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classNameical Latin literature from 45
            BC, making it over 2000 years old. Richard McClintock, a Latin
            professor at Hampden-Sydney College in Virginia, looked up one of
            the more obscure Latin words,{" "}
          </p>
          <h4 className="fw-bold mb-3">Our Brands</h4>
          <p className="content-color">
            Each Brands have a unique personality and exclusive design. They
            have the freedom to develop the styles and garments that create the
            right appeal – and all our Brands are united
          </p>
          <div className="row g-3 brand-section section-b-space">
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/1.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/2.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/3.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/4.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/5.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-3 col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/6.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div> */}
        </section>
      </div>
    );
  }
}
export default About;
