import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Notification extends Component{

    render(){
let url="";
        return(
            <div>
  
    {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
   


    
    <header>
        <div className="back-links">
            <a href="/">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>notification</h2>
                </div>
            </a>
        </div>
    </header>
   


    <section className="pt-0 tab-section section-b-space">
        <div className="title-section px-15">
            <h2>Find your Style</h2>
            <h3>Super Summer Sale</h3>
        </div>

        <div className="px-15">
            <ul className="filter-title" id="filterOptions">
                <li className="active"><a href={url} className="all">All</a></li>
                <li><a href={url} className="order">Order Info</a></li>
                <li><a href={url} className="offers">Offers</a></li>
                <li><a href={url} className="payment">Payment</a></li>
                <li><a href={url} className="return">return</a></li>
            </ul>

            <div id="ourHolder" className="filter-content">
                <div className="item order">
                    <div className="media">
                        <img src="assets/images/notification/1.jpg" className="img-fluid" alt="" />
                        <div className="media-body">
                            <h4>Exclusive Brand Day Sale!! HURRY, LIMITED period offer!</h4>
                            <h6 className="content-color">10 July, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item offers">
                    <div className="media">
                        <img src="assets/images/notification/2.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>Order Placed successfully. It will be delivered on Mon 15 July, 2021</h4>
                            <h6 className="content-color">5 July, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item order">
                    <div className="media">
                        <img src="assets/images/notification/3.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>We have got coupons. collect now and save extras !!!</h4>
                            <h6 className="content-color">5 June, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item payment">
                    <div className="media">
                        <img src="assets/images/notification/4.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>Payment Failed. You can retry making a payment on order sections.</h4>
                            <h6 className="content-color">20 April, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item payment">
                    <div className="media">
                        <img src="assets/images/notification/5.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>Exclusive Brand Day Sale!! HURRY, LIMITED period offer!</h4>
                            <h6 className="content-color">10 July, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item offers">
                    <div className="media">
                        <img src="assets/images/notification/6.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>Your order is delivered on time. for any further assistance please contact us. </h4>
                            <h6 className="content-color">5 July, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item order">
                    <div className="media">
                        <img src="assets/images/notification/3.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>We have got coupons. collect now and save extras !!!</h4>
                            <h6 className="content-color">5 June, 2021</h6>
                        </div>
                    </div>
                </div>
                <div className="item payment">
                    <div className="media">
                        <img src="assets/images/notification/4.jpg" className="img-fluid" alt=""/>
                        <div className="media-body">
                            <h4>Payment Failed. You can retry making a payment on order sections.</h4>
                            <h6 className="content-color">20 April, 2021</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

                
            </div>
        );
    }

}export default Notification;