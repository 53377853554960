import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";


class Reset_phone extends Component{
    constructor(props){
        super(props);
        this.state={
        current_no:"",
        new_no:""
        }
        this.commonChange= this.commonChange.bind(this);
    }

 commonChange(e) {

     const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({
             [e.target.name]: e.target.value
             })
      }
       
  }

resetphone(e){
       // console.log(this.state);
       var param={
             Old_no:this.state.current_no,
             mobile_no:this.state.new_no,
             Method:"Mobile"
       }
      var data={
          Old_mobile_no:this.state.current_no,
           mobile_no:this.state.new_no,
      }
      var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch(
      "https://api.emechmart.com/api/Useraccount/ChangeMobileno",requestOptions)
      .then((resp)=>resp.json())
      .then((res)=>{
          console.log(res);
          if(res.Success===true){
              toast.dismiss()
          toast.success(res.Message,{autoClose:2000})
          this.props.history.push({
              pathname:"/OTP" ,
              state:param
          })
          }
           else{
           toast.dismiss()
          toast.error(res.error,{aotoClose:2000})
           }
      })

}


    render(){
        return(
            <>
{/* <ToastContainer Colored theme='colored' closeButton={false} autoClose={2000} /> */}

        {/* <!-- top design start --> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />
        {/* <!-- top design end --> */}

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Change Number</h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="one"
                maxlength="10"
                minlength="10"
                placeholder="Current Number"
                name="current_no"
                value={this.state.current_no}
                onChange={this.commonChange}
              />
              <label htmlFor="one">Current Number *</label>
              {/* <div className="text-danger">{this.state.errors.current_pass}</div> */}

            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                maxlength="10"
                minlength="10"
                id="two"
                placeholder="New Number"
                name="new_no"
               value={this.state.new_no}
                onChange={this.commonChange}
              />
              <label htmlFor="two">New Number *</label>
              {/* <div className="text-danger">{this.state.errors.new_pass}</div> */}

            </div>
            <button className="btn btn-solid w-100" onClick={(e)=>this.resetphone()}>
              CHANGE NUMBER
            </button>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
                
            </>
        )
    }
}export default Reset_phone