import React, { Component } from "react";
import Iconmenu from "./iconmenu.js";

class Ewishlist extends Component {
  render() {
    return (
      <div>
        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Shop</h2>
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <Iconmenu />
              </li>
            </ul>
          </div>
        </header>

        {/* <!-- empty cart start --> */}
        <section className="px-15">
          <div className="empty-cart-section text-center">
            <img
              src="assets/images/empty-cart.png"
              className="img-fluid"
              alt=""
            />
            <h2 style={{ "margin-bottom": "15px" }}>
              Please login to use wishlist
            </h2>
            {/* <p>Looks like you haven’t added anything to your cart yet. You will find a lot of interesting products on
                our “Shop” page</p> */}
            <a href="/Login" className="btn btn-solid w-100">
              Login
            </a>
          </div>
        </section>
      </div>
    );
  }
}
export default Ewishlist;
