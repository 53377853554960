/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import{Discount} from "./Helper"

class Home extends Component{

 constructor(props) {
    super(props);
    this.state = {
      Data: [],
      deals: [],
      logo:"",
      biggestdeal: [],
      midslider: [],
      topmenu:[],
      CategorywiseProducts:[]
      

      
    };
    
  }
 

componentDidMount() {
    
 fetch("https://api.emechmart.com/api/HomePage/GetLayoutData")
         .then((res) => res.json())
      .then((result) => {
console.log(result);
        
        this.setState({
         logo: result.Data.logo
        });
       
      });

   fetch("https://api.emechmart.com/api/HomePage/GetHomePageData")
        .then((resp)=>resp.json())
        .then((resp)=>{
          console.log(resp);
          this.setState({
          Data: resp.Data.sliders,
          deals:resp.Data.deals,
          biggestdeal:resp.Data.biggest_deals,
          midslider:resp.Data.mid_sliders,
          topmenu:resp.Data.top_menu,
          CategorywiseProducts:resp.Data.CategorywiseProducts
         
         });
      console.log(this.state);

        });

        
  }



    render(){
     var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
     
       const {Data,deals,biggestdeal,midslider,topmenu,CategorywiseProducts} = this.state;
        return(
            <div>
{/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}


     <header>
    <div className="nav-bar">
      <img src="assets/svg/bar.svg" className="img-fluid" alt=""/>
    </div>
    <a href="/" className="brand-logo">
      <img src={this.state.logo} className="img-fluid main-logo" alt=""/>
      <img src="assets/images/logo-white.png" className="img-fluid white-logo" alt=""/>
    </a>
    <div className="header-option">
      <ul>
        <li>
          <a href="/Search"><i className="iconly-Search icli"></i></a>
        </li>
        <li>
          <a href="/Notification"><i className="iconly-Notification icli"></i></a>
        </li>
        <li>
          <a href="/Wishlist"><i className="iconly-Heart icli"></i></a>
        </li>
        <li>
          <a href="/Kart"><i className="iconly-Buy icli"></i></a>
        </li>
      </ul>
    </div>
  </header>


  <a href="#" className="overlay-sidebar">ddd</a>
  <div className="header-sidebar">
    <a href="/Profile_setting" className="user-panel">
      <img src="assets/images/user/1.png" className="img-fluid user-img" alt=""/>
      <span>Hello, User</span>
      <i className="iconly-Arrow-Right-2 icli"></i>
    </a>
    <div className="sidebar-content">
      <ul className="link-section">
      
        <li>
          <a href="/Login">
            <i className="iconly-Paper icli"></i>
            <div className="content">
              <h4>Login</h4>
            </div>
          </a>
        </li>
        <li>
          <a href="/">
            <i className="iconly-Home icli"></i>
            <div className="content">
              <h4>Home</h4>
              <h6>Offers, Top Deals, Top Brands</h6>
            </div>
          </a>
        </li>
        <li>
          <a href="/Cat">
            <i className="iconly-Category icli"></i>
            <div className="content">
              <h4>Shop by Category</h4>
              <h6>Men, Women, Kids, Beauty.. </h6>
            </div>
          </a>
        </li>
        <li>
          <a href="/History">
            <i className="iconly-Document icli"></i>
            <div className="content">
              <h4>Orders</h4>
              <h6>Ongoing Orders, Recent Orders..</h6>
            </div>
          </a>
        </li>
        <li>
          <a href="/Wishlist">
            <i className="iconly-Heart icli"></i>
            <div className="content">
              <h4>Your Wishlist</h4>
              <h6>Your Save Products</h6>
            </div>
          </a>
        </li>
        <li>
          <a href="/Profile">
            <i className="iconly-Profile icli"></i>
            <div className="content">
              <h4>Your Account</h4>
              <h6>Profile, Settings, Saved Cards...</h6>
            </div>
          </a>
        </li>
     
      </ul>
      <div className="divider"></div>
      <ul className="link-section">
        <li>
          <a href="/About">
            <i className="iconly-Info-Square icli"></i>
            <div className="content">
              <h4>About us</h4>
              <h6>About Multikart</h6>
            </div>
          </a>
        </li>
        <li>
          <a href="Help">
            <i className="iconly-Call icli"></i>
            <div className="content">
              <h4>Help/Customer Care</h4>
              <h6>Customer Support, FAQs</h6>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
 


  {/* <!-- category start --> */}
  <section className="category-section top-space">
    <ul className="category-slide">
      {topmenu.map((Data)=>(
      <li key={Data.index}>      
      <a href={Data.menu_link} className="category-box">
          <img src={Data.menu_image} className="img-fluid" alt=""/>
          <h4>{Data.menu_text}</h4>
        </a>
      </li>
      ))}
     
    </ul>
  </section>
  <div className="divider t-12 b-20"></div>
  {/* <!-- category end --> */}


  {/* <!-- home slider start -->  */}
 
   <section className="pt-0 home-section ratio_55">
    <div className="home-slider slick-default theme-dots">
    
    <Slider {...settings}>
     {Data.map((Data)=>(
      <div>   
        <div className="slider-box">
 
          <img src={Data.Homepageslider_image} className="img-fluid bg-img" alt=""/>
          <div className="slider-content">
            <div>
              <h2>{Data.Maintitle}</h2>
              <h1>{Data.Homepageslider_subtitle}</h1>
              <a href={Data.Homepageslider_b1_url} className="btn btn-solid">SHOP NOW</a>
            </div>
          </div>
        </div>
          </div>
))}
</Slider>
      

    </div> 
  </section>
   
  {/* <!-- home slider end --> */}
 

  {/* <!-- deals section start --> */}
  <section className="deals-section px-15 pt-0">
    <div className="title-part">
      <h2>Deals of the Day</h2>
      {/* <a href="/Shop">See All</a> */}
    </div>
    <div className="product-section">
      <div className="row gy-3">
       
      {deals.map((Data)=>(
        
        <div className="col-12" key={Data.product_id}>
          <div className="product-inline">
            <a href="/Product">
              <img src={Data.product_img} className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href="/Product">
                  <h4>{Data.product_name} </h4>
                </a>
                {/* <h5>by Mango</h5> */}
                <div className="price">
                <h4>${Data.your_price} <del>${Data.mrp}</del><span>{Discount(Data.mrp,Data.your_price)}%</span></h4>
                </div>
              </div>
            </div>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
        </div>
      ))}
        {/* <div className="col-12">
          <div className="product-inline">
            <a href="/Product">
              <img src="assets/images/products/2.jpg" className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href="/Product">
                  <h4>Men Blue Denim Jacket</h4>
                </a>
                <h5>by Zara</h5>
                <div className="price">
                  <h4>$32.00<span>SAVE 20%</span></h4>
                </div>
              </div>
            </div>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="product-inline">
            <a href="/Product">
              <img src="assets/images/products/3.jpg" className="img-fluid" alt=""/>
            </a>
            <div className="product-inline-content">
              <div>
                <a href="product.html">
                  <h4>Pink Hoodie t-shirt full </h4>
                </a>
                <h5>by H&M</h5>
                <div className="price">
                  <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                </div>
              </div>
            </div>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
        </div>*/}
      </div> 
    </div>
  </section>
  <div className="divider"></div>
  {/* <!-- deals section end --> */}


  {/* <!-- tab section start --> */}
  <section className="pt-0 tab-section">
    <div className="title-section px-15">
      <h2>Category wise Products</h2>
      
    </div>
    <div className="tab-section"> 
   
      <ul className="nav nav-tabs theme-tab pl-15" >
      {CategorywiseProducts.map((Data)=>(
        <li className="nav-item" key={Data.index}>
          <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#trending" type="button">
          {Data.category_name}</button>
        </li>
        ))}
        {/* <li className="nav-item">
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#top" type="button">Top Picks</button>
        </li>
        <li className="nav-item">
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#featured" type="button">Featured
            Products</button>
        </li>
        <li className="nav-item">
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#rated" type="button">Top Rated</button>
        </li>
        <li className="nav-item">
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#ship" type="button">Ready to ship</button>
        </li> */}
      </ul>
      <div className="tab-content px-15">
        <div className="tab-pane fade show active" id="trending">
          <div className="row gy-3 gx-3">
          {CategorywiseProducts.map((Data,index)=>(
            Data.products.map((Data)=>(
            <div className="col-md-4 col-6"key={Data.index}>
              <div className="product-box ratio_square">
                <div className="img-part">
                  <Link to={{pathname: '/Product',state:Data.product_id}}><img src={Data.product_img} alt="" className="img-fluid bg-img"/></Link>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>{Data.product_name}</h4>
                  </a>
                  <div className="price">
                    <h4>${Data.your_price} <del>${Data.mrp}</del><span>{Discount(Data.mrp,Data.your_price)}%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            ))
             ))}
            {/* <div className="col-md-4 col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
        {/* <div className="tab-pane fade" id="top">
          <div className="row gy-3 gx-3">
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/4.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="featured">
          <div className="row gy-3 gx-3">
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/4.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="rated">
          <div className="row gy-3 gx-3">
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/4.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="ship">
          <div className="row gy-3 gx-3">
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/4.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <a href="/Product"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img"/></a>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli"></i>
                    <i className="iconly-Heart icbo"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="product-content">
                  <ul className="ratings">
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo"></i></li>
                    <li><i className="iconly-Star icbo empty"></i></li>
                  </ul>
                  <a href="/Product">
                    <h4>Blue Denim Jacket</h4>
                  </a>
                  <div className="price">
                    <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>*/} 
        </div> 
      </div>
    </div>
  </section>
  {/* <!-- tab section end --> */}


  {/* <!-- timer banner start --> */}
  {/* <section className="banner-timer">
    <div className="banner-bg">
      <div className="banner-content">
        <div>
          <h6>Denim Wear</h6>
          <h2>Sales Starts In</h2>
          <div className="counters">
            <div className="counter d-none">
              <span id="days">NA</span>
              <p>Days</p>
            </div>
            <div className="counter">
              <span id="hours">NA</span>
              <p>Hours</p>
            </div>
            <div className="counter">
              <span id="minutes">NA</span>
              <p>Minutes</p>
            </div>
            <div className="counter">
              <span id="seconds">NA</span>
              <p>Seconds</p>
            </div>
          </div>
          <a href="/Shop">explore now</a>
        </div>
      </div>
      <div className="banner-img">
        <img src="assets/images/banner-image.png" className="img-fluid" alt=""/>
      </div>
    </div>
  </section>
  <!-- timer banner end --> */}


{/* <!--middle banner start--> */}
 <section className="pt-0 home-section ratio_55">
    <div className="home-slider slick-default theme-dots">
    
    <Slider {...settings}>

     {midslider.map((Data)=>(
      <div>   
        <div className="slider-box">
 
          <img src={Data.Homepageslider_image} className="img-fluid bg-img" alt=""/>
          <div className="slider-content">
            <div>
              <h2>{Data.Maintitle}</h2>
              <h1>{Data.Homepageslider_subtitle}</h1>
              <a href={Data.Homepageslider_b1_url} className="btn btn-solid">SHOP NOW</a>
            </div>
          </div>
        </div>
          </div>
))}
</Slider>
  </div> 
  </section>
{/* <!--middle banner end--> */}




  {/* <!-- brands section start --> */}
  <section className="brand-section pl-15">
    <h2 className="title">Biggest Deals on Top Brands</h2>
    <div className="brand-slider slick-default">
            {biggestdeal.map((Data)=>(
      <div key={Data.index}>
           <a className="brand-box" href={Data.banner_link}>
          <img src={Data.banner_img} className="img-fluid" alt=""/>
        </a>   
      </div>
     ))}
      {/* <div>
        <a className="brand-box" href="shop.html">
          <img src="assets/images/brand-logos/2.png" className="img-fluid" alt=""/>
        </a>
      </div>
      <div>
        <a className="brand-box" href="shop.html">
          <img src="assets/images/brand-logos/3.png" className="img-fluid" alt=""/>
        </a>
      </div>
      <div>
        <a className="brand-box" href="shop.html">
          <img src="assets/images/brand-logos/4.png" className="img-fluid" alt=""/>
        </a>
      </div>
      <div>
        <a className="brand-box" href="shop.html">
          <img src="assets/images/brand-logos/5.png" className="img-fluid" alt=""/>
        </a>
      </div> */}
    </div>
  </section>
  <div className="divider"></div>
  {/* <!-- brands section end --> */}


  {/* <!-- kids corner section start --> */}
  <section className="pt-0 product-slider-section overflow-hidden">
    <div className="title-section px-15">
      <h2>The Kids Corner</h2>
      <h3>Clothing for your Li’l One’s </h3>
    </div>
    <div className="product-slider slick-default pl-15">
      <div>
        <div className="product-box ratio_square">
          <div className="img-part">
            <a href="/Product"><img src="assets/images/products/9.jpg" alt="" className="img-fluid bg-img"/></a>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
          <div className="product-content">
            <ul className="ratings">
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo empty"></i></li>
            </ul>
            <a href="/Product">
              <h4>Blue Denim Jacket</h4>
            </a>
            <div className="price">
              <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-box ratio_square">
          <div className="img-part">
            <a href="/Product"><img src="assets/images/products/10.jpg" alt="" className="img-fluid bg-img"/></a>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
          <div className="product-content">
            <ul className="ratings">
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo empty"></i></li>
            </ul>
            <a href="/Product">
              <h4>Blue Denim Jacket</h4>
            </a>
            <div className="price">
              <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-box ratio_square">
          <div className="img-part">
            <a href="/Product"><img src="assets/images/products/8.jpg" alt="" className="img-fluid bg-img"/></a>
            <div className="wishlist-btn">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
              <div className="effect-group">
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
                <span className="effect"></span>
              </div>
            </div>
          </div>
          <div className="product-content">
            <ul className="ratings">
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo"></i></li>
              <li><i className="iconly-Star icbo empty"></i></li>
            </ul>
            <a href="/Product">
              <h4>Blue Denim Jacket</h4>
            </a>
            <div className="price">
              <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- kids corner section end --> */}


  {/* <!-- offer corner start --> */}
  {/* <section className="offer-corner-section px-15">
    <h2 className="title">Offer Corner</h2>
    <div className="row g-3">
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">Under $50.00</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">Flat $20 OFF</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">buy 1 get 1</a>
        </div>
      </div>
      <div className="col-6">
        <div className="offer-box">
          <a href="/Shop">upto 50% off</a>
        </div>
      </div>
    </div>
  </section> */}
  {/* <!-- offer corner end --> */}


 
  <section className="panel-space"></section>
  


  
  <div className="bottom-panel">
    <ul>
      <li className="active">
        <a href="index.html">
          <div className="icon">
            <i className="iconly-Home icli"></i>
            <i className="iconly-Home icbo"></i>
          </div>
          <span>home</span>
        </a>
      </li>
      <li>
        <a href="/Cat">
          <div className="icon">
            <i className="iconly-Category icli"></i>
            <i className="iconly-Category icbo"></i>
          </div>
          <span>category</span>
        </a>
      </li>
      <li>
        <a href="/Kart">
          <div className="icon">
            <i className="iconly-Buy icli"></i>
            <i className="iconly-Buy icbo"></i>
          </div>
          <span>cart</span>
        </a>
      </li>
      <li>
        <a href="/Wishlist">
          <div className="icon">
            <i className="iconly-Heart icli"></i>
            <i className="iconly-Heart icbo"></i>
          </div>
          <span>wishlist</span>
        </a>
      </li>
      <li>
        <a href="Profile">
          <div className="icon">
            <i className="iconly-Profile icli"></i>
            <i className="iconly-Profile icbo"></i>
          </div>
          <span>profile</span>
        </a>
      </li>
    </ul>
  </div>
   <script src="assets/js/jquery-3.3.1.min.js"></script>
    <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/slick.js"></script>
    <script src="assets/js/script.js"></script>


  {/* <!-- pwa install app popup start -->
  <div className="offcanvas offcanvas-bottom addtohome-popup" tabindex="-1" id="offcanvas">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <div className="offcanvas-body small">
      <div className="app-info">
        <img src="assets/images/logo/logo48.png" className="img-fluid" alt=""/>
        <div className="content">
          <h3>Multikart App</h3>
          <a href="#">www.multikart-app.com</a>
        </div>
      </div>
      <a href="javascript:void(0)" className="btn btn-solid install-app" id="installApp">add to home screen</a>
    </div>
  </div> */}

            </div>
        );
    }

}export default Home;