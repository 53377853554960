import React, { Component } from "react";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Onboard extends Component{

    render(){

        return(
            <div>
 {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}
    

    
    <img src="assets/images/design.svg" className="img-fluid design-top" alt=""/>
    

   
    <div className="topbar-section">
        <a href="/"><img src="assets/images/logo.png" className="img-fluid main-logo" alt=""/>
      <img src="assets/images/logo-white.png" className="img-fluid white-logo" alt="" /> </a>
        <a className="skip-cls" href="/">SKIP</a>
    </div>
    


    {/* <!-- onboarding slider start --> */}
    <section className="overflow-hidden">
        <div className="onboarding-slider theme-dots">
            <div>
                <img src="assets/images/onboarding/1.png" className="img-fluid" alt=""/>
                <div className="boarding-content mt-4">
                    <h2>Welcome to Biggest Online Store</h2>
                    <p>Latest trends in clothing for women, men & kids at Multikart. Find new arrivals​, fashion
                        catalogs, collections & lookbooks every week.</p>
                </div>
            </div>
            <div>
                <img src="assets/images/onboarding/2.png" className="img-fluid" alt=""/>
                <div className="boarding-content mt-4">
                    <h2>Pefect Pair for Everyone </h2>
                    <p>500+ Brands and more than 1,00,000 + apparel and accessories. Discover what works best for you
                        with 30 Days Return policy.</p>
                </div>
            </div>
            <div>
                <img src="assets/images/onboarding/3.png" className="img-fluid" alt=""/>
                <div className="boarding-content mt-4">
                    <h2>Find all New Favourites</h2>
                    <p>new arrivals​, fashion catalogs & collections every week. Premium Brands. Free Shipping
                        Available. Hassle Free Installations.</p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- onboarding slider end --> */}


    
    <section className="section-b-space pt-3 px-15">
        <a href="/" className="btn btn-solid w-100">Start Shopping</a>
        <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">Have an account? <a className="title-color text-decoration-underline"
                    href="/Login">Sign In</a></h4>
        </div>
    </section>
                
            </div>
        );
    }

}export default Onboard;