import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";

import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Reset_password extends Component {
      constructor(props){
          super(props);
           this.state = {
       input: {},
      errors: {}
    };
  
 this.commonChange= this.commonChange.bind(this);
  }

  commonChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
       this.setState({
      input
    }); 
  }
 validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["current_pass"]) {
      isValid = false;
      errors["current_pass"] = "Current password is required";
    }

    if (!input["new_pass"]) {
      isValid = false;
      errors["new_pass"] = "New password is required";
    }
    if(typeof input["new_pass"] !== "undefined"){
          var regex =  new RegExp( "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$")
          if(input["new_pass"].length<8 || input["new_pass"].length> 20){
          isValid=false
         errors["new_pass"]="Passwords lenght should be 8 to 20"
      }
      else if(!regex.test(input["new_pass"])){
       isValid=false;
       errors["new_pass"]="Passwoord should contains: Uppercase, lowercase ,number,special character"
      }
        }
        
    if (!input["confirm_pass"]) {
      isValid = false;
      errors["confirm_pass"] = "Confirm password is required";
    }
    if(typeof input["confirm_pass"] !== "undefined"){
      if(input["confirm_pass"] !==input["new_pass"] ){
        isValid = false;
      errors["confirm_pass"] = "Confirm password and New password does not match."
      }
    }
      
    this.setState({
      errors: errors
    });

    return isValid;
  }

reset() {
  
   let input = {};
        input["current_pass"] = "";
        input["new_pass"] = "";
        input["confirm_pass"] = "";
          this.setState((currentState) => ({
             input:input
           }),
          )}


  resetpass(e){
  if(this.validate()){
       
       
      var data={
          Old_Password:this.state.input.current_pass,
          New_Password:this.state.input.new_pass,
          Confirm_Password:this.state.input.confirm_pass
      }
      var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch(
      "https://api.emechmart.com/api/UserAccount/ChangeUserPassword",
      requestOptions
    )
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        let success=res.Success
        if(success===true){
          toast.dismiss()
          toast.success("Password is changed successfully.",{autoClose:2000})
          this.props.history.push("/")
          
            let input = {};
         input["current_pass"] = "";
        input["new_pass"] = "";
        input["confirm_pass"] = "";
          this.setState((currentState) => ({
             input:input
           }),
          );
         // <Link to="/"></Link>
         // this.props.history.push("/");
        }
        else{
          this.reset()
           toast.dismiss()
          toast.error(res.Message,{aotoClose:2000})
          
       
            console.log(this.state.input);
        }
        
      });
         
    }
    
  }
  


  render() {
      let required=true
    return (
      <div>
        {/* <div className="loader">
          <span></span>
          <span></span>
        </div> */}
 {/* <ToastContainer Colored theme='colored' closeButton={false} autoClose={2000} /> */}

        {/* <!-- top design start --> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />
        {/* <!-- top design end --> */}

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Reset Password</h1>
          <div>
            <div className="form-floating mb-4">
              <input
                type="password"
                className="form-control"
                id="one"
                placeholder="Current Password"
                name="current_pass"
                value={this.state.input.current_pass}
                onChange={this.commonChange}
                required={required}
              />
              <label htmlFor="one">Current Password *</label>
              <div className="text-danger">{this.state.errors.current_pass}</div>

            </div>
            <div className="form-floating mb-4">
              <input
                type="password"
                className="form-control"
                id="two"
                placeholder="New Password"
                name="new_pass"
               value={this.state.input.new_pass}
                onChange={this.commonChange}
              />
              <label htmlFor="two">New Password *</label>
              <div className="text-danger">{this.state.errors.new_pass}</div>

            </div>
            <div className="form-floating mb-4">
              <input
                type="password"
                className="form-control"
                id="three"
                placeholder="Confirm Password"
                name="confirm_pass"
                value={this.state.input.confirm_pass}
                onChange={this.commonChange}
              />
              <label htmlFor="three">Confirm Password *</label>
              <div className="text-danger">{this.state.errors.confirm_pass}</div>
            </div>
            <button className="btn btn-solid w-100" onClick={(e)=>this.resetpass()}>
              RESET PASSWORD
            </button>
          </div>
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">
              Back to{" "}
              <a
                className="title-color text-decoration-underline"
                href="/Login"
              >
                Sign In
              </a>
            </h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
      </div>
    );
  }
}
export default Reset_password;
