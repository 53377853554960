/* eslint-disable no-loop-func */
import React, { Component } from "react";
import Loader from "react-js-loader";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";


const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: "",
      cod: "",
      Method: "RazorPay",
      btotal: 0,
      Tax: 0,
      Discount: 0,
      shipping: 0,
      cod_charge: 0,
      loading: true
    };
    this.commonChange = this.commonChange.bind(this);
    this.ToggleClick = this.ToggleClick.bind(this);
  }

  ToggleClick = () => {
    this.setState({
      show: true,
    });
  };
  commonChange(event) {
    const { state } = this.props.location;
    var param = {
      customer_pincode: state.dpincode,
      vendor_pincode: "",
      method: event.target.value,
      gettype: "",
    };
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Simple POST request with a JSON body using fetch
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Order/GetShippingCharges", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        var object = JSON.parse(res.Data);
        this.setState({
          shipping: parseInt(object.shipping_rate),
          cod_charge: parseInt(object.shipping_cod),
        });
      });

    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  componentDidMount() {
    // this.isLoading = setTimeout(() => {
    //   this.setState({ });
    // }, 2300);
    const { state } = this.props.location;
    var data;
    if (localStorage.getItem("promocode") === null) {
      data = "promocodename=''";
    } else {
      data =
        "promocodename=" +
        encodeURIComponent(localStorage.getItem("promocode"));
    }

    const crequestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: data,
    };
    fetch(
      "https://api.emechmart.com/api/Order/GetProductsForCheckout",
      crequestOptions
    )
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        this.setState((currentState) => ({
          cartinfo: res.Data.OrderedProducts,
          cod: res.Data.iscod,
          validator: res.Data.promocode_applied,
        }));
        for (var i = 0; i < this.state.cartinfo.length; i++) {
          const { cartinfo } = this.state;
          this.setState((prevState) => ({
            btotal:
              prevState.btotal +
              parseInt(cartinfo[i].finalprice) *
                parseInt(cartinfo[i].productquantity),
            Tax:
              prevState.Tax +
              parseFloat(cartinfo[i].totaltax) *
                parseInt(cartinfo[i].productquantity),
            Discount:
              prevState.Discount +
              parseInt(cartinfo[i].discountprice) *
                parseInt(cartinfo[i].productquantity),
                 
          }));
        }
      });
    var param = {
      customer_pincode: state.dpincode,
      vendor_pincode: "",
      method: this.state.Method,
      gettype: "",
    };

    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Simple POST request with a JSON body using fetch
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Order/GetShippingCharges", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        var object = JSON.parse(res.Data);
        this.setState({
          shipping: parseInt(object.shipping_rate),
          cod_charge: parseInt(object.shipping_cod),
          loading: false
        });
      });
  }

  paynow(e) {
    const { state } = this.props.location;
    var promo;
    if (localStorage.getItem("promocode") === null) {
      promo = "";
    } else {
      promo = localStorage.getItem("promocode");
    }
    var Order = {
      delivery_rand: state.d_address,
      billing_rand: state.baddress,
      promocode_applied: this.state.validator,
      payment_method: this.state.Method,
      termsandpolicy: "true",
      promocodename: promo,
    };
    console.log(Order);
    var formBody = [];
    for (var property in Order) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(Order[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Order/CreateOrder", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        if (res.Success === true) {
          if (this.state.Method === "COD") {
              localStorage.setItem("order",JSON.stringify(res.Data));

            this.props.history.push("/Order_placed");
          } else {
            var apikey = res.Data.razorpay_apikey;
            var secret_key = res.Data.razorpay_secretkey;
            var orderid = res.Data.orderid;
            var name = res.Data.customername;
            var amount = res.Data.amount;
            var eid=res.Data.emc_orderid

            this.props.history.push({
              pathname: "/Razor",
              state: { apikey, secret_key, orderid, name, amount,eid},
            });
          }
        }
      });
  }
// componentWillUnmount() {
//     clearTimeout(this.isLoading);
//   }
 
  render() {
    const { validator, cod, btotal, Tax, cod_charge, shipping,loading } = this.state;
    const final = btotal + Tax + shipping + cod_charge;
    const codesheet = () => {
      if (validator === 1) {
        return (
          <li>
            <h4>
              Applied Promocode{" "}
              <span className="theme-color">
                {localStorage.getItem("promocode")}
              </span>
            </h4>
          </li>
        );
      }
    };

    const COD = () => {
      if (cod === "1") {
        return (
          <div className="card">
            <div className="card-header" id="h_one">
              <div
                className="btn btn-link"
                data-bs-toggle="collapse"
                data-bs-target="#one"
              >
                <label htmlFor="r_one1">
                  <img
                    src="assets/images/payment/1.png"
                    className="img-fluid"
                    alt=""
                  />
                  Cash On Delivery
                  
                  <input
                    type="radio"
                    className="radio_animated"
                    id="r_one1"
                    name="Method"
                    onChange={this.commonChange}
                    value="COD"
                    required
                    style={{marginBottom: "1.9px"}}
                  />
                </label>

                
              </div>
            </div>
            <div
              id="one"
              className="collapse show"
              aria-labelledby="h_one"
              data-bs-parent="#accordionExample"
            >
              <div className="card-body p-0"></div>
            </div>
          </div>
        );
      }
    };

    return  loading ? (
      <Loading />
    ) :(
      <div>
        <header>
          <div className="back-links">
            <a href="/Kart">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Payment Details</h2>
                <h6>Step 3 of 3</h6>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- offer section start --> */}
        {/* <section className="offer-section px-15 top-space">
        <h2 className="page-title" stylr={{"margin-left": "10px"}}>Apply Promocode</h2>
        <div className=" check-delivery-section product-detail-box px-15">
           <div className="pincode-form" style={{"margin-left": "-10px", "margin-right": "-10px"}}>
              <input
                className="form-control form-theme"
                placeholder=" Enter Promocode"
                name="promocode"
                onChange={this.commonChange}
              />
              <a href="javascript:;" onClick={(e) => this.checkcode()}>
                Apply
              </a>
            </div>
            {show === true ? codesheet() : null}
        </div>
        <a href="#" className="show-more">Show More (5 offers)</a>
    </section> */}
        <div className="divider"></div>
        {/* <!-- offer section end --> */}

        {/* <!-- payment method start --> */}
        <section className="px-15 payment-method-section pt-0  top-space">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div className="card-header" id="h_two">
                <div
                  className="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#two"
                >
                  <label htmlFor="r_two">
                    <img
                      src="assets/images/payment/4.png"
                      className="img-fluid"
                      alt=""
                    />
                    RazorPay (Online Payment)
                    <input
                      type="radio"
                      className="radio_animated"
                      defaultChecked
                      id="r_two"
                      name="Method"
                      onChange={this.commonChange}
                      value="RazorPay"
                      required
                    />
                  </label>
                </div>
              </div>
              <div
                id="two"
                className="collapse "
                aria-labelledby="h_two"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body p-0"></div>
              </div>
            </div>
            {COD()}
            {/* <div className="card">
              <div className="card-header" id="h_two">
                <div
                  className="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#two"
                >
                  <label htmlFor="r_two">
                    <img
                      src="assets/images/payment/4.png"
                      className="img-fluid"
                      alt=""
                    />
                    RazorPay (Online Payment)
                    <input
                      type="radio"
                      className="radio_animated"
                      defaultChecked
                      id="r_two"
                      name="razor"
                      onChange={this.commonChange}
                      value="RazorPay"
                      required
                    />
                  </label>
                </div>
              </div>
              <div
                id="two"
                className="collapse"
                aria-labelledby="h_two"
                data-bs-parent="#accordionExample"
              > 
                 <div className="card-body">
                        <form className="pt-2">
                            <div className="form-floating mb-4">
                                <label htmlFor="c-name">name on card</label>
                                <input type="text" className="form-control" id="c-name" placeholder="name on card"/>
                            </div>
                            <div className="form-floating mb-4">
                                <label htmlFor="c1name">card number</label>
                                <input type="text" className="form-control" id="c1name" placeholder="card number"/>
                            </div>
                            <div className="row">
                                <div className="form-floating mb-4 col-8">
                                    <label htmlFor="cname">expiration date</label>
                                    <input type="number" className="form-control" id="cname" placeholder="expiration date"/>
                                </div>
                                <div className="form-floating mb-4 col-4">
                                    <label htmlFor="c2name">CVV</label>
                                    <input type="number" className="form-control" id="c2name" placeholder="CVV"/>
                                </div>
                            </div>
                            <div className="payment-btn">
                                <button type="submit" className="btn btn-solid color1">make
                                    payment</button>
                            </div>
                        </form>
                    </div> 
              </div>
            </div>
             <div className="card">
                <div className="card-header" id="h_three">
                    <div className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#three">
                        <label htmlFor='r_three'>
                            <img src="assets/images/payment/3.png" className="img-fluid" alt=""/>Wallets
                            <input type='radio' className="radio_animated" id='r_three' name='occupation' value='Working'
                                required />
                        </label>
                    </div>
                </div>
                <div id="three" className="collapse" aria-labelledby="h_three" data-bs-parent="#accordionExample">
                    <div className="card-body">
                        <form className="wallet-section">
                            <h4 className="page-title">Select Popular Banks</h4>
                            <div className="">
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios1"
                                        value="option1" checked/>
                                    <label className="form-check-label" htmlFor="Radios1">
                                        Industrial & Commercial Bank
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios2"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="Radios2">
                                        Construction Bank Corp.
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios3"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="Radios3">
                                        Agricultural Bank
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios4"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="Radios4">
                                        HSBC Holdings
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios5"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="Radios5">
                                        Bank of America
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios1" id="Radios6"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="Radios6">
                                        JPMorgan Chase & Co.
                                    </label>
                                </div>
                            </div>
                            <div className="form-floating mt-3">
                                <select className="form-select" id="floatingSelect"
                                    aria-label="Floating label select example">
                                    <option selected disabled value="1">Choose Bank...</option>
                                    <option value="2">ICICI</option>
                                    <option value="3">BOB</option>
                                </select>
                                <label htmlFor="floatingSelect">Choose Bank</label>
                            </div>
                            <div className="payment-btn">
                                <button type="submit" className="btn btn-solid mt-4">make
                                    payment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="h_four">
                    <div className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#four">
                        <label htmlFor='r_four'>
                            <img src="assets/images/payment/4.png" className="img-fluid" alt=""/>Net Banking
                            <input type='radio' className="radio_animated" id='r_four' name='occupation' value='Working'
                                required />
                        </label>
                    </div>
                </div>
                <div id="four" className="collapse" aria-labelledby="h_four" data-bs-parent="#accordionExample">
                    <div className="card-body">
                        <form className="wallet-section">
                            <h4 className="page-title">Select Your Wallet</h4>
                            <div className="">
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios1"
                                        value="option1" checked/>
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        Adyen
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios2"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                        Airtel Money
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios3"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="exampleRadios3">
                                        AlliedWallet
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios4"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="exampleRadios4">
                                        Apple Pay
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios5"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="exampleRadios5">
                                        Brinks
                                    </label>
                                </div>
                                <div className="form-check ps-0 mb-1">
                                    <input className="radio_animated" type="radio" name="exampleRadios2" id="exampleRadios6"
                                        value="option2"/>
                                    <label className="form-check-label" htmlFor="exampleRadios6">
                                        CardFree
                                    </label>
                                </div>
                            </div>
                            <div className="payment-btn mt-4">
                                <button type="submit" className="btn btn-solid">make
                                    payment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
          </div>
        </section>
        <div className="divider"></div>
        {/* <!-- payment method end --> */}

        {/* <!-- order details start --> */}
        <section className="px-15 pt-0">
          <h2 className="title">Order Details:</h2>
          <div className="order-details">
            <ul>
              <li>
                <h4>
                  Bag total{" "}
                  <span>
                    {"\u20B9"}
                    {btotal}
                  </span>
                </h4>
              </li>
              <li>
                <h4>
                  Shipping Charge{" "}
                  <span>
                    {"\u20B9"}
                    {shipping}
                  </span>
                </h4>
              </li>
              <li>
                <h4>
                  COD Charge{" "}
                  <span>
                    {"\u20B9"}
                    {cod_charge}
                  </span>
                </h4>
              </li>
              {/* <li>
                <h4>
                  Bag savings <span className="text-green">-{"\u20B9"}{Discount}</span>
                </h4>
              </li> */}
              {codesheet()}
              <li>
                <h4>
                  Tax{" "}
                  <span>
                    {"\u20B9"}
                    {Tax.toFixed(0)}
                  </span>
                </h4>
              </li>
            </ul>
            <div className="total-amount">
              <h4>
                Total Amount{" "}
                <span>
                  {"\u20B9"}
                  {final.toFixed(0)}
                </span>
              </h4>
            </div>
          </div>
        </section>
        {/* <!-- order details end --> */}

        <section className="panel-space"></section>

        <div className="cart-bottom">
          <div>
            <div className="left-content">
              <h4> {"\u20B9"}
                  {final.toFixed(0)}</h4>
              <a href="/Kart" className="theme-color">
                View details
              </a>
            </div>
            <button type="button" class="w-100 btn btn-solid"
              onClick={(e) => this.paynow()}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default Payment;
