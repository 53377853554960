import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




  
const ReactNotificationComponent = ({ title, body }) => {
//const toastId = React.useRef(null);
//  if(! toast.isActive(toastId.current)) {
//    console.log(toast.isActive);
//       toastId.current = toast.info(<Display />);
//     }
//  toast.info(<Display />,{toastId: toastContainerId,});
  function Display() {
    return (
      <div>
        <h4>{title}</h4>
        <p>{body}</p>
      </div>
    );
  }
    
    return toast.info(<Display />);
  
  
};



// ReactNotificationComponent.propTypes={
//     title:propTypes.string,
//     body:propTypes.string
// }

export default ReactNotificationComponent;
