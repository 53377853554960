import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import { saveAs } from 'file-saver';
import { Button, Modal } from "react-bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

const Loading = () => (
  <div className="loader">
    <Loader
      type="bubble-ping"
      bgColor={"#007744"}
      color={"#007744"}
      size={50}
    />
  </div>
);
class Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      P_details: {},
      shippingRates: {},
      billingaddress: {},
      deliveryAddress: {},
      display: "block",
      loading: true,
      status: 0,
      show: false,
      Reasons: [],
      other: "none",
      reason_other: "",
      error: {},
    };
    this.commonChange = this.commonChange.bind(this);
    this.hendleChange = this.hendleChange.bind(this);
  }

  commonChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value === "other") {
      this.setState({
        other: "block",
      });
    } else {
      this.setState({
        other: "none",
      });
    }
  }
  validate() {
    let reason = this.state.reason;
    let reason_other = this.state.reason_other;
    let error = {};
    let isValid = true;

    if (!reason) {
      isValid = false;
      error["reason"] = "Reason is Required.";
    }

    if (reason === "other") {
      if (reason_other === "") isValid = false;
      error["reason_other"] = "Reason is Required";
    }

    this.setState({
      error: error,
    });

    return isValid;
  }
  hendleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleModal() {
    const { P_details } = this.state;
    var value = "categoryid=" + encodeURIComponent(P_details.categoryid);

    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: value,
    };
    fetch(
      "https://api.emechmart.com/api/Order/BindReasonsForOrderCancel",
      headers
    )
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res.Data);
        this.setState({
          Reasons: res.Data,
          show: !this.state.show,
        });
      });
  }
  cancelorder(e) {
    const { state } = this.props.location;

    if (this.validate()) {
      var values = {
        orderid: state.id,
        enc_orderitemid: state.enc_id,
        reason: this.state.reason,
        reason_other: this.state.reason_other,
      };
      var formBody = [];
      for (var property in values) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(values[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const headers = {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: "Bearer " + localStorage.getItem("U_Id"),
        },
        body: formBody,
      };
      fetch("https://api.emechmart.com/api/Order/CancelOrder", headers)
        .then((resp) => resp.json())
        .then((res) => {
          window.location.reload();
        });
    }
  }
  componentDidMount() {
    const { state } = this.props.location;
    var values = {
      orderid: state.id,
      enc_orderitemid: state.enc_id,
    };
    var formBody = [];
    for (var property in values) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(values[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch("https://api.emechmart.com/api/Order/GetOrderTracking", headers)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        this.setState((currentState) => ({
          Data: res.Data,
          billingaddress: res.Data.billingaddress,
          deliveryAddress: res.Data.deliveryAddress,
          P_details: res.Data.singleproduct,
          shippingRates: res.Data.singleproduct.shippingRates,
          loading: false,
        }));

        if (
          this.state.P_details.status === "Cancelled" ||
          this.state.P_details.status === "Checkout"
        ) {
          this.setState({
            display: "none",
          });
        }
      });
  }

  renderSwitch() {
    switch (this.state.P_details.status) {
      case "Unshipped":
        return 1;
      case "Shipped":
        return 3;
      case "Waiting for pickup":
        return 2;
      case "Delivered":
        return 4;
      default:
        return 4;

      // code block
    }
  }
  downloadinvoice(){
    const{Data}=this.state
          saveAs(Data.invoice_link, "Taxinvoice.pdf");
   
  }

  render() {
    const { state } = this.props.location;
 let url="";
    const {
      Data,
      P_details,
      shippingRates,
      billingaddress,
      deliveryAddress,
      display,
      loading,
      Reasons,
      other,
      error,
    } = this.state;
    const send = {
      cid: P_details.categoryid,
      id: state.id,
      enc_id: state.enc_id,
    };

    const amount =
      parseInt(P_details.grandtotal) + parseFloat(shippingRates.final_rate);

    const button = () => {
      if (P_details.status === "Unshipped") {
        return (
          <div>
            <button
              className="status-0rder"
              style={{ backgroundColor: "grey" }}
              onClick={() => this.handleModal()}
            >
              Cancel Order
            </button>
            <Modal show={this.state.show} onHide={() => this.handleModal()}>
              <Modal.Header >ARE YOU SURE?</Modal.Header>
              <Modal.Body closeButton>
                <div className="filter-box">
                  <h2 className="billing">Reason* &nbsp;</h2>
                  <div className="filter-content">
                    <select
                      className="form-select form-control form-theme"
                      aria-label="Default select example"
                      name="reason"
                      onChange={this.commonChange}
                      defaultValue=""
                    >
                      <option disabled value="">
                        Reason Behind Returning the Order
                      </option>
                      {/* <option value="india">india</option> */}
                      { Reasons===null ?null: Reasons.map((data) => (
                        <option value={data.Value}>{data.Text}</option>
                      ))}
                      <option value="other">Other</option>
                    </select>
                    <div className="text-danger">{error.reason}</div>
                  </div>
                </div>
                <div
                  className="form-floating mb-4"
                  style={{ marginTop: "15px", display: other }}
                >
                  <textarea
                    className="form-control"
                    id="one"
                    name="reason_other"
                    placeholder="Email"
                    onChange={this.hendleChange}
                    value={this.state.reason_other}
                    required={true}
                    style={{ height: "60px" }}
                  />
                  <label htmlFor="one">Your Reason</label>
                  <div className="text-danger">{error.reason_other}</div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* <Button onClick={()=>this.handleModal()}>Close</Button>   */}
                <Button
                  onClick={(e) => this.cancelorder()}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel Order
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      } else if (
        P_details.status === "Delivered" &&
        P_details.returnpolicy === "1"
      ) {
        return (
          <Link
            to={{ pathname: "/Return", state: send }}
            className="status-0rder"
            style={{ backgroundColor: "green" }}
          >
            Return
          </Link>
        );
      } else if (P_details.status === "Cancelled") {
        return (
          <button className="status-0rder" style={{ backgroundColor: "red" }}>
            Order Cancelled
          </button>
        );
      } else {
      }
    };
    const Track = () => {
      if (P_details.awb_code === "") {
      } else {
        return (
          <div>
            <h5 className="content-color my-1">
              Tracking Number:{P_details.awb_code}
            </h5>
            <a className="theme-color" href={url}>
              Track Order
            </a>
          </div>
        );
      }
    };
    const Download=()=>{
      if(Data.invoice_link!==""){
        return( <button onClick={(e)=>this.downloadinvoice()}  className="btn btn-outline content-color w-100">
            Download Invoice
          </button>)
      }
    }
    const Notice = () => {
      if (P_details.status === "Checkout" || P_details.status==="Cancelled" ) {
        return (
          <div>
            {/* <div className="divider"></div> */}
            <div className="alert alert-danger d-flex align-items-center top-space">
              <label>
                Cannot show product process because either your payment process
                has failed or order is being cancelled. Please contact admin for
                any issue! At care@emechmart.com
              </label>
            </div>
          </div>
        );
      }
    };
    return loading ? (
      <Loading />
    ) : (
      <div>
        <header className="bg-transparent">
          <div className="back-links">
            <a href="/History">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Order Details</h2>
              </div>
            </a>
          </div>
        </header>

        {/* <!-- product detail start --> */}
        <div className="map-product-section px-15">
          <div className="order-box" style={{ background: "white" }}>
            <div className="d-flex align-items-center">
              <img
                src={P_details.productimage}
                className="img-fluid order-img"
                alt=""
              />
              <div className="media-body">
                <h4>{P_details.productname} </h4>
                <h5 className="content-color my-1">
                  {P_details.variation} Qty:{P_details.productquantity}
                </h5>
                <h5 className="content-color my-1">
                  Total Amount:{amount.toFixed(0)}
                </h5>
                <div className="d-flex">
                  <div>
                    <h6 className="content-color">Ordered:{Data.orderdate}</h6>
                  </div>
                  {/* <div>
                                <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6>
                            </div> */}
                </div>
                {Track()}
              </div>
              {button()}
            </div>
            {/* <div className="delivery-status" >
                       <div className="d-flex">
                            <div>
                                <h6 className="content-color">Ordered:</h6>
                                <h6>26th May, 2021</h6> 
                            </div>
                            <div>
                                 <h6 className="content-color">Delivery Status:</h6>
                                <h6>Dispatched</h6> 
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
        {/* <!-- product detail end --> */}

        {/* <!-- order tracking start --> */}
        {Notice()}
        <div className="order-track px-15" style={{ display: display }}>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 2 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat">Delivered</p>
              {/* <span className="order-track-text-sub">expected delivery on monday</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 3 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> Shipped</p>
              {/* <span className="order-track-text-sub">10.00 am, 21/05/2020</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 2 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> Preparing To Ship</p>
              {/* <span className="order-track-text-sub">12.00 am, 20/05/2020</span> */}
            </div>
          </div>
          <div
            className={
              "order-track-step " +
              (this.renderSwitch() < 1 ? "in-process" : "")
            }
          >
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> Order Placed</p>
              {/* <span className="order-track-text-sub">20/05/2020</span> */}
            </div>
          </div>
        </div>
        <div className="rate-section px-15">
    <ul>
      <li><i className="iconly-Star icli" ></i>
      <Link to={{pathname:"/Rate",state: state}} style={{color:"#777777"}}>Rate & Review Product</Link> </li>
      <li><i className="iconly-Star icli"></i> Need Help?</li>
    </ul>
  </div>
        <div className="divider"></div>
        {/* <!-- order tracking end --> */}

        {/* <!-- address section start --> */}
        <div className="d-flex">
          <div className="px-15" style={{ paddingRight: "24px" }}>
            <h6 className="tracking-title content-color">Billing Address</h6>
            <h4 className="fw-bold mb-1">{billingaddress.fullname}</h4>
            <h4 className="content-color">{billingaddress.line1}</h4>
            <h4 className="content-color">{billingaddress.line2}</h4>
            <h4 className="content-color">
              {billingaddress.town}, {billingaddress.state}
            </h4>
            <h4 className="content-color">{billingaddress.country}</h4>
            {/* <h4 className="fw-bold mt-1 mb-minus-4">Phone No: 903-239-1284</h4> */}
          </div>
          <div className="px-15">
            <h6 className="tracking-title content-color">Delivery Address</h6>
            <h4 className="fw-bold mb-1">{deliveryAddress.fullname}</h4>
            <h4 className="content-color">{deliveryAddress.line1}</h4>
            <h4 className="content-color">{deliveryAddress.line2}</h4>
            <h4 className="content-color">
              {deliveryAddress.town},{deliveryAddress.state}
            </h4>
            <h4 className="content-color">{deliveryAddress.country}</h4>
            <h4 className="fw-bold mt-1 mb-minus-4">
              Phone No:{deliveryAddress.mobileno}
            </h4>
          </div>
        </div>
        <div className="divider"></div>
        {/* <!-- address section end --> */}
         
        {/* <!-- order details section start --> */}
        {/* <div className="px-15 section-b-space"> */}
          {/* <h6 className="tracking-title content-color">Price Details</h6>
    <div className="order-details">
      <ul>
        <li>
          <h4>Bag total <span>$220.00</span></h4>
        </li>
        <li>
          <h4>Bag savings <span className="text-green">-$20.00</span></h4>
        </li>
        <li>
          <h4>Coupon Discount <span>$20.00</span></h4>
        </li>
        <li>
          <h4>Delivery <span>$50.00</span></h4>
        </li>
      </ul>
      <div className="total-amount">
        <h4>Total Amount <span>$270.00</span></h4>
      </div> */}
       {Download()}
          {/* </div> */}
        {/* </div> */}
        {/* <!-- order details section end --> */}
      </div>
    );
  }
}
export default Track;
