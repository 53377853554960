import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      status: "",
    };
    this.commonChange = this.commonChange.bind(this);
  }
  commonChange(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleSubmit = (e) => {
    var email = "email=" + encodeURIComponent(this.state.email);

    var options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: email,
    };
    fetch("https://api.emechmart.com/api/UserAccount/ForgetPassword", options)
      .then((resp) => resp.json())
      .then((res) => {
        console.log(res);
        var Success = res.Success;
        if (Success === true) {
          toast.dismiss();
          //  toast.success(res.Message+" Please check your Email",{autoClose:3000})
          this.setState({
            status: res.Message + " Please check your Email",
          });
        } else {
          toast.dismiss();
          toast.error(res.Message, { autoClose: 3000 });
          this.setState({
            status: " ",
          });
        }
      });
  };
  render() {
    return (
      <div>
        <ToastContainer Colored theme="colored" closeButton={false} />
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* 
    <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Forgot Password</h1>
          <form>
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="one"
                placeholder="Address"
                name="email"
                onChange={this.commonChange}
              />
              <label htmlFor="one">Email Address</label>
            </div>
            <a
              href="/OTP"
              className="btn btn-solid w-100"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              onClick={this.handleSubmit}
            >
              Send
            </a>
          </form>
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">
              Back to{" "}
              <a
                className="title-color text-decoration-underline"
                href="/Login"
              >
                Sign In
              </a>
            </h4>
            <h4 className="status">{this.state.status}</h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>

        {/* <!-- otp canvas start --> */}
      </div>
    );
  }
}
export default Forgot;
