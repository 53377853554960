import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";


class Rename extends Component{
    constructor(props){
        super(props);
        this.state={
        input:{},
        errors:{},
        status:""
        }
        this.commonChange= this.commonChange.bind(this);
    }

 commonChange(e) {
let input = this.state.input;
    input[e.target.name] = e.target.value;
      this.setState({
      input
    }); 
     
  }
 reset() {
  
   let input = {};
       input["current_email"] = "";
        input["new_email"] = "";
          this.setState((currentState) => ({
             input:input
           }),
          )}
   validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
     

    // if (!input["current_name"]) {
    //     isValid = false;
    //     errors["current_name"] = "Current Fullname is required";
    //   }
      if( !input["new_name"]){
       isValid = false;
        errors["new_name"] = "New Fullname is required";     
      }
     
      this.setState({
        errors: errors
      }); 
      return isValid;

   }

resetemail(e){
     if(this.validate()){
        
        
       var data={
               old_name:"",
               new_name:this.state.input.new_name, 
               type :"Fullname"    
       }
     }
      var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("U_Id"),
      },
      body: formBody,
    };
    fetch(
      "https://api.emechmart.com/api/Useraccount/EditRegistartionDetials",requestOptions)
      .then((resp)=>resp.json())
      .then((res)=>{
          console.log(res);
          if(res.Success===true){
              toast.dismiss()
          toast.success(res.Message,{autoClose:3000})
           localStorage.removeItem("U_Id");
           this.props.history.push("/Login")
          }
           else{
           toast.dismiss()
          toast.error(res.error,{aotoClose:3000})
          this.reset()
           }
      })

}


    render(){
        return(
            <>
{/* <ToastContainer Colored theme='colored' closeButton={false} autoClose={4000} /> */}

        {/* <!-- top design start --> */}
        <img
          src="assets/images/design.svg"
          className="img-fluid design-top"
          alt=""
        />
        {/* <!-- top design end --> */}

        {/* <!-- top bar start --> */}
        <div className="topbar-section">
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-fluid main-logo"
              alt=""
            />
            <img
              src="assets/images/logo-white.png"
              className="img-fluid white-logo"
              alt=""
            />
          </a>
          <a className="skip-cls" href="/">
            SKIP
          </a>
        </div>
        {/* <!-- top bar end --> */}

        {/* <!-- login section start --> */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Change Fullname</h1>
          <div>
            {/* <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="one"
                placeholder="Current Name"
                name="current_name"
                value={this.state.input.current_name}
                onChange={this.commonChange}
              />
              <label htmlFor="one">Current Fullname *</label>
              <div className="text-danger">{this.state.errors.current_name}</div>

            </div> */}
            <div className="form-floating mb-4">
              <input
                type="email"
                className="form-control"
                id="two"
                placeholder="New Name"
                name="new_name"
               value={this.state.input.new_name}
                onChange={this.commonChange}
              />
              <label htmlFor="two">New Fullname *</label>
              <div className="text-danger">{this.state.errors.new_name}</div>

            </div>
            <button className="btn btn-solid w-100" onClick={(e)=>this.resetemail()}>
              CHANGE FULLNAME
            </button>
            <h4 className="status">{this.state.status}</h4>
          </div>
        </section>
        {/* <!-- login section end --> */}

        <section className="panel-space"></section>
                
            </>
        )
    }
}export default Rename