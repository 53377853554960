import React, { Component } from "react";

class Ekart extends Component {
  render() {
    return (
      <div>
        <header>
          <div className="back-links">
            <a href="/">
              <i className="iconly-Arrow-Left icli"></i>
              <div className="content">
                <h2>Shopping Cart</h2>
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/Kart">
                  <i className="iconly-Buy icli"></i>
                </a>
              </li>
            </ul>
          </div>
        </header>

        {/* <!-- empty cart start --> */}
        <section className="px-15">
          <div className="empty-cart-section text-center">
            <img
              src="assets/images/empty-cart.png"
              className="img-fluid"
              alt=""
            />
            <h2>Whoops !! Cart is Empty</h2>
            <p>
              Looks like you haven’t added anything to your cart yet. You will
              find a lot of interesting products on our “Home” page
            </p>
            <a href="/" className="btn btn-solid w-100">
              start shopping
            </a>
          </div>
        </section>
      </div>
    );
  }
}
export default Ekart;
