import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Iconmenu from "./iconmenu.js"
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Cat extends Component{
    constructor(props){
        super(props);
        this.state={
           cat: [],
           
        };
    }
 componentDidMount(){
     fetch(" https://api.emechmart.com/api/CategroyAPI/GetAllGroups")
     .then((res)=> res.json())
     .then((res)=>{
         console.log(res);
       this.setState({
        cat:res.Data,
        
       }); 
     })

 }


    render(){
 console.log(this.state);
      const{cat}= this.state;
        return(
            <div >
                  {/* <div className="loader">
        <span></span>
        <span></span>
    </div>
    <!-- loader end --> */}


    
    <header>
        <div className="back-links">
            <a href="/">
                <i className="iconly-Arrow-Left icli"></i>
                <div className="content">
                    <h2>Categories</h2>
                </div>
            </a>
        </div>
        <div className="header-option">
            <ul>
                <li>
                    <a href="/Wishlist"><i className="iconly-Heart icli"></i></a>
                </li>
                <li>
                    <Iconmenu />
                </li>
            </ul>
        </div>
    </header>
    


    {/* <!-- category start --> */}
    <section className="category-listing px-15 top-space pt-0">
        {/* <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <img src="assets/images/sale.gif" className="img-fluid sale-gif" alt=""/>
                <h4>upto 50% off on all products </h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/sale.png" className="img-fluid" alt=""/>
            </div>
        </a> */}
          {cat.map((Data)=>(
        
        <Link  className="category-wrap"  to={
 
       {     
         pathname: '/Inner_category',
         state:Data.group_url
        }
  }> 
            <div className="content-part" key={Data.index}>
                <h2>{Data.group_name}</h2>
                <h4  dangerouslySetInnerHTML={{__html: Data.group_desc}}></h4>
            </div>
            <div className="img-part">
                <img src={Data.group_image} className="img-fluid" alt=""/>
            </div>
        </Link>
        ))}
        {/* <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <h2>MEN</h2>
                <h4>jackets, jeans, denims..</h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/men.png" className="img-fluid" alt=""/>
            </div>
        </a>
        <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <h2>KIDS</h2>
                <h4>clothing, toys, books..</h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/kids.png" className="img-fluid" alt=""/>
            </div>
        </a>
        <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <h2>BEAUTY</h2>
                <h4>skincare, haircare, makeup..</h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/beauty.png" className="img-fluid" alt=""/>
            </div>
        </a>
        <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <h2>FOOTWEAR</h2>
                <h4>shoes, sandle, activewear..</h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/footwear.png" className="img-fluid" alt=""/>
            </div>
        </a>
        <a href="/Inner_category" className="category-wrap">
            <div className="content-part">
                <h2>JEWELLERY</h2>
                <h4>necklace, chains, earrings..</h4>
            </div>
            <div className="img-part">
                <img src="assets/images/category/jewllery.png" className="img-fluid" alt=""/>
            </div>
        </a> */}
    </section>
    {/* <!-- category end --> */}


    <section className="panel-space"></section>
  


    
    <div className="bottom-panel">
        <ul>
            <li>
                <a href="/">
                    <div className="icon">
                        <i className="iconly-Home icli"></i>
                        <i className="iconly-Home icbo"></i>
                    </div>
                    <span>home</span>
                </a>
            </li>
            <li className="active">
                <a href="/Cat">
                    <div className="icon">
                        <i className="iconly-Category icli"></i>
                        <i className="iconly-Category icbo"></i>
                    </div>
                    <span>category</span>
                </a>
            </li>
            <li>
                <a href="/Kart">
                    <div className="icon">
                        <i className="iconly-Buy icli"></i>
                        <i className="iconly-Buy icbo"></i>
                    </div>
                    <span>cart</span>
                </a>
            </li>
            <li>
                <a href="Wishlist">
                    <div className="icon">
                        <i className="iconly-Heart icli"></i>
                        <i className="iconly-Heart icbo"></i>
                    </div>
                    <span>wishlist</span>
                </a>
            </li>
            <li>
                <a href="/Profile">
                    <div className="icon">
                        <i className="iconly-Profile icli"></i>
                        <i className="iconly-Profile icbo"></i>
                    </div>
                    <span>profile</span>
                </a>
            </li>
        </ul>
    </div>
            </div>
        );
    }

}export default Cat;