import React, { Component } from "react";
import { Link } from "react-router-dom";
import FilterResults from "react-filter-search";
import "./assets/css/style.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/iconly.css";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    fetch("https://api.emechmart.com/api/Product/BindAllCategories")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        this.setState({ data: json.Data });
      });
  }
  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ value });
  };

  onhandleclick(i) {
    const { data } = this.state;
    var shop = {
      url: data[i - 1].category_url,
      title: data[i - 1].group_name,
    };
    console.log(i);
    this.props.history.push({ pathname: "/Shop", state: shop });
  }
  render() {
   
    const { data, value } = this.state;
    console.log(this.state);
    return (
      <div className="myrelativediv">
        {/* <div className="loader">
        <span></span>
        <span></span>
    </div> */}

<div className="search-logo">
    <a href="/"><img src="assets/images/logo.png" alt="emechmart" /></a>
</div>
        <div className="mysearchbar">
          <a href="/" className="back-btn myserachicon">
            <i className="iconly-Arrow-Left icli"></i>
          </a>
          <div className="search-bar mysearchtext">
            <input
              type="text"
              name="value"
              value={value}
              onChange={this.handleChange}
              className="form-control form-theme"
              placeholder="Search"
            />
            <i className="iconly-Search icli search-icon mysearchclick"></i>
            <FilterResults
              value={value}
              data={data}
              renderResults={(results) => (
                <div
                  style={{
                    flex: "0 0 auto",
                    background: "rgba(255, 255, 255, 1)",
                    boxShadow: "0 0 15px lightgrey",
                    borderRadius: "0.25rem"
                  }}
                >
                  <div
                    style={{
                      padding: "7px",
                      display: value.length < 3 ? "none" : "block",
                    }}
                  >
                    {results.map((el, index) => (
                      <div style={{padding: "4px"}}>
                        <Link to={{ pathname: "/Shop", state: data[el.index - 1].category_url}}
                           >
                          {el.category_name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        {/* <!-- search panel start --> */}
        {/* <div className="search-panel w-back pt-3 px-15">
        
        
    </div> */}
        {/* <!-- search panel end --> */}
        {/* <section className="px-15 recent-search-section"></section> */}
        {/* <!-- recent search start --> */}
        {/* <section className="px-15 recent-search-section">
        <h4 className="page-title">Recent Search</h4>
        <ul>
            <li><a href="#"><i className="iconly-Time-Circle icli"></i> Party Wear Jumpshuit <img src="assets/svg/x.svg"
                        className="img-fluid delete-icon" alt=""/></a></li>
            <li><a href="#"><i className="iconly-Time-Circle icli"></i> Pink Hoodie t-shirt full <img src="assets/svg/x.svg"
                        className="img-fluid delete-icon" alt=""/></a></li>
            <li><a href="#"><i className="iconly-Time-Circle icli"></i> Blue Denim Jacket <img src="assets/svg/x.svg"
                        className="img-fluid delete-icon" alt=""/></a></li>
            <li><a href="#"><i className="iconly-Time-Circle icli"></i> Men Blue Denim Jacket <img src="assets/svg/x.svg"
                        className="img-fluid delete-icon" alt=""/></a></li>
        </ul>
    </section> */}
        {/* <!-- recent search end --> */}

        {/* <!-- recommended for you section start --> */}
        {/* <section className="px-15">
        <h4 className="page-title">Recommended for you</h4>
        <ul className="row filter-row g-3">
            <li className="col-4">
                <div className="filter-col">small</div>
            </li>
            <li className="col-4">
                <div className="filter-col">Medium</div>
            </li>
            <li className="col-4">
                <div className="filter-col">large</div>
            </li>
            <li className="col-4">
                <div className="filter-col">XL</div>
            </li>
            <li className="col-4">
                <div className="filter-col">2XL</div>
            </li>
        </ul> 
    </section>*/}
        {/* <!-- recommended for you section end --> */}

        {/* <!-- trending category start --> */}
        {/* <section className="px-15 inner-category">
        <h4 className="page-title">Trending Category</h4>
        <div className="row gx-3">
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/flowerprint.png" className="img-fluid" alt=""/>
                    <h4>Flowerprint</h4>
                </a>
            </div>
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/denim.png" className="img-fluid" alt=""/>
                    <h4>Denim</h4>
                </a>
            </div>
            <div className="col-4">
                <a href="/Shop">
                    <img src="assets/images/category/skirts.png" className="img-fluid" alt=""/>
                    <h4>Skirts</h4>
                </a>
            </div>
        </div>
    </section> */}
        {/* <!-- trending category end --> */}

        {/* <!-- brand section start --> */}
        {/* <section className="brand-section px-15">
        <h4 className="page-title">Biggest Deals on Top Brands</h4>
        <div className="row g-3">
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/1.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/2.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/3.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/4.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/5.png" className="img-fluid" alt=""/>
                </a>
            </div>
            <div className="col-4">
                <a className="brand-box" href="/Shop">
                    <img src="assets/images/brand-logos/6.png" className="img-fluid" alt=""/>
                </a>
            </div>
        </div>
    </section> */}
        {/* <!-- brand section end --> */}

        <section className="panel-space"></section>
      </div>
    );
  }
}
export default Search;
